import styled from 'styled-components';

export const PercentStyled = styled.div`
  flex: 1;
  .percent_container {
    flex: 1;
    display: flex;
    align-items: center;
    column-gap: 17px;
    .icon_per {
      display: flex;
    }
    .icon_per_mobile {
      display: none;
    }
    .percent_item {
      position: relative;
      border-radius: 8px;
      flex-basis: 100%;
      height: 44px;
      background: ${({ theme }) => theme.COLORS.GRAY_20}59;
      overflow: hidden;
      .pi_active {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
        width: ${({ percent }) => percent}%;
        height: 100%;
        padding-right: 10px;
        min-width: 80px;
        background-image: url(/assets/images/icons/percent-line.png);
        background-color: ${({ color }) => color};
        background-position: center;
        background-size: cover;
        background-repeat: repeat;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_32};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        .fs_23 {
          padding-top: 7px;
          padding-left: 2px;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
        }
      }
    }
  }

  .theme_branner {
    .percent_container {
      .percent_item {
        height: 32px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .percent_container {
      .icon_per {
        display: none;
      }
      .icon_per_mobile {
        display: flex;
      }
      .percent_item {
        height: 19px;
        border-radius: 3px;
        .pi_active {
          border-radius: 3px;
          min-width: 50px;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
          .fs_23 {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_11};
            padding-top: 2px;
          }
        }
      }
    }
    .theme_branner {
      .percent_container {
        .percent_item {
          height: 17px;
          border-radius: 3px;
          .pi_active {
            border-radius: 3px;
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
            .fs_23 {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_7};
            }
          }
        }
      }
    }
  }
`;
