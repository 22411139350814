import styled from 'styled-components';

export const ShareBtnHerWillWidgetStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .layout_share {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 12px;
    .icon_share {
      cursor: pointer;
    }
    .box_tooltip {
      opacity: 0.3;
    }
    .icon_share_logo {
      width: 45px;
      height: 45px;
      cursor: pointer;
    }
  }
`;
