import styled from 'styled-components';

export const InputSearchStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: ${(props) => (props.width ? props.width : '100%')};
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /*===============================================
Theme 
===============================================*/
  .input_search_container {
    position: relative;
    .input_search {
      border-radius: 6px;
      border: 1px solid ${({ theme }) => theme.COLORS.PINK_1};
      padding: 9px 60px 9px 26px;
      width: 100%;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
      color: ${({ theme }) => theme.COLORS.BLACK_6};
    }
    .search_icon {
      position: absolute;
      right: 24px;
      bottom: 6px;
      cursor: pointer;
    }
    .clear_icon {
      position: absolute;
      right: 33px;
      bottom: 8px;
      cursor: pointer;
    }
    .search_icon_moblie {
      display: none;
      position: absolute;
      right: 7px;
      bottom: 9px;
      cursor: pointer;
    }
    .clear_icon_moblie {
      display: none;
      position: absolute;
      right: 23px;
      bottom: 9px;
      cursor: pointer;
    }
  }

  .theme_standard {
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .input_search_container {
      .input_search {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        padding: 9px 41px;
        padding-left: 10px;
      }
      .search_icon {
        display: none;
      }
      .clear_icon {
        display: none;
      }
      .search_icon_moblie {
        display: block;
      }
      .clear_icon_moblie {
        display: block;
      }
    }
  }
`;
