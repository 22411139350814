import { CONFIG_PROJECT } from '../actions/actionTypes';

const initialState = {};

const configProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_PROJECT:
      state = action?.data;
      return state;
    default:
      return state;
  }
};

export default configProjectReducer;
