import { default as AddressNow } from './AddressNow';
import { default as BannerShow } from './BannerShow';
import { default as BoxHospital } from './BoxHospital';
import { default as BoxLink } from './BoxLink';
import { default as BoxPackage } from './BoxPackage';
import { default as BoxProduct } from './BoxProduct';
import { default as Loading } from './Loading';
import { default as LogoLoadingFade } from './LogoLoadingFade';
import { default as LogoLoadingFade2 } from './LogoLoadingFade2';
import { default as Percent } from './Percent';
import { default as ShowLabel } from './ShowLabel';
import { default as BoxNotic } from './BoxNotic';

const EXPORT = {
  BoxNotic,
  AddressNow,
  BannerShow,
  BoxHospital,
  BoxLink,
  BoxPackage,
  BoxProduct,
  Loading,
  LogoLoadingFade,
  LogoLoadingFade2,
  Percent,
  ShowLabel,
};

export default EXPORT;
