import React from 'react';
import { StandardFormStyled } from './styled';
import { Displays, Buttons } from 'components';

class StandardForm extends React.Component {
  state = { loading: true };

  handleClickButton = (value, key, area, primary) => {
    const { handleClickAns } = this.props;
    handleClickAns(value, key, area, primary);
  };
  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
  };

  render() {
    const { question } = this.props;
    const { loading } = this.state;
    return (
      <StandardFormStyled>
        {question && question.answers && (
          <>
            {loading ? (
              <Displays.LogoLoadingFade />
            ) : (
              <img
                className="img_logo"
                src={'/assets/images/icons/logo_question.png'}
                alt="logo well"
              />
            )}
          </>
        )}
        <div className="title">{question && question.label}</div>
        {question && question.description && (
          <div className="title2">{question.description}</div>
        )}
        <div className="button_layout">
          {question &&
            question.answers &&
            question.answers.map((e, i) => (
              <div key={i}>
                <Buttons.Button
                  width="200px"
                  // height="45px"
                  theme_standard
                  font="14px"
                  height="auto"
                  padding="10px"
                  style={{ marginTop: 20 }}
                  handleClickButton={() =>
                    this.handleClickButton(
                      e.value,
                      question.symptom_key,
                      question.symptom_area,
                      question.is_primary
                    )
                  }
                  backgroundColor={e.value === 'unknown' ? '#ffffff' : ''}
                  color={e.value === 'unknown' ? '#000000' : ''}
                  weight={e.value === 'unknown' ? '' : '500'}
                >
                  {e.label}
                </Buttons.Button>
              </div>
            ))}
        </div>
      </StandardFormStyled>
    );
  }
}

export default StandardForm;
