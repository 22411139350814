import React from 'react';
import { HerwillMainLayoutStyled } from './styled';
import { Outlet } from 'react-router-dom';
import { NavbarPinkAlertWidget } from 'widgets';

class HerwillMainLayout extends React.Component {
  render() {
    return (
      <HerwillMainLayoutStyled>
        {/* <NavbatWidget isBack/> */}
        <NavbarPinkAlertWidget />
        <Outlet />
      </HerwillMainLayoutStyled>
    );
  }
}

export default HerwillMainLayout;
