import React from 'react';
import { BannerCarouselWidgetStyled } from './styled';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Banner1Widget, Banner2Widget, Banner3Widget, Banner4Widget } from './Banners';
import { Icons } from 'components';

const BannerCarouselWidget = () => {
  const CustomLeftArrow = ({
    onClick,
    // ...rest
  }) => {
    // const {
    //   onMove,
    //   carouselState: { currentSlide, deviceType },
    // } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <button className="left_arrow" onClick={() => onClick()}>
        <Icons.CarouselLeftArrow {...renderOffset()} />
      </button>
    );
  };

  const CustomRightArrow = ({
    onClick,
    // ...rest
  }) => {
    // const {
    //   onMove,
    //   carouselState: { currentSlide, deviceType },
    // } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <button className="right_arrow" onClick={() => onClick()}>
        <Icons.CarouselRightArrow {...renderOffset()} />
      </button>
    );
  };

  const renderOffset = () => {
    let width = window.innerWidth;
    if (width > 540) {
      return { width: 28.2, height: 48 };
    }
    return { width: 9.6, height: 16.4 };
  };

  const CustomDot = ({ onClick, ...rest }) => {
    const {
      // onMove,
      index,
      active,
      // carouselState: { currentSlide, deviceType },
    } = rest;
    const carouselItems = [
      <div className={`custom_dot ${index === 0 && active ? 'active' : ''}`} />,
      <div className={`custom_dot ${index === 1 && active ? 'active' : ''}`} />,
      <div className={`custom_dot ${index === 2 && active ? 'active' : ''}`} />,
      <div className={`custom_dot ${index === 3 && active ? 'active' : ''}`} />,
    ];
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <button
        style={{
          marginBottom: 16,
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 4,
          paddingRight: 4,
          background: 'transparent',
        }}
        className={` ${active ? 'active' : 'inactive'}`}
        onClick={() => onClick()}
      >
        {React.Children.toArray(carouselItems)[index]}
      </button>
    );
  };

  return (
    <BannerCarouselWidgetStyled>
      <Carousel
        responsive={responsive}
        // autoPlay={true}
        // infinite
        // transitionDuration={5000}
        autoPlaySpeed={5000}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        showDots
        customDot={<CustomDot />}
      >
        <Banner4Widget />
        <Banner1Widget />
        <Banner2Widget />
        <Banner3Widget />
      </Carousel>
    </BannerCarouselWidgetStyled>
  );
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1400, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    partialVisibilityGutter: 30,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
  },
};

export default BannerCarouselWidget;
