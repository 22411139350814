import { TreatmentItemWidgetStyled } from './styled';

const TreatmentItemWidget = ({ title, icon, data }) => {
  return (
    <TreatmentItemWidgetStyled>
      <div className="tmi_title">{title}</div>
      <div className="tmi_icon_wrap">{icon}</div>
      <div className="tmi_content">
        {data &&
          data.map((e, i) => (
            <div
              key={i}
              className={`tmi_item ${data.length === i + 1 ? 'mb_0' : ''}`}
            >
              <div className="tmi_dot" />
              {e}
            </div>
          ))}
      </div>
    </TreatmentItemWidgetStyled>
  );
};

export default TreatmentItemWidget;
