import { ContentItemStyled } from './styled';

const ContentItem = ({ src, title, subTitle, link_uri, uuid, clickMore }) => {
  return (
    <ContentItemStyled>
      {
        src
          ?
          <img className="ci_img" alt="herwill content" src={src} />
          :
          <div className="blank_img" />
      }
      <div className="ci_body">
        <div className='group_top'>
          <div className="ci_title">{title}</div>
          <div className="ci_sub_title">{subTitle}</div>
        </div>
        {
          link_uri
            ?
            <a href={link_uri} target="_blank" rel="noopener noreferrer">
              <div className="ci_view_more" onClick={() => clickMore && clickMore(uuid)}>{`อ่านรายละเอียด >`}</div>
            </a>
            :
            <div className='blank_label' />
        }
      </div>
    </ContentItemStyled>
  );
};

export default ContentItem;
