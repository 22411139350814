import styled from 'styled-components';

export const PartnerWidgetStyled = styled.div`
  .group_normal {
    padding: 22px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: ${({ theme, noBg }) => noBg ? 'unset' : theme.COLORS.WHITE_1};
    .band_label {
      margin-bottom: 34px;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_36};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      .pw_title_line {
        margin-top: 8px;
        margin: auto;
        width: 66%;
        height: 3px;
        background: ${({ theme }) => theme.COLORS.PINK_8};
      }
    }
    .large_img {
      display: block;
      .top_img {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 70px;
        &.s_gap {
          column-gap: 83px;
        }
        &.mt4 {
          margin-top: 4px;
        }
        .band_imb {
          max-width: 99px;
        }
        .band_imb_new {
          &.her_will {
            height: 80px;
          }
          &.loreal {
            height: 30px;
          }
          &.mercure_rayong {
            height: 120px;
          }
          &.pullman {
            height: 70px;
          }
          &.pyt1 {
            height: 120px;
          }
          &.PYT3 {
            height: 95px;
          }
          &.PTY_nawa {
            height: 115px;
          }
          &.pyt_sri {
            height: 70px;
          }
          &.praram9 {
            height: 150px;
          }
          &.chula3 {
            height: 70px;
          }
          &.Paolo {
            height: 60px;
          }
          &.novo {
            height: 40px;
          }
        }
      }
    }
    .small_img {
      display: none;
      .small_top_img {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 70px;

        .sm_band_imb_new {
          &.her_will {
            height: 50px;
          }
          &.loreal {
            height: 20px;
          }
          &.mercure_rayong {
            height: 85px;
          }
          &.pullman {
            height: 40px;
          }
          &.pyt1 {
            height: 70px;
          }
          &.PYT3 {
            height: 65px;
          }
          &.PTY_nawa {
            height: 85px;
          }
          &.pyt_sri {
            height: 50px;
          }
          &.praram9 {
            height: 85px;
          }
          &.chula3 {
            height: 45px;
          }
          &.Paolo {
            height: 35px;
          }
          &.novo {
            height: 25px;
          }
        }
      }
    }
  }
  .group_small {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    column-gap: 58px;
    .small_brand {
      max-width: 109px;
    }
  }

  @media (max-width: 1024px) {
    .group_small {
      display: flex;
      column-gap: 13px;
      justify-content: center;
      .small_brand {
        max-width: 41px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .group_normal {
      padding: 15px;
      .band_label {
        margin-bottom: 12px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_32};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        .pw_title_line {
          height: 1px;
        }
      }
      .large_img {
        .top_img {
          column-gap: 30px;
          &.s_gap {
            column-gap: 30px;
          }
          .band_imb {
            max-width: 70px;
            &.tbcc {
              max-width: 52px;
            }
            &.jura {
              max-width: 99px;
            }
            &.mana {
              max-width: 99px;
            }
          }
          .band_imb_new {
            &.her_will {
              height: 70px;
            }
            &.loreal {
              height: 20px;
            }
            &.mercure_rayong {
              height: 85px;
            }
            &.pullman {
              height: 50px;
            }
            &.pyt1 {
              height: 90px;
            }
            &.PYT3 {
              height: 65px;
            }
            &.PTY_nawa {
              height: 85px;
            }
            &.pyt_sri {
              height: 50px;
            }
            &.praram9 {
              height: 115px;
            }
            &.chula3 {
              height: 50px;
            }
            &.Paolo {
              height: 50px;
            }
            &.novo {
              height: 30px;
            }
          }
        }
      }
      
    }
  }

  @media (max-width: 630px) {
    .group_normal {
      .large_img {
        display: none;
        
      }
      .small_img {
        display: block;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .group_normal {
      padding: 15px;
      .band_label {
        margin-bottom: 12px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        .pw_title_line {
          height: 1px;
        }
      }
      .large_img {
        .top_img {
          column-gap: 20px;
          &.s_gap {
            column-gap: 20px;
          }
          row-gap: 5px;
          .band_imb {
            max-width: 40px;
            &.tbs {
              max-width: 35px;
            }
            &.tbcc {
              max-width: 30px;
            }
            &.jura {
              max-width: 70px;
            }
            &.mana {
              max-width: 50px;
            }
          }
          .band_imb_new {
            &.her_will {
              height: 50px;
            }
            &.loreal {
              height: 20px;
            }
            &.mercure_rayong {
              height: 85px;
            }
            &.pullman {
              height: 40px;
            }
            &.pyt1 {
              height: 70px;
            }
            &.PYT3 {
              height: 65px;
            }
            &.PTY_nawa {
              height: 85px;
            }
            &.pyt_sri {
              height: 50px;
            }
            &.praram9 {
              height: 85px;
            }
            &.chula3 {
              height: 45px;
            }
            &.Paolo {
              height: 35px;
            }
            &.novo {
              height: 25px;
            }
          }
        }
      }
      .small_img {

      }
    }
    .group_small {
      .small_brand {
        &.tbs {
          max-width: 35px;
        }
        &.tbcc {
          max-width: 30px;
        }
        &.jura {
          // max-width: 70;
        }
        &.mana {
          // max-width: 70;
        }
      }
    }
  }
`;
