import React, { useRef, useEffect } from 'react';
import { DateTimeStyled } from './styled';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';

const DateTime = ({
  theme_standard,
  theme_line,
  theme_line_herwill,
  input,
  disabled,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_line: theme_line,
    theme_line_herwill: theme_line_herwill,
  });

  const pickerRef = useRef();
  const today = new Date();

  useEffect(() => {
    if (isMobile && pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true;
    }
  }, [pickerRef]);

  return (
    <DateTimeStyled>
      <div className={customClass}>
        <DatePicker
          ref={pickerRef}
          disabledKeyboardNavigation={true}
          selected={input.value ? new Date(input.value) : null}
          onChange={input.onChange}
          dateFormat="dd/MM/yyyy"
          showMonthDropdown
          showYearDropdown
          maxDate={today}
          dropdownMode="select"
          disabled={disabled}
        />
      </div>
    </DateTimeStyled>
  );
};

export default DateTime;
