import styled from 'styled-components';

export const SendEmailFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .md_title {
    font-weight: bold;
    font-size: 18px;
    color: #003564;
    margin-bottom: 20px;
  }
  .md_input_wrap {
  }
  .btn_pink {
    width: 100%;
    max-width: 131px;
    margin: auto;
    height: 45px;
    margin-top: 16px;
    background-color: ${({ theme }) => theme.COLORS.PINK_1};
    font-size: 1rem;
    border: none;
    border-radius: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    &.disabled {
      cursor: not-allowed;
      background: #d9d9d9;
    }
  }
`;
