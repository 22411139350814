import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { HealthInsurancerContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { userService } from 'apiService';
import theme from 'styles/theme.json';
import { Icons, Buttons, Displays, Cards } from 'components';

class HealthInsurancerContainer extends React.Component {
  state = {
    healthData: [],
    pagination: {
      offset: 0,
      per_page: 5,
    },
    isDesktop: window.matchMedia(`(max-width: ${theme.SCREENS.MINI_TABLET})`)
      .matches
      ? false
      : true,
  };

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.router.location.pathname });
    ReactGA.pageview(this.props.router.location.pathname);
    this.setState({
      loading: true,
    });
    this.handleDetectScreen();
    this.fetchHealthInsurance();
    window.addEventListener('resize', this.handleDetectScreen, true);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleDetectScreen, true);
  }

  handleDetectScreen = () => {
    const setDevice = (e) => {
      this.setState({
        isDesktop: e,
      });
    };
    if (
      window.matchMedia(`(max-width: ${theme.SCREENS.MINI_TABLET})`).matches
    ) {
      setDevice(false);
    } else {
      setDevice(true);
    }
  };

  fetchHealthInsurance = async () => {
    let res = await userService.GET_HEALTH_INSURANCE_SERVICE_LIST();
    if (res && res.success) {
      this.setState({
        loading: false,
        healthData: res.data,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  handleClickBack = () => {
    this.props.router.navigate(-1);
  };

  handleClickLandingPage = () => {
    this.props.router.navigate(-1);
  };

  render() {
    const { healthData, loading } = this.state;
    return (
      <HealthInsurancerContainerStyled>
        <div className={`loader_layout ${loading ? 'active' : ''}`}>
          <Displays.LogoLoadingFade />
        </div>
        <div className="is_desktop">
          <img
            className="mb_bg"
            src={'/assets/images/backgrounds/insurance-bg.png'}
            alt="img"
          />
          <div className="btn_back_wrap" onClick={this.handleClickBack}>
            <Buttons.BackBtn
              theme_gray
              txt="ย้อนกลับ"
              icon={<Icons.Back color="#6F7070" />}
            />
          </div>
          <div className="btn_mb_back_wrap" onClick={this.handleClickBack}>
            <Buttons.BackBtn
              txt="ย้อนกลับ"
              icon={<Icons.Back color="white" />}
            />
          </div>
          <div className="location_container">
            <div className="title">ประกันสุขภาพที่ให้บริการ</div>
            <div className="box_show">
              <div
                className="bg_show"
                style={{
                  backgroundImage: `url('/assets/images/backgrounds/bg_3.jpg')`,
                }}
              >
                <div className="box_label_show">
                  <div className="bText">บริการประกันสุขภาพสำหรับคุณ</div>
                  <div>
                    วางแผนประกันสุขภาพล่วงหน้าเพื่อช่วยค่าใช้จ่าย
                    และสิทธิ์การรักษาของคุณในอนาคต
                  </div>
                </div>
              </div>
            </div>
            <div className="box_mobile_show">
              <div className="bms_left_col">
                <img
                  src={'/assets/images/icons/Insurance-cuate.png'}
                  alt="insurance"
                />
              </div>
              <div className="bms_right_col">
                <div className="bms_title">บริการประกันสุขภาพสำหรับคุณ</div>
                <div className="bms_sub_title">
                  วางแผนประกันสุขภาพล่วงหน้าเพื่อช่วยค่า ใช้จ่าย
                  และสิทธิ์การรักษาของคุณในอนาคต
                </div>
              </div>
            </div>
            <div className="title_sec_2">ประกันสุขภาพที่เข้าร่วมโครงการ</div> 
            <div className="coming_text">Coming soon...</div>
            <div className="insurance_row">
              {healthData &&
                healthData.map((e, i) => (
                  <div key={i} className="insurance_col">
                    <Cards.InsuranceItem
                      imgUri={e.image_uri}
                      redirectUril={e.redirect_uri}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="btn_layout">
          <button className="btn_end" onClick={this.handleClickLandingPage}>
            กลับสู่หน้าหลัก
          </button>
        </div>
      </HealthInsurancerContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HealthInsurancerContainer));
