import { TreatmentGuildlineWidgetStyled } from './styled';
import { Cards, Icons, Displays } from 'components';

const TreatmentGuildlineWidget = () => {
  return (
    <TreatmentGuildlineWidgetStyled>
      <img
        className="tg_img_footer"
        alt="treatment backgrond"
        src="/assets/images/backgrounds/guildline-bg.png"
      />
      <img
        className="tg_img_head"
        alt="treatment backgrond"
        src="/assets/images/backgrounds/guildline-head.png"
      />
      <div className="tg_header_row">
        <img
          className="tr_heart"
          alt="treatment heart"
          src="/assets/images/icons/treatHeart.png"
        />
        <div className="icon_show_mobile">
          <img
            className="tr_heart_mobile"
            alt="treatment heart"
            src="/assets/images/icons/treatHeart.png"
          />
        </div>
        <div className="tg_title_1">ความหวัง โอกาสและแนวทางการรักษา</div>
      </div>
      <div className="tg_body">
        <div className="tg_left_col">
          <div className="statistic_container">
            <div className="st_title">
              โอกาสในการรักษาหายขาดของ{' '}
              <span className="pink_highligh">"มะเร็งเต้านม"</span>{' '}
              <div>จะสูงกว่าเมื่อตรวจพบในระยะเริ่มต้น</div>
            </div>
            <div className="box_percent">
              <div className="st_row">
                <div className="st_label">ระยะที่ 1</div>
                <Displays.Percent percent={98} color="#9BDEAC" />
              </div>
              <div className="st_row">
                <div className="st_label">ระยะที่ 2-3</div>
                <Displays.Percent percent={84} color="#F0A5C1" />
              </div>
              <div className="st_row mb_0">
                <div className="st_label">ระยะที่ 4</div>
                <Displays.Percent percent={24} color="#CC8190" />
              </div>
              <div className='text_det'>
              อัตราการหายขาดเมื่อตรวจพบในแต่ละระยะ
            </div>
            </div>
            
          </div>
        </div>
        <div className="tg_right_col">
          <div className="treatment_patien_container">
            <div className="tg_title">
              วิธีการรักษาผู้ป่วย{' '}
              <span className="pink_highligh">"มะเร็งเต้านม"</span>{' '}
            </div>
            <div className="tg_content_row">
              <div className="tg_item_wrap">
                <Cards.TreatmentItemWidget
                  title="ผ่าตัดรักษา"
                  icon={<Icons.TreatHeartRate />}
                  data={[
                    'จำเป็นต่อผู้ป่วยระยะเริ่มต้น เพื่อการหายขาด',
                    'ตัดเฉพาะก้อนมะเร็ง ไม่จำเป็น ต้องตัดเต้านม',
                  ]}
                />
              </div>
              <div className="tg_item_wrap border_left_right">
                <Cards.TreatmentItemWidget
                  title="ให้ยารักษา"
                  icon={<Icons.TreatHeartCapsule />}
                  data={[
                    'ใช้ยารักษาทั้งผู้ป่วยระยะเริ่มต้น และระยะแพร่กระจาย',
                    'ใช้ยาเคมีบำบัด, ยาต้านฮอร์โมน และยาพุ่งเป้าจำเพาะ',
                  ]}
                />
              </div>
              <div className="tg_item_wrap">
                <Cards.TreatmentItemWidget
                  title="รังสีรักษา"
                  // icon={<Icons.TreatHeartVirus />}
                  icon={
                    <img
                      className="img_virus"
                      src="/assets/images/icons/treatVirus.svg"
                      alt="treat virus"
                    />
                  }
                  data={[
                    'ผู้ป่วยระยะเริ่มต้น ที่มีก้อนขนาดใหญ่',
                    'ผู้ป่วยระยะแพร่กระจาย ที่มีมะเร็ง กระจายไปยังต่อมน้ำเหลือง',
                  ]}
                />
              </div>
            </div>
            <div className="tg_content_row moblie">
              <div className="tg_item_wrap">
                <Cards.TreatmentItemWidget
                  title="ผ่าตัดรักษา"
                  icon={<Icons.TreatHeartRate height="30.75" width="27.9" />}
                  data={[
                    'จำเป็นต่อผู้ป่วยระยะเริ่มต้น เพื่อการหายขาด',
                    'ตัดเฉพาะก้อนมะเร็ง ไม่จำเป็น ต้องตัดเต้านม',
                  ]}
                />
              </div>
              <div className="tg_item_wrap border_left_right">
                <Cards.TreatmentItemWidget
                  title="ให้ยารักษา"
                  icon={<Icons.TreatHeartCapsule height="30" width="30" />}
                  data={[
                    'ใช้ยารักษาทั้งผู้ป่วยระยะเริ่มต้น และระยะแพร่กระจาย',
                    'ใช้ยาเคมีบำบัด, ยาต้านฮอร์โมน และยาพุ่งเป้าจำเพาะ',
                  ]}
                />
              </div>
              <div className="tg_item_wrap">
                <Cards.TreatmentItemWidget
                  title="รังสีรักษา"
                  icon={
                    <img
                      className="img_virus small"
                      src="/assets/images/icons/treatVirus.svg"
                      alt="treat virus"
                    />
                  }
                  // icon={<Icons.TreatHeartVirus height="30.45" width="28.18" />}
                  data={[
                    'ผู้ป่วยระยะเริ่มต้น ที่มีก้อนขนาดใหญ่',
                    'ผู้ป่วยระยะแพร่กระจาย ที่มีมะเร็ง กระจายไปยังต่อมน้ำเหลือง',
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </TreatmentGuildlineWidgetStyled>
  );
};

export default TreatmentGuildlineWidget;
