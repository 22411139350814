import React from 'react';

export default function AaPlatform({ color = '#ed799e' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_109810"
        data-name="Path 109810"
        d="M9.466,8.314l6.23-6.23A1.037,1.037,0,1,0,14.23.618L8,6.849,1.77.618A1.037,1.037,0,0,0,.3,2.084l6.23,6.23L.3,14.545A1.037,1.037,0,1,0,1.77,16.011L8,9.78l6.23,6.23A1.037,1.037,0,1,0,15.7,14.545Z"
        transform="translate(0 -0.314)"
        fill={color}
      />
    </svg>
  );
}
