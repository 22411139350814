import styled from 'styled-components';

export const PreviewVideoStyled = styled.div`
  width: 100%;
  .preview_video_container {
    .pv_figture {
      position: relative;
      margin-bottom: 8px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-top: 56%;
      background: ${({ theme }) => theme.COLORS.PINK_5};
      background-image: url(${({ src }) => src});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .pv_play_wrap {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
      }
    }
    .pv_topic {
      margin-bottom: 9px;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
      color: ${({ theme }) => theme.COLORS.BROWN_2};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .pv_total_row {
      display: flex;
      align-items: center;
      &.mobile {
        display: none;
      }
      .score_block {
        padding: 0 10px;
        display: flex;
        align-items: center;
        column-gap: 5px;
        .pv_score {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
          color: ${({ theme }) => theme.COLORS.BLACK_7};
        }
        .view_big {
          display: flex;
        }
        .view_small {
          display: none;
        }
      }
      .pv_line_between {
        width: 1px;
        height: 23px;
        background: ${({ theme }) => theme.COLORS.GRAY_5}4F;
      }
    }
  }

  .theme_standard {
  }

  .theme_home {

  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .preview_video_container {
      .pv_figture {
        .pv_play_wrap {
          top: 38%;
        }
      }
      .pv_topic {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        margin-bottom: 0px;
      }
      .pv_total_row {
        display: none;
        &.mobile {
          display: flex;
        }
        .score_block {
          .pv_score {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
          }
        }
      }
    }

    .theme_home {
      .preview_video_container {
        .pv_total_row {
          &.mobile {
            column-gap: 10px;
            .score_block {
              padding: 0px;
            }
          }
        }
      }
    }
  }
`;
