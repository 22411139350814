import React from 'react';

export default function AaPlatform({
  width="40",
      height="40"
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 40 40"
    >
      <defs>
        <filter
          id="Rectangle_31182"
          x="5.5"
          y="7.5"
          width="34.17"
          height="31"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="3" dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur" />
          <feFlood flood-opacity="0.09" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_31180"
            data-name="Rectangle 31180"
            width="19.165"
            height="16"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_94652"
        data-name="Group 94652"
        transform="translate(-898 -3256)"
      >
        <path
          id="Path_107601"
          data-name="Path 107601"
          d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z"
          transform="translate(898 3256)"
          fill="#ed799e"
        />
        <g
          id="Group_90114"
          data-name="Group 90114"
          transform="translate(370 -241)"
        >
          <g
            transform="matrix(1, 0, 0, 1, 528, 3497)"
            filter="url(#Rectangle_31182)"
          >
            <rect
              id="Rectangle_31182-2"
              data-name="Rectangle 31182"
              width="19.17"
              height="16"
              rx="0.5"
              transform="translate(10 12)"
              fill="#ed799e"
            />
          </g>
          <g
            id="Group_90111"
            data-name="Group 90111"
            transform="translate(538 3509)"
          >
            <g
              id="Group_90110"
              data-name="Group 90110"
              clip-path="url(#clip-path)"
            >
              <path
                id="Path_107599"
                data-name="Path 107599"
                d="M18.6,0H.561A.561.561,0,0,0,0,.561V15.439A.561.561,0,0,0,.561,16H18.6a.561.561,0,0,0,.561-.561V.561A.561.561,0,0,0,18.6,0M7.207,9.885V6.115a.721.721,0,0,1,.4-.642.8.8,0,0,1,.79.042l3.213,1.875a.5.5,0,0,1,.047.031.709.709,0,0,1,0,1.157.559.559,0,0,1-.047.031L8.4,10.485a.8.8,0,0,1-.79.042.721.721,0,0,1-.4-.641m5.977-8.762h1.884V2.1H13.185ZM12.062,2.1H10.178V1.123h1.884Zm-3.007,0H7.171V1.123H9.055Zm-3.007,0H4.164V1.123H6.048Zm0,12.776H4.164V13.9H6.048ZM7.171,13.9H9.055v.978H7.171Zm3.007,0h1.884v.978H10.178Zm3.007,0h1.884v.978H13.185ZM18.042,2.1H16.192V1.123h1.851Zm-15,0H1.123V1.123H3.041ZM1.123,13.9H3.041v.978H1.123Zm15.069,0h1.851v.978H16.192Z"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
