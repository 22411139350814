import styled from 'styled-components';

export const NavbatWidgetStyled = styled.div`
    .container_navbar {
        position: relative;
        padding: 20px;
        display: flex;
        align-items: center;
        .logo_layout {
            display: flex;
            align-items: center;
            .back_layout {
                display: none;
            }
            .logo_show {
                width: 170px;
                cursor: pointer;
            }
        }
        .group_menu {
            display: flex;
            margin-left: 48px;
            column-gap: 24px;
            .menu {
                cursor: pointer;
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
                color: ${({ theme }) => theme.COLORS.PINK_7};
                font-weight: 400;
                text-shadow: 1px 1px #70707033;
                &.active {
                    color: ${({ theme }) => theme.COLORS.PINK_1};
                }
            }
        }
        .small_menu {
            display: none;
            position: relative;
            z-index: 22;
            .box_menu {
                padding: 48px 0px;
                height: 100vh;
                background: rgb(255,255,255);
                background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,245,245,1) 100%);
                .close_menu {
                    padding: 0px 5%;
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 64px;
                }
                .group_menu_moblie {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    row-gap: 23px;
                    .menu {
                        cursor: pointer;
                        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
                        color: ${({ theme }) => theme.COLORS.BLACK_1};
                        font-weight: 300;
                        padding-bottom: 27px;
                        border-bottom: 1px solid #7070701A;
                        &.active {
                            color: ${({ theme }) => theme.COLORS.PINK_1};
                            font-size: ${({ theme }) =>
                              theme.FONTS.SIZE.SIZE_24};
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .nav_herwill_bg{
            position: absolute;
            top: 0px;
            right: 0px;
            z-index: -1;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    
    @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
        .container_navbar {
            justify-content: space-between;
            &.pink_bg {
                background: #ffebf7;
            }
            padding: 10px;
            .logo_layout {
                .back_layout {
                    display: flex;
                    cursor: pointer;
                    &.hide {
                        display: none;
                    }
                }
                .logo_show {
                    width: 122px;
                }
            }
            .group_menu {
                display: none;
            }
            .small_menu {
                display: block;
            }
        }
    }
}
`;
