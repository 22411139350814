import React from 'react';
import { BoxNoticStyled } from './styled';
import cx from 'classnames';
import { Icons } from 'components';

const BoxNotic = ({ theme_standard_box_notic, title, detail, num }) => {
  const customClass = cx({
    theme_standard_box_notic: theme_standard_box_notic,
  });

  const renderIcon = () => {
    switch (num) {
      case '1':
        return (
          <>
            <div className="svg_mid">
              {/* <Icons.Notic1 width={'4.6vw'} height={'4.6vw'} /> */}
              <img
                className="img_1"
                alt="notic icon"
                src="/assets/images/icons/notic-1.png"
              />
            </div>
            <div className="svg_mid mobile">
              <Icons.Notic1 width="31px" height="31px" />
            </div>
          </>
        );

      case '2':
        return (
          <>
            <div className="svg_mid">
              {/* <Icons.Notic2 /> */}
              <img
                className="img_1"
                alt="notic icon"
                src="/assets/images/icons/notic-2.png"
              />
            </div>
            <div className="svg_mid mobile">
              <Icons.Notic2 width="31px" height="31px" />
            </div>
          </>
        );

      case '3':
        return (
          <>
            <div className="svg_mid">
              {/* <Icons.Notic3 /> */}
              <img
                className="img_1"
                alt="notic icon"
                src="/assets/images/icons/notic-3.png"
              />
            </div>
            <div className="svg_mid mobile">
              <Icons.Notic3 width="27px" height="31px" />
            </div>
          </>
        );

      case '4':
        return (
          <>
            <div className="svg_mid">
              {/* <Icons.Notic4 /> */}
              <img
                className="img_1"
                alt="notic icon"
                src="/assets/images/icons/notic-4.png"
              />
            </div>
            <div className="svg_mid mobile">
              <Icons.Notic4 width="31px" height="24px" />
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="svg_mid">
              <Icons.Notic1 />
            </div>
            <div className="svg_mid mobile">
              <Icons.Notic1 width="31px" height="31px" />
            </div>
          </>
        );
    }
  };
  return (
    <BoxNoticStyled>
      <div className={customClass}>
        <div className="top_notic">
          <div className="svg">
            {/* <Icons.NoticLabel  width={'4.6vw'} height={'4.6vw'} /> */}
            <div className='label_num'>{num}</div>
          </div>
          <div className="tn_tablet">
            {/* <Icons.NoticLabel  width="41px" height="39px" /> */}
            <div className="svg_tablet">
              <div className='tn_num'>{num}</div>
            </div>
          </div>
          <div className="tn_moblie">
            {/* <Icons.NoticLabel  width="21px" height="19px" /> */}
            <div className="svg_mobile">
              <div className='mo_num'>{num}</div>
            </div>
          </div>
          <div className="top_label">{title}</div>
        </div>
        <div className="group_bottom">
          {renderIcon()}
          <div className="label_bottom">{detail}</div>
        </div>
      </div>
    </BoxNoticStyled>
  );
};

export default BoxNotic;
