import React from 'react';
import { BoxProductStyled } from './styled';
import cx from 'classnames';
import { Displays, Buttons } from 'components';

const BoxProduct = ({
  theme_standard_2,
  theme_standard_pink,
  src,
  title,
  percent,
  price1,
  price2,
  btnTheme,
}) => {
  const customClass = cx({
    theme_standard_2: theme_standard_2,
    theme_standard_pink: theme_standard_pink,
  });
  return (
    <BoxProductStyled>
      <div className={customClass}>
        <div className="left_layout">
          <div
            className="box_img"
            style={{ backgroundImage: `url(${src})` }}
          ></div>
        </div>
        <div className="right_layout">
          <div className="show_head">{title}</div>
          {percent && percent !== 0 ? (
            <div className="show_discount">
              <Displays.ShowLabel theme_red label={percent} fontSize="12px" />
            </div>
          ) : (
            <div></div>
          )}
          <div className="group_price">
            <div className="label_show">ราคา : </div>
            {percent && percent !== 0 ? (
              <div className="price_1">{price1}</div>
            ) : (
              <div></div>
            )}
            <div className="price_2">{price2}</div>
          </div>
          <div className="btn">
            <Buttons.ButtonIcon
              {...btnTheme}
              lable1Size="16px"
              label1="เลือก"
            />
          </div>
        </div>
      </div>
    </BoxProductStyled>
  );
};

export default BoxProduct;
