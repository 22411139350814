import styled from 'styled-components';

export const BannerCarouselWidgetStyled = styled.div`
  .left_arrow {
    position: absolute;
    left: 24px;
    z-index: 2;
    background: transparent;
    cursor: pointer;
  }
  .right_arrow {
    position: absolute;
    right: 24px;
    z-index: 2;
    background: transparent;
    cursor: pointer;
  }
  .custom_dot {
    border-radius: 1px;
    width: 32px;
    height: 8px;
    background: ${({ theme }) => theme.COLORS.PINK_10}80;
    &.active {
      background: ${({ theme }) => theme.COLORS.PINK_11};
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    position: relative;
    .left_arrow {
      left: 8px;
    }
    .right_arrow {
      right: 8px;
    }
    .custom_dot {
      width: 14px;
      height: 3px;
      background: ${({ theme }) => theme.COLORS.PINK_10};
    }
  }
`;
