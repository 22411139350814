import { ShareVideoWidgetStyled } from './styled';
import { Icons } from 'components';
// import ReactTooltip from 'react-tooltip';
import ReactGA from 'react-ga';
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from 'react-share';
import { toast } from 'react-toastify';
import { herwillService } from 'apiService';

const ShareVideoWidget = ({ onClose, url, content_uuid }) => {
  const copyFunction = () => {
    var copyText = document.createElement('textarea');
    copyText.value = url;
    document.body.append(copyText);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    copyText.remove();
    toast.success('copied to clipboard');
    // Tracking user event
    ReactGA.event({
      category: 'herwill_video_share',
      action: 'click_copy',
    });
    handleReactShare('link');
  };

  const handleReactShare = (channel) => {
    // Tracking user event
    ReactGA.event({
      category: 'herwill_video_share',
      action: 'click_' + channel,
    });

    let params = {
      type: channel,
    };

    herwillService.POST_SHARE_VIDEO(content_uuid, params);

    // return true;
  };

  return (
    <ShareVideoWidgetStyled>
      <div className="sv_row">
        Share
        <div className="close_wrap" onClick={onClose}>
          <Icons.CloseBold />
        </div>
      </div>
      <div className="share_row">
        <FacebookShareButton
          url={url}
          // quote={
          //   'แด่ เธอ ผู้หญิงทุกคน วันนี้เราจะร่วมสู้มะเร็งเต้านมไปด้วยกัน คุณสามารถเข้าร่วมโครงการ Her Will ผ่านการตรวจความเสี่ยงตนเองฟรี ที่นี่ herwill.wellcancer.com และแชร์ข้อความนี้ต่อให้กับผู้หญิงรอบตัวท่านได้'
          // }
          // hashtag={'#HERWill'}
          beforeOnClick={() => handleReactShare('facebook')}
        >
          <img
            className="icon_share icon_share_logo"
            src="/assets/images/icons/face_svg.svg"
            alt="logo well"
          />
        </FacebookShareButton>
        <LineShareButton
          url={url}
          // title={
          //   'แด่ เธอ ผู้หญิงทุกคน วันนี้เราจะร่วมสู้มะเร็งเต้านมไปด้วยกัน คุณสามารถเข้าร่วมโครงการ Her Will ผ่านการตรวจความเสี่ยงตนเองฟรี ที่นี่ herwill.wellcancer.com และแชร์ข้อความนี้ต่อให้กับผู้หญิงรอบตัวท่านได้'
          // }
          beforeOnClick={() => handleReactShare('line')}
        >
          <img
            className="icon_share icon_share_logo"
            src="/assets/images/icons/line_svg.svg"
            alt="logo well"
          />
        </LineShareButton>
        <TwitterShareButton
          url={'https://herwill.wellcancer.com'}
          // title={
          //   'แด่ เธอ ผู้หญิงทุกคน วันนี้เราจะร่วมสู้มะเร็งเต้านมไปด้วยกัน คุณสามารถเข้าร่วมโครงการ Her Will ผ่านการตรวจความเสี่ยงตนเองฟรี ที่นี่ herwill.wellcancer.com และแชร์ข้อความนี้ต่อให้กับผู้หญิงรอบตัวท่านได้'
          // }
          // hashtags={['HERWil', 'เพื่อเธอ']}
          beforeOnClick={() => handleReactShare('twitter')}
        >
          <img
            className="icon_share"
            alt="line share"
            src="/assets/images/icons/twitter_svg.svg"
          />
        </TwitterShareButton>
        <div className="icon_share share">
          <img
            onClick={copyFunction}
            className="icon_share share"
            src="/assets/images/icons/copy_svg.svg"
            alt="logo well"
            data-tip
            data-for="shareTip"
            data-event="click focus"
          />
        </div>
      </div>
      <div className="clipboard">
        <div className="cb_link">{url}</div>
        <div className="cb_copy" onClick={copyFunction}>
          Copy
        </div>
      </div>
    </ShareVideoWidgetStyled>
  );
};

export default ShareVideoWidget;
