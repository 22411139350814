import styled from 'styled-components';

export const RegisterContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background-image: linear-gradient(
    to bottom,
    #0253a3,
    #006fb4,
    #0089bb,
    #00a1bb,
    #2eb7b8
  );
  min-height: 100vh;
  color: ${({ theme }) => theme.COLORS.WHITE_1};
  font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
  overflow-y: auto;
  position: relative;
  padding: 20px 10px;
  .loading_move {
    height: 94vh;
    align-items: center;
    justify-content: center;
    display: flex;
    .group_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .back_layout {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        cursor: pointer;
        position: absolute;
        top: 70px;
        left: 340px;
        &.herwill {
          color: ${({ theme }) => theme.COLORS.BLACK_6};
        }
      }
      .layout_detail {
        width: 40%;
        .show_logo {
          width: 50px;
          height: 50px;
          .img_show {
            width: 100%;
          }
        }
        .body_show {
          margin-top: 20px;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .loading_move {
      .group_form {
        .back_layout {
          left: 140px;
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .loading_move {
      min-height: calc(100vh);
      .group_form {
        .back_layout {
          left: 110px;
        }
        .layout_detail {
          width: 45%;
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .loading_move {
      .group_form {
        .layout_detail {
          width: 60%;
        }
        .back_layout {
          left: 30px;
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
    .loading_move {
      min-height: calc(100vh);
      .group_form {
        .back_layout {
          left: 26px;
          top: 20px;
        }
        .layout_detail {
          width: 90%;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_PHONE}) {
    .loading_move {
      height: calc(100vh - 70px);
      .group_form {
        .back_layout {
          left: 10px;
          top: 26px;
        }
        .layout_detail {
          width: 100%;
        }
      }
    }
  }
`;
