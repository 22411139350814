import styled from 'styled-components';

export const Banner1WidgetStyled = styled.div`
  position: relative;
  background-image: url(/assets/images/backgrounds/banner1-bg.jpg);
  background-color: #cccccc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 112px);
  display: flex;
  align-items: center;
  .banner_1_container {
    max-width: 50vw;
    width: 100%;
    padding: 0 20px;
    .banner1_header_block {
      margin-bottom: 30px;
      .b1_sub_title {
        text-align: center;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_48};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
      .b1_title {
        text-align: center;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_65};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
        color: ${({ theme }) => theme.COLORS.PINK_8};
        .b1_t_black {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_48};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
        }
      }
    }
    .banner1_description_block {
      margin-bottom: 54px;
      .b1_description {
        text-align: center;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_30};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
    }
    .b1_btn_block {
      margin-bottom: 39px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .icon_click_wrap {
        margin-bottom: -34px;
        .click_icon {
          width: 56px;
          height: 56px;
        }
      }
    }
    .b1_hastag {
      text-align: center;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_36};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
    }
    .label_mth_1 {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
      color: ${({ theme }) => theme.COLORS.GRAY_20};
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MEDIUM_DESKTOP}) {
    min-height: calc(100vh - 102px);
    .banner_1_container {
      .banner1_header_block {
        .b1_sub_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_45};
        }
        .b1_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_62};
          .b1_t_black {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_45};
          }
        }
      }
      .banner1_description_block {
        .b1_description {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_27};
        }
      }
      .b1_hastag {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_33};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    background-image: url(/assets/images/backgrounds/banner1-bg-moblie.jpg);
    background-position: top;
    height: calc(100vh - 80px);
    min-height: unset;
    .banner_1_container {
      max-width: none;
      height: 100%;
      position: relative;
      padding: 10px 20px 8px 20px;
      .banner1_header_block {
        margin-bottom: 8px;
        .b1_sub_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_42};
        }
        .b1_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_59};
          .b1_t_black {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_42};
          }
        }
      }
      .banner1_description_block {
        margin-bottom: 0px;
        .b1_description {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
        }
      }
      .b1_btn_block {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 18%;
        margin-bottom: 0px;
      }
      .b1_hastag {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 8%;
        margin-bottom: 0px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_30};
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    background-position: center;
    height: 434px;
    // height: calc(100vh - 200px);
    min-height: unset;
    .banner_1_container {
      padding: 32px 20px 0px 20px;
      .banner1_header_block {
        margin-bottom: 5px;
        .b1_sub_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        }
        .b1_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
          .b1_t_black {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          }
        }
      }
      .banner1_description_block {
        .b1_description {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_13};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        }
      }
      .b1_btn_block {
        bottom: 68px;
        .icon_click_wrap {
          margin-bottom: -14px;
          .click_icon {
            width: 24px;
            height: 24px;
          }
        }
      }
      .b1_hastag {
        bottom: 30px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      }
      .label_mth_1 {
        display: block;
        position: absolute;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_7};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
        right: 10px;
        bottom: 8px;
      }
    }
  }
`;
