import styled from 'styled-components';

export const ContentItemStyled = styled.div`
  height: 100%;
  border-radius: 4px;
  -webkit-box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.09);
  max-width: 392px;
  overflow: hidden;
  .ci_img {
    width: 100%;
    height: 208px;
    object-fit: cover;
  }
  .blank_img {
    width: 100%;
    height: 208px;
    background: #ededed8c;
  }
  .ci_body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 208px);
    padding: 24px 16px 10px 16px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .group_top {
      .ci_title {
        margin-bottom: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.BROWN_2};
      }
      .ci_sub_title {
        // margin-bottom: 20px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.GRAY_20};
      }
    }
    .ci_view_more {
      text-align: right;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.PINK_8};
      height: 28px;
    }
    .blank_label {
      height: 28px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .ci_body {
      padding: 8px;
      .group_top {
        .ci_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          margin-bottom: 1px;
        }
        .ci_sub_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
          // margin-bottom: 6px;
        }
      }
      .ci_view_more {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .ci_img {
      height: 78px;
    }
    .blank_img {
      height: 78px;
    }
    .ci_body {
      height: calc(100% - 78px);
      padding: 8px;
      .group_top {
        .ci_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          margin-bottom: 1px;
        }
        .ci_sub_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
          // margin-bottom: 6px;
        }
      }
      .ci_view_more {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
      }
    }
  }
`;
