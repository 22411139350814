import styled from 'styled-components';

export const QualificationExaminationContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  height: calc(100vh - 80px);
  background-image: url('/assets/images/icons/dot_bg.png');
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top 0;
  overflow-y: auto;
  .layout_detail {
    max-width: 400px;
    .img_logo {
    }
    .txt_detail {
      color: #003564;
      font-size: 18px;
      margin-top: 20px;
      max-width: 400px;
    }
    .txt_detail2 {
      color: grey;
      font-size: 16px;
      margin-top: 10px;
      max-width: 400px;
    }
    .header_layout {
      width: 90vw;
    }
    .btn_next {
      width: 350px;
      height: 45px;
      margin-top: 30px;
      background-color: #3b96b7;
      &.herwill {
        background-color: ${({ theme }) => theme.COLORS.PINK_1};
      }

      font-size: 18px;
      border: none;
      border-radius: 30px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn_next_white {
      width: 350px;
      height: 45px;
      margin-top: 20px;
      background-color: #f8f8f8;
      box-shadow: 2px 1px 6px 0px rgba(0, 0, 0, 0.2),
        0 4px 20px 0 rgba(0, 0, 0, 0.19);

      font-size: 18px;
      border: none;
      border-radius: 30px;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
    .layout_detail {
      width: 100vw;
      display: flex;
      align-items: center;
      flex-direction: column;
      .btn_next {
        width: 90vw;
        height: 40px;
        margin-top: 30px;
        background-color: #3b96b7;

        font-size: 18px;
        border: none;
        border-radius: 30px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn_next_white {
        width: 90vw;
        height: 40px;
      }
    }
  }
`;
