import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authenReducer from './authenReducer';
import configProjectReducer from './configProjectReducer';
import cookingPermissionReducer from './cookingPermissionReducer';
import hospitalReducer from './hospitalReducer';

const rootReducer = combineReducers({
  form: formReducer,
  authenRedux: authenReducer,
  configProjectRedux: configProjectReducer,
  cookiePermissionRedux: cookingPermissionReducer,
  hospitalListRedux: hospitalReducer,
});

export default rootReducer;
