import React, { useEffect, useState } from 'react';
import { InputNormalSelectStyled } from './styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import cx from 'classnames';

import { Icons } from 'components';

const InputNormalSelect = ({
  theme_standard,
  theme_pink,
  value,
  onChange,
  placeholder,
  endPoint,
  isInitial,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_pink: theme_pink,
  });

  const [_options, _setOptions] = useState({
    page: { offset: 0, per_page: 20 },
    data: [],
    hasMore: true,
  });
  const [_active, _setActive] = useState(false);
  const [_value, _setValue] = useState(placeholder ? placeholder : 'หมวดหมู่');

  useEffect(() => {
    _fetchData();
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const _fetchData = async (
    stringQuery = `?per_page=${_options.page.per_page}&offset=${_options.page.offset}`,
    isLoadMore
  ) => {
    if (!isLoadMore) {
      if (!_options || _options.data.length === 0) {
        let res = await endPoint(stringQuery);
        if (res && res.success) {
          _setOptions({
            data: res.data.map((e) => ({ label: e.name_th, value: e.uuid })),
            page: {
              offset: res.page.from - 1,
              per_page: res.page.per_page,
            },
            hasMore: res.data.length < res.page.per_page ? false : true,
          });
          if (value && value !== _value) {
            let find = res.data.find((e) => e.uuid === value);
            _setValue(find ? find.name_th : value);
            if (!isInitial) {
              onChange(
                find ? { label: find.name_th, value: find.uuid } : value
              );
            }
          }
        }
      } else {
        if (value && value !== _value) {
          let find = _options.data.find((e) => e.value === value);
          _setValue(find ? find.label : value);
          // if (!isInitial) {
          //   onChange(find ? { label: find.name_th, value: find.uuid } : value);
          // }
        }
      }
    } else {
      let res = await endPoint(stringQuery);
      if (res && res.success) {
        if (!res.data || res.data.length === 0) {
          _setOptions({
            ..._options,
            hasMore: false,
          });
        } else {
          _setOptions({
            ..._options,
            data: [
              ..._options.data,
              ...res.data.map((e) => ({ label: e.name_th, value: e.uuid })),
            ],
            page: {
              offset: res.page.from - 1,
              per_page: res.page.per_page,
            },
            hasMore: res.data.length < res.page.per_page ? false : true,
          });
        }
      }
    }
  };

  const _fetchMoreData = () => {
    _fetchData(
      `?per_page=${_options.page.per_page}&offset=${
        _options.page.offset + _options.page.per_page + 1
      }`,
      true
    );
  };

  const _handleClick = () => {
    _setActive(!_active);
  };

  const handleSelect = (e) => {
    _setValue(e.label);
    _setActive(false);
    onChange(e);
  };

  return (
    <InputNormalSelectStyled>
      <div className={customClass}>
        <div className="body_dropdown" onClick={_handleClick}>
          <div className="label_show">{_value}</div>
          {theme_pink ? (
            <>
              <div className="d_arrow">
                {_active ? (
                  <Icons.ArrowPinkUpDown />
                ) : (
                  <Icons.ArrowPinkUpDown isUp />
                )}
              </div>
              <div className="d_arrow mobile">
                {_active ? (
                  <Icons.ArrowPinkUpDown width="14" height="14" />
                ) : (
                  <Icons.ArrowPinkUpDown isUp width="14" height="14" />
                )}
              </div>
            </>
          ) : (
            <div className="d_arrow">
              {_active ? <Icons.ArrowVUp /> : <Icons.ArrowVDown />}
            </div>
          )}
        </div>
        {_active && (
          <div className="list_dropdown">
            <InfiniteScroll
              dataLength={_options.data.length}
              next={_fetchMoreData}
              hasMore={_options.hasMore}
              loader={<h3 className="loading">Loading...</h3>}
              height={220}
              endMessage={
                <p className="loading">{/* <b>ดูครบทั้งหมดแล้ว</b> */}</p>
              }
            >
              {_options &&
                _options.data.length > 0 &&
                _options.data.map((e, i) => (
                  <div
                    key={i + 1}
                    className="dropdown_item"
                    onClick={() => handleSelect(e)}
                  >
                    {e.label}
                  </div>
                ))}
            </InfiniteScroll>
          </div>
        )}
      </div>
    </InputNormalSelectStyled>
  );
};

export default InputNormalSelect;
