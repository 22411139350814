import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { withRouter } from 'navigations/withRouter';
import { QualificationExaminationContainerStyled } from './styled';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { calAge } from 'utils/functions/calsAge';
import { Displays } from 'components';

class QualificationExaminationContainer extends React.Component {
  state = {
    scheme: this.props.configProjectRedux.scheme === 'herwill' && 'herwill',
  };

  componentDidMount() {
    this.fetchData();

    // Tracking user pageview
    ReactGA.set({ page: this.props.router.location.pathname });
    ReactGA.pageview(this.props.router.location.pathname);
  }

  fetchData = () => {
    this.checkCondition();
  };

  checkCondition = () => {
    const { authRedux } = this.props;
    if (calAge(authRedux.birthday) < 16 || authRedux.gender === 'male') {
      // Log reject due to under age
      ReactGA.event({
        category: 'exit',
        action: 'reject_pinkalert_underage',
      });
      this.handleRenderQuestion(1, false);
    } else {
      if (authRedux.gender === 'female') {
        this.handleRenderQuestion(2, false);
      } else {
        this.handleClickNext();
      }
    }
  };

  handleRenderQuestion = (key, value) => {
    const { authRedux } = this.props;
    const { scheme } = this.state;
    switch (key) {
      case 1:
        if (!value) {
          this.setState({
            renderQuestion:
              authRedux.gender === 'female' ? (
                <div className="txt_detail">
                  Well ขอขอบคุณในความสนใจการตรวจหาแนวโน้มมะเร็ง
                  เหมาะสำหรับบุคคลที่อายุ 16 ปีขึ้นไป
                </div>
              ) : (
                <div className="txt_detail">
                  เพศของคุณไม่สามารถประเมิณความเสี่ยงนี้ได้
                </div>
              ),
            renderChoice: (
              <button
                className={`btn_next ${scheme}`}
                onClick={this.handleClickToHome}
              >
                ยืนยัน
              </button>
            ),
          });
        }
        break;
      case 2:
        if (!value) {
          this.setState({
            renderQuestion: (
              <div className="txt_detail">คุณกำลังสงสัยว่าตั้งครรภ์หรือไม่</div>
            ),
            renderChoice: (
              <>
                <button
                  className={`btn_next ${scheme}`}
                  onClick={() => this.handleRespondQuestionFamale(true)}
                >
                  ใช่
                </button>
                <button
                  className="btn_next_white"
                  onClick={() => this.handleRespondQuestionFamale(false)}
                >
                  ไม่ใช่
                </button>
              </>
            ),
          });
        } else {
          this.setState({
            renderQuestion: (
              <>
                <div className="txt_detail">
                  Well ต้องขออภัยถ้าตอนนี้คุณกำลังตั้งครรภ์ Well
                  ไม่สามารถให้บริการคุณได้
                </div>
                <div className="txt_detail2">
                  (เพราะอาจเกิดความคลาดเคลื่อนในการหาแนวโน้ม)
                </div>
              </>
            ),
            renderChoice: (
              <button
                className={`btn_next ${scheme}`}
                onClick={this.handleClickToHome}
              >
                ยืนยัน
              </button>
            ),
          });
        }
        break;
      default:
        break;
    }
  };

  handleRespondQuestionFamale = (e) => {
    if (e) {
      // Log reject due to pregnant
      ReactGA.event({
        category: 'exit',
        action: 'reject_pinkalert_pregnant',
      });
      this.handleRenderQuestion(2, true);
    } else {
      this.handleClickNext();
    }
  };

  handleClickToHome = () => {
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
      this.props.router.navigate('/');
    }, Math.floor(Math.random() * 1000) + 100);
  };

  handleClickNext = () => {
    this.setState({
      loading: true,
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
      this.props.router.navigate(ROUTE_PATH.QUESTIONNAIRE);
    }, Math.floor(Math.random() * 500) + 100);
  };

  render() {
    const { renderQuestion, renderChoice, loading } = this.state;
    return (
      <QualificationExaminationContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <Displays.LogoLoadingFade />
          </div>
        ) : (
          <>
            <div className="layout_detail">
              <img
                className="img_logo"
                src={'/assets/images/icons/logo_question.png'}
                alt="logo well"
              />
              <div className="question_wrap">{renderQuestion}</div>
              <div className="choice_wrap">{renderChoice}</div>
            </div>
          </>
        )}
      </QualificationExaminationContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.authenRedux,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(QualificationExaminationContainer));
