import axios from 'axios';
import { BASE_HERWILL_API, BASE_PATH_API } from './apiConfig';

const getConfig = (token) => {
  const config = {
    baseURL: BASE_HERWILL_API + BASE_PATH_API,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return config;
};

const getConfigFormData = (token, callback) => {
  const config = {
    baseURL: BASE_HERWILL_API + BASE_PATH_API,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log('upload percent', percentCompleted);
      callback && callback(percentCompleted);
    },
  };
  return config;
};

const axiosSuccess = (result) => {
  // console.log('axiosSuccess =>', result);
  return result.data;
};

const axiosError = (error) => {
  if (error) {
    return error?.response?.status;
  } else {
    return error;
  }
};

const axiosService = async (type, url, params, callback) => {
  const config = getConfig(localStorage.getItem('herwill_token'));
  let configFormData = getConfigFormData(
    localStorage.getItem('herwill_token'),
    callback
  );
  switch (type) {
    case 'get':
      return axios.get(url, config).then(axiosSuccess).catch(axiosError);
    case 'post':
      return axios
        .post(url, params, config)
        .then(axiosSuccess)
        .catch(axiosError);
    case 'put':
      return axios
        .put(url, params, config)
        .then(axiosSuccess)
        .catch(axiosError);
    case 'patch':
      return axios
        .patch(url, params, config)
        .then(axiosSuccess)
        .catch(axiosError);
    case 'delete':
      return axios
        .delete(url, { ...config, data: params })
        .then(axiosSuccess)
        .catch(axiosError);
    case 'post_formdata':
      return axios
        .post(url, params, configFormData)
        .then(axiosSuccess)
        .catch(axiosError);
    default:
      return false;
  }
};

const getConfigCustom = (baseUrl, token) => {
  const config = {
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token}`,
    },
  };
  return config;
};

const axiosServiceCustom = (type, baseUrl, url) => {
  // session storage
  let config = getConfigCustom(baseUrl, localStorage.getItem('herwill_token'));
  switch (type) {
    case 'get':
      return axios.get(url, config).then(axiosSuccess).catch(axiosError);
    default:
      return false;
  }
};

const Export = {
  get: (url, params) => axiosService('get', url, params),
  post: (url, params) => axiosService('post', url, params),
  put: (url, params) => axiosService('put', url, params),
  delete: (url, params) => axiosService('delete', url, params),
  post_formdata: (url, params, callback) =>
    axiosService('post_formdata', url, params, callback),
  patch: (url, params) => axiosService('patch', url, params),

  get_base: (baseUrl, url, params) =>
    axiosServiceCustom('get', baseUrl, url, params),
};

export default Export;
