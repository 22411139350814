import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { LocationNearContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { userService } from 'apiService';
import InfiniteScroll from 'react-infinite-scroll-component';
import theme from 'styles/theme.json';
import { HopitalItem, HopitalItemType2, MyAddressLocation } from 'widgets';
import { Icons, Buttons, Displays } from 'components';

class LocationNearContainer extends React.Component {
  state = {
    mammogramData: [],
    lat: null,
    long: null,
    amphoeData: [],
    provinceData: [],
    pagination: {
      offset: 0,
      per_page: 5,
    },
    provinceIDSelected: 10,
    isDesktop: window.matchMedia(`(max-width: ${theme.SCREENS.MINI_TABLET})`)
      .matches
      ? false
      : true,
  };

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.router.location.pathname });
    ReactGA.pageview(this.props.router.location.pathname);
    this.setState({
      loading: true,
    });
  }

  onGetCurrentPosition = async (resPosition) => {
    if (resPosition && resPosition.status) {
      switch (resPosition.type) {
        case 'amphoeID':
          let resAmphoeID = await this.fetchMammogramCenter(
            `?per_page=5&offset=0&amphoe_id=${resPosition.amphoeID}`
          );
          if (resAmphoeID) {
            this.setState({
              loading: false,
              queryString: `amphoe_id=${resPosition.amphoeID}`,
            });
          }
          break;
        case 'latln':
          let resLatLng = await this.fetchMammogramCenter(
            `?per_page=5&offset=0&latitude=${resPosition.lat}&longitude=${resPosition.lng}`
          );
          if (resLatLng) {
            this.setState({
              loading: false,
              queryString: `latitude=${resPosition.lat}&longitude=${resPosition.lng}`,
            });
          }
          break;
        default:
          break;
      }
    } else {
      let res = await this.fetchMammogramCenter('?per_page=5&offset=0');
      if (res) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  fetchMammogramCenter = async (queryString, isNew) => {
    const {
      pagination: { per_page },
    } = this.state;
    let res = await userService.GET_MAMMOGRAM_CENTERS_LIST(queryString);
    if (res && res.success) {
      if (!res.data || res.data.length === 0) {
        this.setState({
          hasMore: false,
        });
      } else {
        this.setState((state) => ({
          mammogramData: isNew
            ? res.data
            : [...state.mammogramData, ...res.data],
          hasMore: isNew
            ? true
            : res.data.length < Number(per_page)
            ? false
            : true,
          pagination: { ...res.page, per_page: Number(res.page.per_page) },
        }));
      }
      return res.data;
    }
  };

  handleClickBack = () => {
    this.props.router.navigate(-1);
  };

  handleClickLandingPage = () => {
    this.props.router.navigate(-1);
  };

  handleSelectedAmphoe = async (e) => {
    let res = await this.fetchMammogramCenter(
      `?per_page=5&offset=0&amphoe_id=${e}`,
      true
    );
    if (res) {
      this.setState({
        loading: false,
        queryString: `amphoe_id=${e}`,
      });
    }
  };

  fetchMoreData = () => {
    const { queryString } = this.state;
    this.setState(
      (state) => ({
        pagination: {
          offset: state.pagination.per_page * state.pagination.current_page,
          per_page: state.pagination.per_page,
        },
      }),
      () => {
        this.fetchMammogramCenter(
          `?per_page=${this.state.pagination.per_page}&offset=${
            this.state.pagination.offset
          }${queryString ? `&${queryString}` : ''}`
        );
      }
    );
  };

  render() {
    const { mammogramData, hasMore, loading } = this.state;
    return (
      <LocationNearContainerStyled>
        <div className={`loader_layout ${loading ? 'active' : ''}`}>
          <Displays.LogoLoadingFade />
        </div>
        <InfiniteScroll
          dataLength={mammogramData.length}
          next={this.fetchMoreData}
          hasMore={hasMore}
          loader={<h4 className="loading">Loading...</h4>}
          height={'calc(100vh - 102px)'}
          endMessage={
            <p className="loading">
              <b>ดูครบทั้งหมดแล้ว</b>
            </p>
          }
        >
          <div className="is_desktop">
            <div className="btn_back_wrap" onClick={this.handleClickBack}>
              <Buttons.BackBtn
                theme_gray
                txt="ย้อนกลับ"
                icon={<Icons.Back color="#6F7070" />}
              />
            </div>
            <div className="location_container">
              <div className="title">รายชื่อโรงพยาบาลที่อยู่ใกล้คุณ</div>
              <MyAddressLocation
                onGetCurrentPosition={this.onGetCurrentPosition}
                onChangeAmphoe={this.handleSelectedAmphoe}
              />
              <div className="title_sec_2">โรงพยาบาลที่อยู่ใกล้คุณ</div>
              <div className="row_2_col">
                {mammogramData &&
                  mammogramData.map((e, i) => (
                    <div className="item_wrap" key={i}>
                      <HopitalItemType2
                        name={e.name}
                        image_uri={e.image_uri}
                        province={e.amphoe?.changwat?.name_th}
                        amphoe={e.amphoe?.name_th}
                        distance={e.distance}
                        phone_no={e.phone_no}
                      />
                    </div>
                  ))}
                {mammogramData && mammogramData.length % 2 !== 0 && (
                  <div className="item_wrap"></div>
                )}
              </div>
            </div>
          </div>
          <div className="is_mobile">
            <div
              className="bg_top"
              style={{
                backgroundImage: `url('/assets/images/backgrounds/bg_hos.jpg')`,
              }}
            />
            <div className="container">
              <div className="m_btn_back_wrap" onClick={this.handleClickBack}>
                <Buttons.BackBtn txt="ย้อนกลับ" icon={<Icons.Back />} />
              </div>
              <div className="m_title">รายชื่อโรงพยาบาลที่อยู่ใกล้คุณ</div>
              <MyAddressLocation
                onGetCurrentPosition={this.onGetCurrentPosition}
                onChangeAmphoe={this.handleSelectedAmphoe}
              />
              <div className="m_title_sec_2">โรงพยาบาลที่อยู่ใกล้คุณ</div>
              <div className="m_row">
                {mammogramData &&
                  mammogramData.map((e, i) => (
                    <div key={i} className="m_item_wrap">
                      <HopitalItem
                        name={e.name}
                        image_uri={e.image_uri}
                        province={e.amphoe?.changwat?.name_th}
                        amphoe={e.amphoe?.name_th}
                        distance={e.distance}
                        phone_no={e.phone_no}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </InfiniteScroll>
        <div className="btn_layout">
          <button className="btn_end" onClick={this.handleClickLandingPage}>
            ย้อนกลับ
          </button>
        </div>
      </LocationNearContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LocationNearContainer));
