import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';

const bounceAnimation = keyframes`${fadeInUp}`;

export const TextAndButtonFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  width: 320px;
  margin-left: 10px;
  .img_logo {
    width: 50px;
    height: 50px;
  }
  .txt_detail {
    color: #003564;
    font-size: 18px;
    margin-top: 20px;
    max-width: 400px;
  }
  .txt_detail2 {
    color: grey;
    font-size: 16px;
    margin-top: 10px;
    max-width: 400px;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    color: #2f4f74;
    margin: 20px 0 0 10px;
    animation: 2s ${bounceAnimation};
  }
  .title2 {
    font-size: 14px;
    color: #777777;
    margin: -3px 0 0 10px;
    animation: 2s ${bounceAnimation};
  }
  .button_layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    animation: 2s ${bounceAnimation};
  }

  .txt_input {
    height: 20px;
  }
  .error {
    color: red;
    margin-top: 5px;
  }
  .btn_bottom {
    height: 100px;
  }
  .question_layout {
    animation: 2s ${bounceAnimation};
  }
  .theme_standard {
    margin-left: 10px;
  }
  .theme_border_question {
    width: 130px;
    display: flex;
    margin-left: 10px;
    justify-content: center;
    .btn_layout {
      width: 130px;
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
    width: 300px;
    .button_layout {
      margin-left: 10px;
    }
    .theme_border_question {
      width: 130px;
      display: flex;
      justify-content: center;
      margin-left: 0px;

      .btn_layout {
        width: 130px;
      }
    }
    .theme_standard {
      margin-left: 0px;
    }
  }
`;
