import styled from 'styled-components';

export const InputNormalSelectStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  position: relative;
  .body_dropdown {
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    border-color: ${({ theme }) => theme.COLORS.PINK_1};
    width: 230px;
    background: ${({ theme }) => theme.COLORS.PINK_1};
    font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    position: relative;
    .d_arrow {
      position: absolute;
      right: 17px;
      top: 13px;
    }
    .label_show {
      width: 87%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .list_dropdown {
    position: absolute;
    top: 110%;
    border-radius: 6px;
    -webkit-box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.12);
    padding: 10px 0;
    min-width: 272px;
    z-index: 1;
    background: white;
    margin-top: -1px;
    overflow-y: auto;
    position: absolute;
    max-width: 270px;
    .dropdown_item {
      cursor: pointer;
      padding: 6px 16px;
      &:hover {
        background: #5d5df21a;
      }
    }
    .infinite-scroll-component {
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
    }
  }

  .loading {
    margin-top: 10px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PINK_1};
  }

  .theme_standard {

  }
  .theme_pink {
    .body_dropdown {
      border-radius: 4px;
      width: 350px;
      background: ${({ theme }) => theme.COLORS.PINK_9};
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
      color: ${({ theme }) => theme.COLORS.BROWN_2};
      .d_arrow {
        &.mobile {
          display: none;
        }
      }
    }
    .list_dropdown {
      max-width: unset;
      width: 100%;
      background: ${({ theme }) => theme.COLORS.PINK_9};
      color: ${({ theme }) => theme.COLORS.GRAY_20};
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .theme_pink {
      .body_dropdown {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
      }
      .list_dropdown {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    width: 100%;
    .body_dropdown {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
      text-align: center;
      position: relative;
      // width: 115px;
      min-width: 0px;
      white-space: nowrap;
      .d_arrow {
        position: absolute;
        right: 15px;
        top: 12px;
      }
      .label_show {
        width: 74%;
      }
    }
    .list_dropdown {
      left: -159px;
      max-width: 274px;
      .dropdown_item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .theme_pink {
      .body_dropdown {
        width: 100%;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        .d_arrow {
          display: none;
          &.mobile {
            display: block;
          }
        }
      }
      .list_dropdown {
        left: 0px;
        min-width: 100px;
        width: 100%;
        top: 114%;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      }
    }
  }
`;
