import React from 'react';

export default function AaPlatform({
  width = "68.401",
  height = "62.982",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 68.401 62.982"
    >
      <defs>
        <filter
          id="Rectangle_31202"
          x="0"
          y="-0.018"
          width="68"
          height="63"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur" />
          <feFlood flood-opacity="0.051" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Union_351"
          x="0"
          y="0"
          width="68.401"
          height="62.982"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur-2" />
          <feFlood flood-opacity="0.051" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_351)">
        <path
          id="Union_351-2"
          data-name="Union 351"
          d="M20.81,53a1.107,1.107,0,0,1-.094-.194l-3.167-8.7-1.143,3.44a1.064,1.064,0,0,1-1,.713H2.051s-.669,0-.891-.022A3.393,3.393,0,0,1,0,47.9v-.347l.856-1.394,9.553-15.622a.59.59,0,0,1,.807-.189L15.41,32.9a.548.548,0,0,1,.262.366.607.607,0,0,1-.062.451l-3,4.886a29.493,29.493,0,0,1-6.269,7.549h8.315l1.856-5.577a1.064,1.064,0,0,1,.986-.714,1.032,1.032,0,0,1,1,.692L21.7,49.378l3.208-8.819a1.012,1.012,0,0,1,1-.692,1.063,1.063,0,0,1,.985.714l1.856,5.577h6.586a1.049,1.049,0,0,1,0,2.1h-7.34a1.064,1.064,0,0,1-1-.713L25.853,44.1l-3.166,8.7a1.05,1.05,0,0,1-.094.194Zm6.134-22.633c0-4.76,3.156-8.347,7.339-8.347A9.4,9.4,0,0,1,41.8,25.837a1.038,1.038,0,0,0,1.741,0,9.4,9.4,0,0,1,7.518-3.817c4.184,0,7.34,3.587,7.34,8.347,0,5.788-5.139,11.628-15.739,17.857C32.082,41.995,26.944,36.155,26.944,30.367Zm2.1,0a1.049,1.049,0,1,0,2.1,0c0-2.013,1.1-4.153,3.145-4.153a1.049,1.049,0,1,0,0-2.1C30.876,24.117,29.041,27.337,29.041,30.367ZM13.2,29.111l1.472-2.4,2.4,1.472-1.471,2.4Zm5.473-2.425L15.265,24.6a1.049,1.049,0,0,1-.351-1.436L28.929.524A1.048,1.048,0,0,1,30.371.176l3.411,2.086A1.048,1.048,0,0,1,34.131,3.7L20.118,26.34a1.048,1.048,0,0,1-1.441.345Z"
          transform="translate(3 2.98)"
          fill="#9bdeac"
          stroke="rgba(0,0,0,0)"
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
    </svg>
  );
}
