import styled from 'styled-components';

export const FilterVideoStyled = styled.div`
  .fv_form {
    display: flex;
    align-items: center;
    column-gap: 16px;
    .logo {
      width: 184px;
      height: auto;
    }
    .f_label_show {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
      // font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
      color: ${({ theme }) => theme.COLORS.BROWN_2};
      white-space: nowrap;
    }
    .cate_label {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGUALR};
      color: ${({ theme }) => theme.COLORS.GRAY_20};
      white-space: nowrap;
    } 
    .search_wrap {
      width: 100%;
      max-width: 368px;
    }
  }
  
  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .fv_form {
      .f_label_show {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
        // font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
        color: ${({ theme }) => theme.COLORS.BROWN_2};
        white-space: nowrap;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .fv_form {
      flex-direction: ${({ labelType }) => labelType === 'logo' ? 'column' : 'row'};
      .f_label_show {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        // font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
        color: ${({ theme }) => theme.COLORS.BROWN_2};
        white-space: nowrap;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .fv_form {
      .f_label_show {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGUALR};
        color: ${({ theme }) => theme.COLORS.BROWN_2};
        white-space: nowrap;
      }
      .cate_label {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGUALR};
      } 
      .dropdown_wrap {
        width: 100%;
      }
    }
  }
`;
