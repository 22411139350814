import styled from 'styled-components';

export const PackageList2Styled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: 100%;
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    width: 100%;
    font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    .title {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
      font-weight: 800;
      margin-bottom: 10px;
    }
    .package_show {
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_12};
      padding-bottom: 30px;
      margin-bottom: 16px;
    }
    .group_package {
      width: 100%;
      .title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
        font-weight: 600;
        margin-bottom: 10px;
      }
      .list_package {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .body_package {
          width: 49%;
          margin: 5px 0px;
        }
        .img_package {
          width: 100%;
          height: auto;
        }
      }
      .btn2 {
        max-width: 298px;
        width: 100%;
        margin: auto;
        margin-top: 30px;
      }
    }
  }

  .theme_standard_pink {
    // cursor: pointer;
    width: 100%;

    font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
    color: ${({ theme }) => theme.COLORS.BLUE_2};
    .title {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
      font-weight: 800;
      margin-bottom: 10px;
    }
    .package_show {
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_12};
      padding-bottom: 30px;
      margin-bottom: 16px;
    }
    .group_package {
      width: 100%;
      .title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
        font-weight: 600;
        margin-bottom: 10px;
      }
      .list_package {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // margin-bottom: -4px;
        .body_package {
          width: 49%;
          margin: 5px 0px;
        }
        .img_package {
          width: 100%;
          height: auto;
        }
      }
      .btn2 {
        max-width: 298px;
        width: 100%;
        margin: auto;
        margin-top: 30px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .theme_standard {
      // background: pink;
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .theme_standard_pink {
      .title {
        text-align: center;
        padding-top: 10vw;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
    .theme_standard {
      // background: gold;
      .title {
        text-align: center;
      }
      .package_show {
        border-bottom: none;
        padding-bottom: 0px;
        margin-bottom: 30px;
      }
      .group_package {
        .title {
          text-align: left;
        }
        .list_package {
          .body_package {
            width: 100%;
          }
        }
        .btn2 {
          max-width: unset;
          margin-top: 20px;
        }
      }
    }
    .theme_standard_pink {
      // background: gold;
      .title {
        text-align: center;
      }
      .package_show {
        border-bottom: none;
        padding-bottom: 0px;
        margin-bottom: 30px;
      }
      .group_package {
        .title {
          text-align: left;
        }
        .list_package {
          .body_package {
            width: 100%;
          }
        }
        .btn2 {
          max-width: unset;
          margin-top: 20px;
        }
      }
    }
  }
`;
