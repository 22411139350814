import React from 'react';
import { connect } from 'react-redux';
import { PinkAlertContainerStyled } from './styled';
import { setReduxUserAuth } from 'store/actions';
import { setReduxCookiePermission } from 'store/actions';
import { withRouter } from 'navigations/withRouter';
import { Element } from 'react-scroll';
import ReactGA from 'react-ga';
import { ROUTE_PATH } from 'utils/constants/routePath';
import {
  NavbarPinkAlertWidget,
  BannerCarouselWidget,
  KnowledWidget,
  ContentWidget,
  HerwillCampaignWidget,
  PartnerWidget,
  VideoRecWidget,
  BannerPopup,
  TreatmentGuildlineWidget,
  TelemedicineWidget,
  TestShortcutWidget,
} from 'widgets';
import { Modals, Displays } from 'components';
import { herwillService } from 'apiService';

class PinkAlertContainer extends React.Component {
  state = {
    isLoading: false,
    dataContentList: [],
  };

  componentDidMount() {
    // this.clearToken();
    this.renderModalFirst();
    this.fetchContentList();

    // Tracking user pageview
    ReactGA.set({ page: this.props.router.location.pathname });
    ReactGA.pageview(this.props.router.location.pathname);

    ReactGA.event({
      category: this.props.configProjectRedux.scheme + '_landing',
      action: this.props.configProjectRedux.scheme + '_view',
    });
  }

  fetchContentList = async (query) => {
    let res = await herwillService.GET_CONTENT_LIST('?type=content');
    // console.log('list content', res)
    if (res && res.success) {
      this.setState({
        dataContentList: res.data,
      });
    }
  };

  clearToken = () => {
    // sessionStorage.clear();
    localStorage.clear();
  };

  handleClickSetQuick = () => {
    ReactGA.event({
      category: this.props.configProjectRedux.scheme + '_start',
      action: this.props.configProjectRedux.scheme + '_start_new_sess',
    });
    this.props.router.navigate(ROUTE_PATH.AUTO_SIGNUP);
  };

  renderModalFirst = () => {
    const { cookiePermissionRedux } = this.props;
    if (!cookiePermissionRedux) {
      this.setState({
        isModal: true,
        renderModal: (
          <BannerPopup handleClickCloseModal={this.handleClickCloseModal} />
        ),
      });
    }
  };

  handleClickCloseModal = () => {
    this.props.setReduxCookiePermission(true);
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };

  render() {
    const { isModal, renderModal, isLoading, dataContentList } = this.state;
    return (
      <PinkAlertContainerStyled>
        <div className="navbar_pink_wrap">
          <NavbarPinkAlertWidget />
        </div>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <div className="body_widget_show">
            <Element name="home">
              <BannerCarouselWidget />
            </Element>
            <KnowledWidget />
            <TelemedicineWidget />
            <Element name="video">
              <VideoRecWidget />
            </Element>
            <Element name="content">
              <ContentWidget contentList={dataContentList} />
            </Element>
            <Element name="treatment">
              <TreatmentGuildlineWidget />
            </Element>
            <HerwillCampaignWidget />
            <PartnerWidget showLabel />
            <TestShortcutWidget onClickSetQuck={this.handleClickSetQuick} />
            <div className="label_number">M-TH-00002352</div>
          </div>
        )}
        <Modals.Modal
          theme_top_layout
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
          bgColor="rgba(0, 0, 0, 0.8)"
        >
          {renderModal}
        </Modals.Modal>
      </PinkAlertContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  configProjectRedux: state.configProjectRedux,
  cookiePermissionRedux: state.cookiePermissionRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
  setReduxCookiePermission: (data) => dispatch(setReduxCookiePermission(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PinkAlertContainer));
