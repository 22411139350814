import React from 'react';
import { BackBtnStyled } from './styled';
import cx from 'classnames';

const BackBtn = ({ theme_standard, theme_gray, txt, icon }) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_gray: theme_gray,
  });
  return (
    <BackBtnStyled>
      <div className={customClass}>
        <div className="btn_container">
          <div className="icon">{icon}</div>
          <div className="txt">{txt}</div>
        </div>
      </div>
    </BackBtnStyled>
  );
};

export default BackBtn;
