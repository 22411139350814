import styled from 'styled-components';

export const RiskFactorContainerStyled = styled.div`
  .navbar_pink_wrap {
    position: sticky;
    top: 0px;
    z-index: 2000;
  }
  .risk_container {
    // .box_notic {
    //   position: fixed;
    //   bottom: 41px;
    //   right: -1px;
    //   padding-top: 70px;
    //   min-width: 170px;
    //   height: 135px;
    //   background-image: url(/assets/images/backgrounds/box_notic.png);
    //   background-position: center;
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   z-index: 99;
    //   cursor: pointer;
    //   text-align: center;
    //   font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
    //   font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
    //   color: ${({ theme }) => theme.COLORS.WHITE_1};
    // }
    .brand_show {
      background-image: url(/assets/images/backgrounds/risk_bg.png);
      background-color: #cccccc;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 550px;
      position: relative;
      .box_label {
        position: absolute;
        left: 10%;
        top: 10%;
        .title_show {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_55};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          color: ${({ theme }) => theme.COLORS.WHITE_1};
          text-align: center;
          .bigText {
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
            margin-left: 20px;
          }
        }
      }
      .risk_btn_block {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 40px;
        .icon_click_wrap {
          position: absolute;
          left: 5%;
          bottom: -19%;
          &.mobile {
            display: none;
          }
        }
      }
      .risk_hastag {
        text-align: center;
        margin-top: 20px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .more_label {
        margin-top: 30px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_26};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        text-align: center;
      }
    }
    .risk_body {
      background-image: url(/assets/images/backgrounds/risk_body_bg.png);
      background-color: #ffffff;
      background-size: contain;
      background-repeat: no-repeat;
      height: 131.7vw;
      position: relative;
      .ribbin {
        width: 103px;
        height: 103px;
        position: absolute;
        top: -60px;
        left: 3.5%;
      }
      .risk_sec_1 {
        padding-top: 2.3vw;
        width: 88vw;
        height: 69.5vw;
        margin-left:auto;
        .risk_title_1 {
          margin-bottom: 3.3vw;
          &.bottom_show {
            top: unset;
            bottom: 703px;
            left: 50px;
          }
          .top_risk_title_1 {
            margin-bottom: 1.1vw;
            display: flex;
            align-items: center;
            .icon_title_1 {
              display: flex;
              margin-right: 24px;
              &.mobile {
                display: none;
                margin-right: 7px;
              }
            }
            .zoom_icon {
              width: 48px;
              height: 48px;
              margin-right: 24px;
            }
            .label_title_1 {
              font-size: 2.8vw;
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
              color: ${({ theme }) => theme.COLORS.BROWN_2};
            }
          }
          .sub_title_1 {
            font-size: 1.52vw};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
            color: ${({ theme }) => theme.COLORS.BROWN_1};
          }
        }
        .risk_case {
          .box_label_top {
            margin-left: -28vw;
            position: relative;
            display: flex;
            justify-content: center;
            .yellow_label_1 {
              width: 36.7vw;
              height: auto;
            }
            .notic_1 {
              position: absolute;
              top: 20px;
              font-size: 1.4vw;
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
              color: ${({ theme }) => theme.COLORS.BROWN_1};
            }
          }
          .group_notic {
            margin-left: auto;
            padding: 0 15px;
            width: 70vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            .box_line {
              display: flex;
              column-gap: 50px;
              &.mb_4_vw {
                margin-bottom: 4vw;
              }
              &.mb_49 {
                // margin-bottom: 2.5vw;
                margin-bottom: 1.5vw;
              }
              .notic_show {
                max-width: 438px;
              }
            }
            .link_text {
              font-size: 1.05vw;
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
              color: ${({ theme }) => theme.COLORS.PINK_8};
              text-align: center;
            }
          }
        }
      }
      .risk_sec_2 {
        padding-top: 3vw;
        padding-left: 7vw;
        padding-right: 7vw;
        .risk_title_2 {
          margin-bottom: 3.3vw;
          &.bottom_show {
            top: unset;
            bottom: 703px;
            left: 50px;
          }
          .top_risk_title_2 {
            margin-bottom: 1.1vw;
            display: flex;
            align-items: center;
            .zoom_icon_bottom {
              width: 48px;
              height: 48px;
              margin-right: 24px;
            }
            .icon_title_2 {
              display: flex;
              margin-right: 24px;
              &.mobile {
                display: none;
                margin-right: 7px;
              }
            }
            .label_title_2 {
              font-size: 2.8vw;
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
              color: ${({ theme }) => theme.COLORS.BROWN_2};
            }
          }
          .sub_title_2 {
            font-size: 1.52vw};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
            color: ${({ theme }) => theme.COLORS.BROWN_1};
          }
        }
        .risk_case_bottom {
          .box_label_bottom {
            margin: auto;
            width: fit-content;
            position: relative;
            .yellow_label_2 {
              width: 36.7vw;
              height: auto;
            }
            .notic_2 {
              position: absolute;
              top: 24px;
              left: 7vw;
              font-size: 1.4vw;
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
              color: ${({ theme }) => theme.COLORS.BROWN_1};
            }
          }
          .group_notic_2 {
            top: 140px;
            .group_sym {
              display: flex;
              column-gap: 50px;
              .box_sym {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 15.4vw;
                .sym_img {
                  width: 100%;
                  height: auto;
                  &.moblie {
                    display: none;
                  }
                }
                .text_des {
                  margin-top: 10px;
                  text-align: center;
                  font-size: 1.27vw;
                  font-family: ${({ theme }) =>
                    theme.FONTS.STYLE.KANIT_SEMIBOLD};
                  color: ${({ theme }) => theme.COLORS.BROWN_1};
                  text-align: center;
                }
              }
            }
            .link_text_2 {
              margin-top: 49px;
              font-size: 1.05vw;
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
              color: ${({ theme }) => theme.COLORS.PINK_8};
              text-align: center;
            }
          }
        }
      }
      .show_branner {
        position: absolute;
        bottom: -10px;
        // right: 350px;
        width: 100%;
        justify-content: center;
      }
    }
    .mobile_risk_body {
      display:none;
      padding: 24px 18px;
      background-image: url(/assets/images/backgrounds/risk-mobile-bg_2.png);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      .ribbin_mobile {
        width: 103px;
        height: 103px;
      }
      .m_header_row {
        padding-top: 4px;
        .zoom_icon_bottom {
          width: 48px;
          height: 48px;
          margin-right: 24px;
          display: flex;
          align-items: center;
        }
        .mobile_icon_wrap {
          display: none;
        }
        .m_title {
          margin-bottom: 6px;
          margin-left: 7px;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_36};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
          color: ${({ theme }) => theme.COLORS.BROWN_2};
        }
      }
      .m_sub_title {
        margin-bottom: 22px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BROWN_1};
      }
      .m_title_highligh {
        margin: auto;
        margin-bottom: 22px;
        max-width: 565px;
        width: 100%;
        height: 72px;
        background-image: url(/assets/images/backgrounds/yellow_label_mobile.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BROWN_1};
      }
      .m_sec_1 {
        .m_header_row {
          .zoom_icon_bottom_mobile {
            width: 48px;
            height: 48px;
          }
        }
        .s1_body{
          .box_item_wrap{
            margin-bottom: 20px;
            &.mb_8 {
              margin-bottom: 8px;
            }
          }
        }
        .s1_footer {
          display: flex;
          align-items: center;
          .s1_f_link {
            flex: 1;
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_17};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
            color: ${({ theme }) => theme.COLORS.PINK_8};
          }
          .s1_f_img {
            flex-shrink: 0;
            width: 124px;
            height: auto;
          }
        }
      }
      .m_sec_2 {
        .m_header_row {
          display: flex;
          align-items: center;
          .zoom_icon_bottom {
            margin-right: 0px;
          }
        }
        .s2_body{
          .m_box_sym_item {
            display: flex;
            align-items: center;
            column-gap: 4px;
          }
          .m_sym_img {
            width: 223px;
            height: auto;
          }
          .m_text_des {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
            color: ${({ theme }) => theme.COLORS.BROWN_1};
          }
        }
        .s2_footer {
          display: flex;
          align-items: center;
          .s2_f_link {
            flex: 1;
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_17};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
            color: ${({ theme }) => theme.COLORS.PINK_8};
          }
          .s2_f_img {
            flex-shrink: 0;
            width: 124px;
            height: auto;
          }
        }
      }
      .m_partner_sec {
        margin-top: 14px;
        .box_line {
          margin-bottom: 18px;
          width: 100%;
          display: flex;
          justify-content: center;
          .line_show {
            border-top: 1px solid #BEA5AB80;
            height: 1px;
            width: 30%;
          }
        }
      }
    }
    .footer_layout {
      background: ${({ theme }) => theme.COLORS.WHITE_1};
    }
    .label_number {
      padding: 10px;
      padding-top: 2px;
      text-align: right;
      // background: #fff4f9;
      color: ${({ theme }) => theme.COLORS.GRAY_20};
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .risk_container {
      .brand_show {
        background-image: url(/assets/images/backgrounds/risk_bg_moblie.png);
        height: 786px;
        .box_label {
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          .title_show {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_45};
          }
        }
        .risk_btn_block {
          margin-top: 37%;
          .icon_click_wrap {
            left: 22%;
          }
        }
        .more_label {
          margin-top: 2%;
        }
      }
      .risk_body {
        display: none;
      }
      .mobile_risk_body {
        display: block;
        position: relative;
        .ribbin_mobile {
          width: 80px;
          height: 80px;
          position: absolute;
          top: -52px;
          left: 1.5%;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .risk_container {
      .brand_show {
        
        .box_label {
         
          .title_show {
            
          }
        }
        .risk_btn_block {

          .icon_click_wrap {
            left: 20%;
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .risk_container {
      // .box_notic {
      //   font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
      //   font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
      //   background-size: contain;
      //   min-width: 110px;
      //   padding-top: 68px;
      //   bottom: 0px;
      // }
      .brand_show {
        // height: 604px;
        height: 416px;
        .box_label {
          .title_show {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            text-align: center;
            .bigText {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
              margin-left: 20px;
            }
          }
        }
        .risk_btn_block {
          margin-top: 30%;
          .icon_click_wrap {
            display: none;
            &.mobile {
              display: block;
              left: 25%;
            }
          }
        }
        .risk_hastag {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        }
        .more_label {
          margin-top: 4%;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        }
      }
      .mobile_risk_body {
        .ribbin_mobile {
          width: 40px;
          height: 40px;
          top: -1.8%;
        }
        .m_header_row {
          display: flex;
          align-items: center;
          .zoom_icon_bottom {
            width: 17px;
            height: 17px;
          }
          .tablet_icon_wrap {
            display: none;
          }
          .mobile_icon_wrap {
            display: contents;
          }
          .m_title {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
          }
        }
        .m_sub_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        }
        .m_title_highligh {
          max-width: 288px;
          height: 37px;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        }
        .m_sec_1 {
          .s1_footer {
            .s1_f_link {
              padding-left: 20px;
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
            }
            .s1_f_img {
              width: 124px;
            }
          }
          .m_header_row {
            .zoom_icon_bottom_mobile {
              width: 17px;
              height: 17px;
            }
          }
        }
        .m_sec_2 {
          .s2_body {
            .m_sym_img {
              width: 140px;
              height: auto;
            }
            .m_text_des {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
            }
          }
          .s2_footer {
            .s2_f_link {
              padding-left: 20px;
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
            }
            .s2_f_img {
              width: 124px;
            }
          }
        }
        .m_partner_sec {
          margin-top: 14px;
          .box_line {
            margin-bottom: 8px;
            .line_show {
            }
          }
        }
      }
      .label_number {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
        // background: #fff4f9;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
    .risk_container {
      .box_notic {

      }
      .brand_show {

        .box_label {
          .title_show {

            .bigText {

            }
          }
        }
        .risk_btn_block {
          margin-top: 40%;
          .icon_click_wrap {

            &.mobile {
              left: 15%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 430px) {
    .risk_container {
      .box_notic {

      }
      .brand_show {

        .box_label {
          .title_show {

            .bigText {

            }
          }
        }
        .risk_btn_block {
          margin-top: 38.5%;
          .icon_click_wrap {

            &.mobile {
              left: 15%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .risk_container {
      .box_notic {

      }
      .brand_show {

        .box_label {
          .title_show {

            .bigText {

            }
          }
        }
        .risk_btn_block {
          .icon_click_wrap {

            &.mobile {
              left: 14%;
            }
          }
        }
      }
    }
  }
`;
