import styled from 'styled-components';

export const BoxLinkStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_standard_box_link {
    font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
    color: ${({ theme }) => theme.COLORS.GRAY_13};
    display: flex;
    cursor: pointer;
    .arrow_layer {
      margin-right: 5px;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
      font-weight: bold;
    }
    .show_label {
      display: flex;
      align-items: center;
    }
  }

  .theme_white_box_link {
    font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    display: flex;
    cursor: pointer;
    .arrow_layer {
      margin-right: 5px;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
      font-weight: bold;
    }
    .show_label {
      display: flex;
      align-items: center;
    }
  }
`;
