import React from 'react';

export default function AaPlatform({ color = '#ed799e' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16.001"
      viewBox="0 0 24 16.001"
    >
      <g
        id="Group_92676"
        data-name="Group 92676"
        transform="translate(-3307 23492)"
      >
        <path
          id="Path_109206"
          data-name="Path 109206"
          d="M22.4,3.192H1.6A1.6,1.6,0,1,1,1.6,0H22.4a1.6,1.6,0,1,1,0,3.192"
          transform="translate(3307 -23492)"
          fill={color}
        />
        <path
          id="Path_109207"
          data-name="Path 109207"
          d="M22.4,243.192H1.6A1.6,1.6,0,1,1,1.6,240H22.4a1.6,1.6,0,1,1,0,3.192"
          transform="translate(3307 -23719.191)"
          fill={color}
        />
        <path
          id="Path_109208"
          data-name="Path 109208"
          d="M22.4,123.192H1.6A1.6,1.6,0,1,1,1.6,120H22.4a1.6,1.6,0,1,1,0,3.192"
          transform="translate(3307 -23605.598)"
          fill={color}
        />
      </g>
    </svg>
  );
}
