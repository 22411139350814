import React, { useEffect, useRef, useState } from 'react';
import { PackageList2Styled } from './styled';
import cx from 'classnames';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { RenderCommaMoney } from 'utils/functions/FormatMoney';
import { Displays, Buttons } from 'components';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const PackageList2 = ({
  theme_standard,
  theme_standard_pink,
  handleClickBackFirstPage,
  dataCheckup,
  handleClickServiceOrder,
  indexArrayData,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_pink: theme_standard_pink,
  });

  const CarouselRef = useRef();
  const [_dataDetail, _setDataDetail] = useState();

  useEffect(() => {
    if (dataCheckup && CarouselRef && CarouselRef.current) {
      CarouselRef.current.goToSlide(indexArrayData, true);
      _setDataDetail(dataCheckup[indexArrayData]);
    }
  }, [dataCheckup, CarouselRef, indexArrayData]);

  const selectData = (e) => {
    _setDataDetail(dataCheckup[e]);
  };

  const CustomDot = ({ onClick, active, index }) => {
    return (
      <li>
        <button
          style={{
            borderTopLeftRadius: index === 0 || active ? 4 : 0,
            borderBottomLeftRadius: index === 0 || active ? 4 : 0,
            borderTopRightRadius:
              index === dataCheckup.length - 1 || active ? 4 : 0,
            borderBottomRightRadius:
              index === dataCheckup.length - 1 || active ? 4 : 0,
            height: 8,
            border: 0,
            background: active ? '#CF1A71' : '#CDD5D8',
          }}
          onClick={() => onClick()}
        ></button>
      </li>
    );
  };

  return (
    <PackageList2Styled>
      <div className={customClass}>
        <div className="title">แพ็คเกจตรวจคัดกรองมะเร็ง</div>
        <div className="package_show">
          {dataCheckup && (
            <Carousel
              // infinite
              responsive={responsive}
              ref={CarouselRef}
              showDots
              customDot={<CustomDot />}
              afterChange={(previousSlide, { currentSlide, onMove }) => {
                selectData(currentSlide);
              }}
            >
              {dataCheckup &&
                dataCheckup.map((e, i) => (
                  <div
                    key={i}
                    className="box_package_wrapF"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={dataCheckup && dataCheckup.redirect_uri}
                  >
                    <Displays.BoxPackage theme_standard_1 dataCheckup={e} />
                  </div>
                ))}
            </Carousel>
          )}
        </div>
        <div className="group_package">
          <div className="title">แพ็คเกจทั้งหมด</div>
          <div className="list_package">
            {
              _dataDetail && _dataDetail.popup_image_uri
                ?
                <img
                  className="img_package"
                  src={_dataDetail.popup_image_uri}
                  alt="pack_age"
                />
                :
                _dataDetail &&
                _dataDetail.packages &&
                _dataDetail.packages.map((e, i) => (
                  <div
                    key={i}
                    className="body_package"
                    onClick={() => handleClickServiceOrder(e.id)}
                  >
                    <Displays.BoxProduct
                      theme_standard_pink
                      src={e.image_uri}
                      title={e.name}
                      percent={e.discount_message}
                      price1={`฿ ${RenderCommaMoney(Number(e.full_price))}`}
                      price2={`฿ ${RenderCommaMoney(Number(e.final_price))}`}
                      btnTheme={{ theme_pink_2: true }}
                    />
                  </div>
                ))}
          </div>
          <div className="btn2">
            <Buttons.ButtonIcon
              theme_gray
              lable1Size="16px"
              label1="กลับสู่หน้าแรก"
              handleOnClick={handleClickBackFirstPage}
            />
          </div>
        </div>
      </div>
    </PackageList2Styled>
  );
};

export default PackageList2;
