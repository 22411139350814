import React from 'react';
import { ImgAndButtonFormStyled } from './styled';
import theme from 'styles/theme.json';
import { Displays, Buttons } from 'components';

class ImgAndButtonForm extends React.Component {
  state = { loading: true };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
  };

  handleClickButton = (id) => {
    const { handleSubmitAns } = this.props;
    handleSubmitAns([{ id: id }]);
  };

  render() {
    const { question, loading, scheme } = this.props;
    return (
      <ImgAndButtonFormStyled>
        {question && question.answers && (
          <>
            {loading ? (
              <Displays.LogoLoadingFade />
            ) : (
              <img
                className="img_logo"
                src={'/assets/images/icons/logo_question.png'}
                alt="logo well"
              />
            )}
          </>
        )}
        <div className="title">{question && question.label}</div>
        {question && question.description && (
          <div className="title2">{question.description}</div>
        )}
        <img
          className="img_question"
          src={process.env.REACT_APP_API_ENDPOINT + question.image_uri}
          alt="logo well"
        />
        <div className="button_layout">
          {question &&
            question.answers &&
            question.answers.map((e, i) => (
              <div key={i}>
                <Buttons.Button
                  width="200px"
                  height="45px"
                  theme_standard
                  style={{ marginTop: 20 }}
                  handleClickButton={() => this.handleClickButton(e.id)}
                  backgroundColor={
                    e.is_unknown ? '#ffffff' : scheme ? theme.COLORS.PINK_1 : ''
                  }
                  color={e.is_unknown ? '#000000' : ''}
                  weight={e.is_unknown ? '' : '300'}
                >
                  {e.label}
                </Buttons.Button>
              </div>
            ))}
        </div>
      </ImgAndButtonFormStyled>
    );
  }
}

export default ImgAndButtonForm;
