import React from 'react';
import { OptionBoxStyled } from './styled';
import cx from 'classnames';

const OptionBox = ({
  theme_standard_opt,
  theme_herwill,
  data,
  input,
  onChangeCustom,
}) => {
  const customClass = cx({
    theme_standard_opt: theme_standard_opt,
    theme_herwill: theme_herwill,
  });
  return (
    <OptionBoxStyled>
      <div className={customClass}>
        {data.map((e, i) => (
          <div
            key={i + 1}
            className="opt_layout"
            onClick={() => {
              input.onChange(e);
              onChangeCustom();
            }}
          >
            {e.label}
          </div>
        ))}
      </div>
    </OptionBoxStyled>
  );
};

export default OptionBox;
