import { Banner3WidgetStyled } from './styled';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Buttons } from 'components';

const Banner3Widget = () => {
  const navigate = useNavigate();
  const { configProjectRedux } = useSelector((state) => state);

  const _handleClickConsultDocotr = () => {
    ReactGA.event({
      category: configProjectRedux.scheme + '_fromLanding',
      action: 'click_goIteleList',
    });
    navigate(ROUTE_PATH.CONSULT_DOCTOR);
  };

  return (
    <Banner3WidgetStyled>
      <div className="banner3_container">
        <img
          className="b3_logo_wrap"
          alt="itele logo"
          src="/assets/images/icons/itele-logo.png"
        />
        <div className="b3_sub_title">
          Telemedicine <span className="s_black"> ปรึกษาแพทย์ออนไลน์</span>
        </div>
        <div className="b3_title">
          กับ
          <span className="t_pink"> "โรงพยาบาลชั้นนำ"</span>
        </div>
        <div className="b3_btn_block">
          <div className="icon_click_wrap">
            <img
              className="click_icon"
              alt="icon click"
              src="/assets/images/icons/click.png"
            />
          </div>
          <Buttons.WellBtn
            label={'รับคำปรึกษา'}
            prefixIcon="/assets/images/icons/consult-doc.png"
            onClick={_handleClickConsultDocotr}
            theme_branner_3
          />
        </div>
        <div className="b3_hastag">
          #มะเร็งเต้านม รู้ไว โอกาสรักษาหายสูงกว่า
        </div>
        <div className='label_mth_3'>
          M-TH-00002356
        </div>
      </div>
    </Banner3WidgetStyled>
  );
};

export default Banner3Widget;
