import React from 'react';
import { InsuranceItemStyled } from './styled';
import cx from 'classnames';

const InsuranceItem = ({ theme_standard, imgUri, redirectUril }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const _handleClickLink = (e) => {
    window.open(e, '_blank');
  };

  return (
    <InsuranceItemStyled>
      <div className={customClass}>
        <div className="insurance_item_container">
          <img
            className="insurance_img"
            src={imgUri}
            alt="insurance"
            onClick={() => _handleClickLink(redirectUril)}
          />
          <div
            className="btn_call"
            onClick={() => _handleClickLink(redirectUril)}
          >
            <div className="btn_txt">ดูรายละเอียด</div>
          </div>
        </div>
      </div>
    </InsuranceItemStyled>
  );
};

export default InsuranceItem;
