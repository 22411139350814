import styled from 'styled-components';

export const TestShortcutWidgetStyled = styled.div`
  .box_notic {
    background-image: url(/assets/images/backgrounds/box_notic.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    bottom: ${({ isBottom }) => (isBottom ? '220px' : '41px')};
    transition-duration: 4s;
    transition-delay: 0.2s;
    right: -1px;
    z-index: 99;
    cursor: pointer;
    font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
    font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    height: 135px;
    text-align: center;
    padding-top: 70px;
    min-width: 170px;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .box_notic {
      bottom: ${({ isBottom }) => (isBottom ? '100px' : '0px')};
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
      background-size: contain;
      min-width: 110px;
      padding-top: 68px;
    }
    .label_number {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
    }
  }
`;
