import styled from 'styled-components';

export const TreatmentItemWidgetStyled = styled.div`
  padding: 0 16px;
  .tmi_title {
    margin-bottom: 42px;
    text-align: center;
    font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
    font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.PINK_8};
  }
  .tmi_icon_wrap {
    margin-bottom: 42px;
    text-align: center;
    height: 74px;
  }
  .tmi_content {
    .tmi_item {
      margin-bottom: 9px;
      display: flex;
      column-gap: 8px;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      &.mb_0 {
        margin-bottom: 0px;
      }
      .tmi_dot {
        flex-shrink: 0;
        border-radius: 8px;
        margin-top: 10px;
        width: 8px;
        height: 8px;
        background: ${({ theme }) => theme.COLORS.PINK_8};
      }
    }
  }

  @media (max-width: 1600px) {
    .tmi_content {
      .tmi_item {
        min-height: 90px;
      }
    }
  }

  @media (max-width: 1230px) {
    .tmi_content {
      .tmi_item {
        min-height: 120px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .tmi_content {
      .tmi_item {
        min-height: 90px;
      }
    }
  }

  @media (max-width: 625px) {
    .tmi_content {
      .tmi_item {
        min-height: 120px;
      }
    }
  }


  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    padding: 0 6px;
    .tmi_title {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_15};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      margin-bottom: 8px;
    }
    .tmi_icon_wrap {
      margin-bottom: 8px;
      height: 36px;
    }
    .tmi_content {
      .tmi_item {
        min-height: 60px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        .tmi_dot {
          width: 3px;
          height: 3px;
        }
      }
    }
  }
`;
