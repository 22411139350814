import React from 'react';
import { LogoLoadingFade2Styled } from './styled';

const LogoLoadingFade2 = () => {
  return (
    <LogoLoadingFade2Styled>
      <img
        className="logo_line_out"
        src={'/assets/images/icons/well_logo_circle_outer_black.png'}
        alt=""
      />
      <img
        className="logo_line_in"
        src={'/assets/images/icons/well_logo_circle_inner_black.png'}
        alt=""
      />
      <img
        className="log_char"
        src={'/assets/images/icons/well_square_transparent_black.svg'}
        alt=""
      />
    </LogoLoadingFade2Styled>
  );
};

export default LogoLoadingFade2;
