import React from 'react';

export default function AaPlatform({
  color = '#4E5B7E',
  width = "88",
  height = "79.613",
  label = ""
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 88 79.613">
      <g id="Group_91928" data-name="Group 91928" transform="translate(-624 -1328.001)">
        <path id="Path_108521" data-name="Path 108521" d="M117.578,39.807a39.806,39.806,0,1,0-52.4,37.759A23.077,23.077,0,0,1,77.772,88l0,0A23.47,23.47,0,0,1,90.561,77.5a39.808,39.808,0,0,0,27.017-37.694" transform="translate(712.001 1290.035) rotate(90)" fill="#ed799e" />
        <text id="_1" data-name="1" transform="translate(666 1379.809)" fill="#fff" font-size="36" font-family="Kanit-Medium, Kanit" font-weight="500"><tspan x="0" y="0">{label}</tspan></text>
      </g>
    </svg>
  );
}
