import React from 'react';
import { InputTextStyled } from './styled';
import cx from 'classnames';

const InputText = (
  {
    theme_standard,
    theme_standard_2,
    theme_well,
    theme_well_login,
    theme_normal,
    theme_herwell,
    label,
    width,
    placeholder,
    input,
    input_width,
    disabled,
    meta: { touched, error },
    type,
    value_reset,
  },
  props
) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_standard_2: theme_standard_2,
    theme_normal: theme_normal,
    theme_well: theme_well,
    theme_herwell: theme_herwell,
    theme_well_login: theme_well_login,
  });
  return (
    <InputTextStyled
      isInvalid={touched && error}
      width={width}
      input_width={input_width}
    >
      <div className={customClass}>
        {label && <div className="label">{label}</div>}
        <input
          {...props}
          {...input}
          type={type}
          value={value_reset ? value_reset : input.value}
          placeholder={placeholder}
          disabled={disabled}
        />
        {touched && error && <div className="error">{error}</div>}
      </div>
    </InputTextStyled>
  );
};

export default InputText;
