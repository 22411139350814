import React from 'react';
import { LogoLoadingFadeStyled } from './styled';

const LogoLoadingFade = () => {
  return (
    <LogoLoadingFadeStyled>
      <img
        className="logo_line_out"
        src="/assets/images/icons/well_logo_circle_outer.png"
        alt=""
      />
      <img
        className="logo_line_in"
        src="/assets/images/icons/well_logo_circle_inner.png"
        alt=""
      />
      <img
        className="log_char"
        src="/assets/images/icons/well_logo_square_transparent.png"
        alt=""
      />
    </LogoLoadingFadeStyled>
  );
};

export default LogoLoadingFade;
