import styled from 'styled-components';

export const WellBtnStyled = styled.div`
  .well_btn_container {
    cursor: pointer;
    display: flex;
    align-items: center;
    .well_btn_icon_wrap {
      border-radius: 120px;
      padding: 15px;
      background: ${({ theme }) => theme.COLORS.PURPLE_1};
      z-index: 2;
      .btn_cirle_bg {
        border-radius: 90px;
        width: 90px;
        height: 90px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        .well_img {
          width: 90px;
          height: 90px;
        }
      }
    }
    .well_btn_label {
      flex-shrink: 0;
      margin-left: -34px;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      padding: 13px 38px;
      background: ${({ theme }) => theme.COLORS.PURPLE_1};
      display: flex;
      align-items: center;
      column-gap: 18px;
      .w_icon {
        margin-top: -2px;
        width: 33px;
        height: auto;
      }
      .w_label {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_36};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MEDIUM_DESKTOP}) {
    .well_btn_container {
      .well_btn_icon_wrap {
        .btn_cirle_bg {
          width: 88px;
          height: 88px;
          .well_img {
            width: 88px;
            height: 88px;
          }
        }
      }
      .well_btn_label {
        .w_label {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_34};
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .well_btn_container {
      .well_btn_icon_wrap {
        .btn_cirle_bg {
          width: 68px;
          height: 68px;
          .well_img {
            width: 68px;
            height: 68px;
          }
        }
      }
      .well_btn_label {
        .w_label {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .well_btn_container {
      .well_btn_icon_wrap {
        padding: 6px;
        .btn_cirle_bg {
          width: 38px;
          height: 38px;
          .well_img {
            width: 38px;
            height: 38px;
          }
        }
      }
      .well_btn_label {
        padding: 6px 20px;
        margin-left: -12px;
        column-gap: 12px;
        .w_icon {
          width: 23px;
        }
        .w_label {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_17};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        }
      }
    }

    .theme_branner_3 {
      .well_btn_container {
        .well_btn_label {
          width: 177px;
        }
      }
    }
  }
`;
