import React from 'react';
import { HopitalItemStyled } from './styled';
import { Icons } from 'components';

const HopitalItem = ({
  name,
  province,
  amphoe,
  distance,
  image_uri,
  phone_no,
}) => {
  return (
    <HopitalItemStyled>
      <div className="row_1">
        <div
          className="left_col"
          style={{
            backgroundImage: `url(${image_uri})`,
          }}
        ></div>
        <div className="right_col">
          <div className="txt name">{name}</div>
          <div className="txt gray mb_20">
            <div>{province}</div>
            <div>{amphoe}</div>
          </div>
        </div>
      </div>
      <div className="row_2">
        <div className="left_col">
          <div className="rang_row">
            <Icons.Location color="#F395BE" />
            <div className="txt">{distance} กม.</div>
          </div>
        </div>
        <div className="right_col">
          <a href={`tel:${phone_no}`}>
            <div className="btn_call">
              <Icons.Call />
              <div className="btn_txt">โทร</div>
            </div>
          </a>
        </div>
      </div>
    </HopitalItemStyled>
  );
};

export default HopitalItem;
