import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { PrefixIconBtnStyled } from './styled';

const PrefixIconBtn = ({
  theme_standard_view,
  theme_standard_view_big,
  theme_small,
  theme_standard_view_regular,
  icon,
  label,
  onClick,
}) => {
  const customClass = cx({
    theme_standard_view: theme_standard_view,
    theme_standard_view_big: theme_standard_view_big,
    theme_small: theme_small,
    theme_standard_view_regular: theme_standard_view_regular,
  });
  return (
    <PrefixIconBtnStyled>
      <div className={customClass}>
        <div className="prefix_icon_conatianer" onClick={onClick}>
          {icon}
          <div className="pi_label">{label}</div>
        </div>
      </div>
    </PrefixIconBtnStyled>
  );
};

PrefixIconBtn.propTypes = {};

export default PrefixIconBtn;
