import React from 'react';

export default function AaPlatform({ width = '56', height = '56' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_30980"
            data-name="Rectangle 30980"
            width={width}
      height={height}
            transform="translate(0 -0.004)"
            fill="#5e0943"
          />
        </clipPath>
      </defs>
      <g
        id="Group_94885"
        data-name="Group 94885"
        transform="translate(0 0.004)"
      >
        <g id="Group_89241" data-name="Group 89241">
          <g
            id="Group_89240"
            data-name="Group 89240"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_107162"
              data-name="Path 107162"
              d="M154.171,142.615l-2.357,11.055a1.32,1.32,0,0,1-2.456.3l-1.375-2.589-3.831,3.884a1.353,1.353,0,0,1-1.866,0l-2.357-2.39a1.4,1.4,0,0,1,0-1.892l3.929-3.685L141.4,145.9a1.343,1.343,0,0,1-.688-1.394,1.27,1.27,0,0,1,.983-1.1l10.9-2.291a1.343,1.343,0,0,1,1.571,1.494"
              transform="translate(-139.428 -131.981)"
              fill="#5e0943"
            />
          </g>
        </g>
        <g id="Group_89243" data-name="Group 89243">
          <g
            id="Group_89242"
            data-name="Group 89242"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_107163"
              data-name="Path 107163"
              d="M145.584,5.195a.991.991,0,0,1-.982-1V1.211a.982.982,0,1,1,1.964,0V4.2a.991.991,0,0,1-.982,1"
              transform="translate(-132.118 -0.236)"
              fill="#5e0943"
            />
            <path
              id="Path_107164"
              data-name="Path 107164"
              d="M41.972,43l2.161-2.091a.913.913,0,0,1,1.473,0,.972.972,0,0,1,0,1.394L43.543,44.4a1.058,1.058,0,0,1-1.474,0,.9.9,0,0,1-.1-1.394"
              transform="translate(-24.872 -37.944)"
              fill="#5e0943"
            />
            <path
              id="Path_107165"
              data-name="Path 107165"
              d="M5.126,144.058a.991.991,0,0,1-.982,1H1.2a.992.992,0,0,1-.982-1,1.064,1.064,0,0,1,.982-1H4.144a.992.992,0,0,1,.982,1"
              transform="translate(18.555 -133.822)"
              fill="#5e0943"
            />
            <path
              id="Path_107166"
              data-name="Path 107166"
              d="M44.992,212.483l2.063,2.091a1.094,1.094,0,0,1,0,1.494,1.058,1.058,0,0,1-1.473,0l-2.063-2.092a1.094,1.094,0,0,1,0-1.494,1.056,1.056,0,0,1,1.473,0"
              transform="translate(-26.419 -198.462)"
              fill="#5e0943"
            />
            <path
              id="Path_107167"
              data-name="Path 107167"
              d="M216.078,44.137l-2.063-2.191a1.094,1.094,0,0,1,0-1.494,1.057,1.057,0,0,1,1.473,0l2.063,2.091a1.094,1.094,0,0,1,0,1.494.98.98,0,0,1-1.473.1"
              transform="translate(-207.818 -37.585)"
              fill="#5e0943"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
