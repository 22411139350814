import React from 'react';

export default function AaPlatform({
  width = "40",
  height = "40",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 40 40"
    >
      <defs>
        <filter
          id="Path_107694"
          x="7.846"
          y="14.248"
          width="28.597"
          height="20.995"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur" />
          <feFlood flood-opacity="0.051" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Path_107679"
          x="13.869"
          y="7"
          width="16.552"
          height="20.385"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur-2" />
          <feFlood flood-opacity="0.051" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_90190"
        data-name="Group 90190"
        transform="translate(-2059 -5048)"
      >
        <path
          id="Path_107680"
          data-name="Path 107680"
          d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z"
          transform="translate(2059 5048)"
          fill="#ed799e"
        />
        <path
          id="Path_107695"
          data-name="Path 107695"
          d="M-.558-.151,3.295.217,3.4,3.978-.623,3.073Z"
          transform="translate(2077.375 5064.82) rotate(45)"
          fill="#ed799e"
        />
        <path
          id="Path_107696"
          data-name="Path 107696"
          d="M.126,4.2l3.627-.286L4.157,0l-4.3.882Z"
          transform="translate(2084.151 5067.1) rotate(135)"
          fill="#ed799e"
        />
        <g id="Group_94885" data-name="Group 94885">
          <g
            transform="matrix(1, 0, 0, 1, 2059, 5048)"
            filter="url(#Path_107694)"
          >
            <path
              id="Path_107694-2"
              data-name="Path 107694"
              d="M19.6,11.995H11.153l6.6-2.512a.429.429,0,0,0,.289-.394V1.616H19.6V11.995ZM0,1.615H1.554V9.089a.429.429,0,0,0,.289.394l6.6,2.512H0V1.615ZM2.5,0V8.8l6.832,2.6V2.6Zm7.774,2.6v8.8L17.1,8.8V0Z"
              transform="translate(10.35 16.75)"
              fill="#fff"
              fill-rule="evenodd"
            />
          </g>
          <g
            transform="matrix(1, 0, 0, 1, 2059, 5048)"
            filter="url(#Path_107679)"
          >
            <path
              id="Path_107679-2"
              data-name="Path 107679"
              d="M6.289,8.467A40.5,40.5,0,0,1,2,3.218a4.832,4.832,0,0,1-.808-1.9,4.8,4.8,0,0,0-.6,2.208c-.008.29.224.961,1.375,2.506.8,1.076,1.8,2.249,2.531,3.106.31.365.571.669.757.9ZM2.926,7.8c-.4.484-.79.941-1.133,1.343-.31.365-.571.669-.757.9L0,8.467C.714,7.685,1.033,7.294,1.6,6.61l.2-.241c.341.455.726.935,1.131,1.429m.218-5.984a2.394,2.394,0,0,0-1.316.4,1.934,1.934,0,0,1-.235-1.5,2.038,2.038,0,0,1,3.1,0c.086.231.055.657-.331,1.435a2.361,2.361,0,0,0-1.22-.337m.149,2.645A14.213,14.213,0,0,0,4.765,2.092a3.5,3.5,0,0,0,.282-.853A4.793,4.793,0,0,1,5.7,3.531c.008.279-.206.912-1.255,2.343Q3.788,5.1,3.292,4.459"
              transform="translate(17 10)"
              fill="#fff"
              stroke="rgba(0,0,0,0)"
              stroke-miterlimit="10"
              stroke-width="1"
              fill-rule="evenodd"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
