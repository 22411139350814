export const ROUTE_PATH = {
  ROOT: '/',
  LOGIN: '/login',
  AUTO_SIGNUP: '/signup',
  RISK_FACTOR: '/riskfactor',
  HOME_PATH: '/system',
  TERM_OF_USE: '/system/termofuse',
  REGISTER: '/system/register',
  QUANLIFICATION_EXAMINATION: '/system/quanlificationexamination',
  QUESTIONNAIRE: '/system/questionnaire',
  SHARE: '/system/share',
  CONSULT_DOCTOR: '/system/consult-doctor',
  HEALTH_INSURANCER: '/system/health-insurancer',
  PINK_HOSPITAL_CHOOSE: '/system/hospital-choose',
  PINK_INSURANCE_PACKAGE: '/system/service-package',
  LOCATION_NEAR: '/system/locationnear',
  VIDEOS: '/videos',
  VIDEOS_DETAIL: '/videos/:id',
  // VIDEOS_CATEGORY: '/videos/categories',
  // VIDEOS_CATEGORY_LIST: '/videos/categories/:id',

  // SIGNUP: '/pinkalert/signup',
  // PINK_PROFILE_SCREENING: '/pinkalert/profile',
};
