import React from 'react';

export default function AaPlatform({
  color = '#9a9a9a',
  width = 27.199,
  height = 48,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28.199 48"
    >
      <path
        id="Path_110386"
        data-name="Path 110386"
        d="M27.637,22.127,6.276.767a2.632,2.632,0,0,0-3.714,0L.99,2.339a2.63,2.63,0,0,0,0,3.714L18.927,23.99.97,41.947a2.633,2.633,0,0,0,0,3.714l1.573,1.572a2.632,2.632,0,0,0,3.713,0l21.38-21.38a2.65,2.65,0,0,0,0-3.726"
        transform="translate(-0.203)"
        fill={color}
      />
    </svg>
  );
}
