import styled from 'styled-components';

export const ModalStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .theme_modal_standard {
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
    background: ${({ bgColor }) => (bgColor ? bgColor : 'rgba(0, 0, 0, 0.5)')};
    display: ${({ _isShow }) => (_isShow ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .modal_body {
      position: absolute;
      z-index: 91;
    }
  }

  .theme_modal_showpic {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 25%;
    z-index: 90;
    background: rgba(0, 0, 0, 0);
    display: ${({ _isShow }) => (_isShow ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .modal_body {
      position: absolute;
      z-index: 91;
    }
  }

  .theme_modal_light {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 90;
    background: #ffffffc4;
    display: ${({ _isShow }) => (_isShow ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .modal_body {
      position: absolute;
      z-index: 91;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .theme_top_layout {
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: ${({ bgColor }) => (bgColor ? bgColor : 'rgba(0, 0, 0, 0.5)')};
    display: ${({ _isShow }) => (_isShow ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    .backdrop {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .modal_body {
      position: absolute;
      z-index: 9999;
    }
  }
`;
