import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { HerWillContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { userService } from 'apiService';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { RenderCommaNumber } from 'utils/functions/FormatNumber';
import AnimatedNumber from 'animated-number-react';
// import { ShareGroup } from 'widgets';
import { Cards, Modals } from 'components';
import { setReduxHospitalList } from 'store/actions';
import { BannerPopup } from 'widgets'

class HerWillContainer extends React.Component {
  state = {
    screenCount: 0,
  };

  componentDidMount() {
    this.fetchData();
    this.fetchDataCheckUp();
  }

  fetchData = async () => {
    let res = await userService.GET_HERWILL_SCREEN_COUNT();
    if (res && res.success) {
      this.setState({
        screenCount: res.data,
      });
    }
  };

  fetchDataCheckUp = async () => {
    let res = await userService.GET_SERVICE_CATEGORIES_HER_WELL_SERVICE_LIST();
    
    if (res && res.success) {
      this.setState({
        dataCheckup: res.data,
      });
      this.props.setReduxHospitalList(res.data);
    }
  };

  handleClickMemmogram = () => {
    const { configProjectRedux } = this.props;
    ReactGA.event({
      category: configProjectRedux.scheme + '_exit',
      action: 'click_goMammogram',
    });
    this.props.router.navigate(ROUTE_PATH.LOCATION_NEAR);
  };

  handleClickHealth = () => {
    const { configProjectRedux } = this.props;

    ReactGA.event({
      category: configProjectRedux.scheme + '_exit',
      action: 'click_healthInsurancer',
    });
    this.props.router.navigate(ROUTE_PATH.HEALTH_INSURANCER);
  };

  handleClickToBookAppointment = () => {
    const { configProjectRedux } = this.props;
    ReactGA.event({
      category: configProjectRedux.scheme + '_exit',
      action: 'click_goCancerHospital',
    });
    this.props.router.navigate(ROUTE_PATH.PINK_HOSPITAL_CHOOSE);
  };

  handleClickArticle = () => {
    const { configProjectRedux } = this.props;
    ReactGA.event({
      category: configProjectRedux.scheme + '_exit',
      action: 'click_goArticle',
    });
    window.open(
      'https://www.thaibreast.org/TH/breastcancerknowledge.html',
      '_blank'
    );
  };

  handleClickConsult = () => {
    const { configProjectRedux } = this.props;
    ReactGA.event({
      category: configProjectRedux.scheme + '_exit',
      action: 'click_goIteleList',
    });
    this.props.router.navigate(ROUTE_PATH.CONSULT_DOCTOR);
  };

  handleClickServiceOrder = async (e) => {
    if (e.popup_image_uri) {
      this.renderModal(e.popup_image_uri)
    } else {
      ReactGA.event({
        category: 'herwill_cancer_hospital',
        action: 'click_chooseHospital_' + e.id,
      });
      this.props.router.navigate(
        `${ROUTE_PATH.PINK_INSURANCE_PACKAGE}?id=${e.id}`
      );
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };

  renderModal = (e) => {
    this.setState({
      isModal: true,
      renderModal: (
        <BannerPopup imgSrc={e} handleClickCloseModal={this.handleClickCloseModal} />
      ),
    });
  };


  render() {
    const { loading, screenCount, dataCheckup, renderModal, isModal } = this.state;
    const { authRedux, handleClickHome } = this.props;
    return (
      <HerWillContainerStyled
        src={loading ? '' : '/assets/images/icons/share_bg.svg'}
      >
        <div className="logo_wrap">
          <img
            className="icon_logo"
            src={'/assets/images/icons/logo_HerWill.svg'}
            alt="logo well"
          />
        </div>
        <div className="share_detail">
          <div className="box_layout">
            <div className="txt_center">
              <div
                className="txt_normal"
                style={{ fontWeight: 'bold', fontSize: '22px' }}
              >
                ขอบคุณ{' '}
              </div>
              <div className="txt_pink">
                คุณ{authRedux && authRedux.firstname}
              </div>
            </div>
            <div className="txt_center row">
              <div className="txt_normal">คุณได้เข้าร่วมเป็น 1 ใน</div>
              <div className="txt_pink fs_36">
                <AnimatedNumber
                  value={screenCount}
                  formatValue={(count) => RenderCommaNumber(parseInt(count))}
                  duration={1000}
                />
              </div>
            </div>
            <div className="container_hop_app">
              <div className="title_page">จองตรวจคัดกรองมะเร็งเต้านม</div>
              <div className="sub_title_page">
                โรงพยาบาลที่เข้าร่วมโครงการในราคาพิเศษ
              </div>
              <Cards.HospitalList
                theme_standard
                handleClickBackFirstPage={handleClickHome}
                dataCheckup={dataCheckup}
                handleClickServiceOrder={this.handleClickServiceOrder}
              />
            </div>
            {/* <div className="txt_center">
              <div className="txt_normal fs_14 bold">
                เลือกวางแผนและดูแลสุขภาพของคุณ
              </div>
            </div>
            <div className="group_card">
              <div className="box_card">
                <div className="card_wrap">
                  <img
                    className="img_brand"
                    src={'/assets/images/icons/brand.png'}
                    alt="img_icon"
                  />
                  <div className="group_text">
                    <div className="text1">มะเร็งเต้านม</div>
                    <div className="text2">รู้ไว เริ่มเร็ว หายทัน</div>
                  </div>
                </div>
                <div className="label_show">
                  พบคุณหมอเพื่อตรวจมะเร็งเต้านมที่โรงพยาบาล
                </div>
                <button
                  className="btn_pink_rent"
                  onClick={this.handleClickToBookAppointment}
                >
                  <div className="txt_btn">จองคิวตรวจมะเร็ง</div>
                </button>
              </div>
              <div className="box_card">
                <div className="card_wrap">
                  <img
                    className="img_brand"
                    src={'/assets/images/icons/brand_2.png'}
                    alt="img_icon"
                  />
                  <div className="group_text_2">
                    <img
                      className="img_itele"
                      src={'/assets/images/icons/itele_2.svg'}
                      alt="icon show"
                    />
                    <div className="text1">ปรึกษาแพทย์ได้ทุกที่ทุกเวลา</div>
                  </div>
                </div>
                <div className="label_show">
                  ปรึกษาคุณหมอหากท่านมีความกังวลเรื่องอาการทางร่างกาย
                </div>
                <button
                  className="btn_pink_rent"
                  onClick={this.handleClickConsult}
                >
                  <div className="icon_btn">
                    <Icons.Camera />
                  </div>
                  <div className="txt_btn">ปรึกษาแพทย์ผู้เชี่ยวชาญ</div>
                </button>
              </div>
            </div>
            <div className="line" />
            <div className="bot_layout">
              <div className="left_bot">
                <div className="txt_center mb_12">
                  <div className="txt_normal fs_14 bold">บริการอื่นๆ</div>
                </div>
                <div className="row_2_col">
                  <div className="btn_container">
                    <img
                      className="img_icon"
                      src={'/assets/images/icons/share1.svg'}
                      alt="service etc"
                      onClick={() => this.handleClickMemmogram()}
                    />
                    <button
                      className="btn_pink_sub"
                      onClick={() => this.handleClickMemmogram()}
                    >
                      ข้อมูลศูนย์ตรวจ
                    </button>
                    <div className="btn_txt_detail">
                      ศูนย์ตรวจแมมโมแกรม
                      <br />
                      ใกล้คุณ
                    </div>
                  </div>
                  <div className="btn_container">
                    <img
                      className="img_icon"
                      src={'/assets/images/icons/share2.svg'}
                      alt="service etc"
                      onClick={() => this.handleClickArticle()}
                    />
                    <button
                      className="btn_pink_sub"
                      onClick={() => this.handleClickArticle()}
                    >
                      อ่านบทความ
                    </button>
                    <div className="btn_txt_detail">
                      เข้าใจและเพิ่มความรู้
                      <br />
                      โรคมะเร็ง
                    </div>
                  </div>
                </div>
                <div className="row_2_col mt">
                  <div className="btn_container">
                    <img
                      className="img_icon"
                      src={'/assets/images/icons/asure2.svg'}
                      alt="service etc"
                      onClick={() => this.handleClickHealth()}
                    />
                    <button
                      className="btn_pink_sub"
                      onClick={() => this.handleClickHealth()}
                    >
                      ประกันสุขภาพ
                    </button>
                    <div className="btn_txt_detail">
                      วางแผนการเงิน
                      <br />
                      เพื่อสุขภาพของคุณ
                    </div>
                  </div>
                </div>
              </div>
              <div className="right_bot">
                <div className="txt_center">
                  <div className="txt_navy">
                    แชร์ลิงค์ ชวนเพื่อนมาเช็คมะเร็งเต้านม
                  </div>
                </div>
                <ShareGroup.ShareBtnHerWill source={'exit'} />
              </div>
            </div> */}
            {/* <button className="btn_end_page" onClick={handleClickHome}>
              <div className="txt_btn">กลับสู่หน้าหลัก</div>
            </button> */}
          </div>
        </div>
        <Modals.Modal
          theme_top_layout
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
          bgColor="rgba(0, 0, 0, 0.8)"
        >
          {renderModal}
        </Modals.Modal>
      </HerWillContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.authenRedux,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxHospitalList: (data) => dispatch(setReduxHospitalList(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HerWillContainer));
