import React from 'react';

export default function AaPlatform({
  width = "68.051",
  height = "68.006",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 68.051 68.006"
    >
      <defs>
        <filter
          id="Union_349"
          x="0"
          y="0"
          width="68.051"
          height="68.006"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur" />
          <feFlood flood-opacity="0.051" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Union_349)">
        <path
          id="Union_349-2"
          data-name="Union 349"
          d="M34.743,55.949a7,7,0,0,1,0-9.9l4.95-4.951,2.121,2.122-2.829,2.829A1,1,0,1,0,40.4,47.463l2.828-2.829L49.592,51l-4.948,4.951a7,7,0,0,1-9.9,0Zm9.9-12.731L46.763,41.1a1,1,0,0,0-1.415-1.414L43.226,41.8l-2.121-2.122,4.951-4.95a7,7,0,1,1,9.9,9.9l-4.95,4.951Zm4.243-7.071-.707.707a1,1,0,0,0,1.415,1.415l.707-.708a1,1,0,0,0-1.415-1.414Zm-35.518,9.8L8.415,41l6.366-6.366,2.828,2.829a1,1,0,1,0,1.415-1.414L16.2,33.217,18.317,31.1l4.95,4.952a7,7,0,1,1-9.9,9.9ZM2.051,34.635a7,7,0,1,1,9.9-9.9l4.951,4.95L14.78,31.8l-2.122-2.121A1,1,0,1,0,11.245,31.1l2.121,2.122L7,39.583Zm5.658-8.49a1,1,0,0,0,0,1.413l.707.708A1,1,0,0,0,9.83,26.852l-.707-.707a1,1,0,0,0-1.415,0ZM25.3,30.94,48.95,7.293A16.986,16.986,0,0,1,25.3,30.94Zm-2.322-1.923c-.239-.243-.45-.495-.671-.743L46.283,4.3c.25.22.5.427.743.671s.45.494.672.743L23.725,29.688C23.476,29.467,23.221,29.26,22.982,29.017Zm0-24.041a16.985,16.985,0,0,1,21.72-1.927L21.059,26.7A16.986,16.986,0,0,1,22.985,4.976Z"
          transform="translate(3.04 3.01)"
          fill="#9bdeac"
          stroke="rgba(0,0,0,0)"
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
    </svg>
  );
}
