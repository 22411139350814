import React from 'react';
import ReactGA from 'react-ga';
import { Field, reduxForm } from 'redux-form';
import { SendEmailFormStyled } from './styled';
import { VALIDATE } from 'utils/constants/validateForm';
import { Inputs } from 'components';

class SendEmailForm extends React.Component {
  state = {
    isFormDisabled: false,
    lbBtn: 'ยืนยัน E-mail',
  };

  _handleClickSendEmail = () => {
    this.setState({
      isFormDisabled: true,
    });
    this.props.handleClickSendEmail((res) => {
      if (res) {
        this.setState({
          lbBtn: 'ส่งสำเร็จ',
        });

        ReactGA.event({
          category: 'herwill_exit',
          action: 'send_email',
        });
      } else {
        this.setState({
          isFormDisabled: false,
          lbBtn: 'ส่งสำเร็จ',
        });
      }
    });
  };

  render() {
    const { isFormDisabled, lbBtn } = this.state;
    const { handleSubmit } = this.props;
    return (
      <SendEmailFormStyled>
        <form onSubmit={handleSubmit(this._handleClickSendEmail)}>
          <div className="md_title">กรอก E-mail ของคุณ</div>
          <div className="md_input_wrap">
            <Field
              theme_herwell
              name="email"
              placeHolder="you@example.com"
              component={Inputs.InputText}
              validate={VALIDATE.EMAIL}
              disabled={isFormDisabled}
            />
          </div>
          <button
            className={`btn_pink ${isFormDisabled ? 'disabled' : ''}`}
            type="submit"
            disabled={isFormDisabled}
          >
            {lbBtn}
          </button>
        </form>
      </SendEmailFormStyled>
    );
  }
}

export default reduxForm({
  form: 'SendEmailForm', // a unique identifier for this form
  enableReinitialize: true,
})(SendEmailForm);
