import styled from 'styled-components';

export const Banner4WidgetStyled = styled.div`
  position: relative;
  background-image: url(/assets/images/backgrounds/banner4-bg.png);
  background-color: #eaeaea;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.SCREENS.MEDIUM_DESKTOP}) {
    
  } 

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {

    
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    background-image: url(/assets/images/backgrounds/banner4-bg-mobile.png);
  }
`;
