import styled from 'styled-components';

export const TelemedicineWidgetStyled = styled.div`
  padding: 80px 64px 52px 64px;
  background: ${({ theme }) => theme.COLORS.PINK_9};
  .telemed_row {
    display: flex;
    justify-content: space-between;
    column-gap: 60px;
    .t_left_col {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img_show {
        border-radius: 8px;
        width: 100%;
        height: auto;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left;
        padding: 10% 22px 29px 40%;
        .img_itele {
          width: 34px;
          height: 12.87px;
          position: absolute;
          top: 16px;
          right: 9px;
        }
        .label_top_show {
          display: flex;
          flex-direction: column;
          .group_text {
            .text1 {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_34};
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
              color: ${({ theme }) => theme.COLORS.GREEN_3};
              &.fs_sub_title {
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_29};
              }
            }
            .text2 {
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_29};
              color: ${({ theme }) => theme.COLORS.BLACK_3};
            }
          }
        }
        .box_btn {
          margin-top: 43px;
          width: 100%;
          max-width: 347px;
          .btn_itele {
            border-radius: 22px;
            padding: 6px 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: ${({ theme }) => theme.COLORS.PURPLE_1};
            text-align: center;
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
            color: ${({ theme }) => theme.COLORS.WHITE_1};
            cursor: pointer;
            .icon_btn {
              margin-right: 10px;
            }
            .icon_btn_mobile {
              margin-right: 4px;
              display: none;
            }
            a {
              color: ${({ theme }) => theme.COLORS.WHITE_1};
            }
          }
        }
      }
      .service_box {
        margin-top: 65px;
        width: 100%;
        .service_title {
          margin-bottom: 30px;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_36};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        }
        .service_row {
          display: flex;
          justify-content: space-between;
          row-gap: 30px;
          .box_service_detail {
            .box_wrap {
              text-align: center;
              .box_image {
                margin-bottom: 24px;
                border-radius: 8px;
                padding: 18px;
                width: 169px;
                height: 169px;
                background: ${({ theme }) => theme.COLORS.WHITE_1};
                cursor: pointer;
              }
            }
            .box_label {
              display: flex;
              flex-direction: column;
              .button_show {
                margin: auto;
                margin-bottom: 20px;
                border-radius: 16px;
                padding: 5px 15px;
                width: fit-content;
                cursor: pointer;
                background: ${({ theme }) => theme.COLORS.PURPLE_1};
                font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
                text-align: center;
                color: ${({ theme }) => theme.COLORS.WHITE_1};
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
                a {
                  color: ${({ theme }) => theme.COLORS.WHITE_1};
                }
              }
              .text_row {
                .text_show {
                  text-align: center;
                  font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
                  font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
                }
              }
              .text_row_mobile {
                order: 1;
                display: none;
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
                font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
              }
            }
          }
        }
      }
    }
    .t_right_col {
      flex: 1;
      .social_block {
        margin-bottom: 60px;
        .sb_title {
          margin-bottom: 26px;
          text-align: center;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_36};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
        }
        .sb_row_2_col {
          display: flex;
          justify-content: center;
          .sb_item {
            width: 100%;
            .icon_wrap {
              margin-bottom: 24px;
              text-align: center;
            }
            .sb_content {
              .btn_social {
                border-radius: 16px;
                margin: auto;
                margin-bottom: 20px;
                padding: 5px 24px;
                width: 186px;
                background: ${({ theme }) => theme.COLORS.PURPLE_1};
                text-align: center;
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
                font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
                color: ${({ theme }) => theme.COLORS.WHITE_1};
                cursor: pointer;
                a {
                  color: ${({ theme }) => theme.COLORS.WHITE_1};
                }
              }
              .lb_social {
                text-align: center;
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
                font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
                color: ${({ theme }) => theme.COLORS.BLACK_1};
              }
            }
          }
        }
      }
      .record_travel_block {
        .group_label {
          .label_show {
            text-align: center;
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_32};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
            color: ${({ theme }) => theme.COLORS.BLACK_1};
            &.regular {
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
            }
            &.h1 {
              margin-bottom: 12px;
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_48};
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
              color: ${({ theme }) => theme.COLORS.BROWN_2};
            }
            &.h2 {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_120};
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
              color: ${({ theme }) => theme.COLORS.PINK_8};
            }
          }
        }
        .sub_detail {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_26};
          color: ${({ theme }) => theme.COLORS.BROWN_2};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
          .group_sub {
            .h1 {
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
              padding-right: 5px;
            }
          }
        }
      }
    }
  } 
  @media (max-width: 1920px) {
    .telemed_row {
      .t_left_col {
        .service_box {
          .service_title {
            text-align: left;
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MEDIUM_DESKTOP}) {
    padding: 60px 44px 52px 44px;
    .telemed_row {
      column-gap: 40px;
      .t_left_col {
        .img_show {
          padding: 10% 22px 29px 33%;
          background-position: center;
          .label_top_show {
            .group_text {
              .text1 {
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_26};
                &.fs_sub_title {
                  font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_21};
                }
              }
              .text2 {
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_21};
              }
            }
          }
          .box_btn {
            .btn_itele {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
            }
          }
        }
        .service_box {
          .service_title {
            text-align: left;
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_28};
          }
          .service_row {
            flex-wrap: wrap;
            .box_service_detail {
              flex-basis: 50%;
              .box_wrap {
                .box_image {
                  width: 149px;
                  height: 149px;
                }
              }
              .box_label {
                .button_show {
                  font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
                }
                .text_row {
                  .text_show {
                    font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
                  }
                }
              }
            }
          }
        }
      }
      .t_right_col {
        .social_block {
          .sb_title {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_28};
          }
          .sb_row_2_col {
            .sb_item {
              .sb_content {
                .btn_social {
                  font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
                }
                .lb_social {
                  font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
                }
              }
            }
          }
        }
        .record_travel_block {
          .group_label {
            .label_show {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
              &.h1 {
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_40};
              }
              &.h2 {
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_112};
              }
            }
          }
          .sub_detail {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    padding: 0px 0px 18px 0px;
    .telemed_row {
      flex-direction: column;
      .t_left_col {
        padding-bottom: 17px;
        .img_show {
          padding: 10% 22px 29px 40%;
        }
        .service_box {
          margin-top: 21px;
          .service_title {
            text-align: center;
          }
        }
      }
      .t_right_col {
        .social_block {
          margin-bottom: 24px;
          padding: 20px;
          background: ${({ theme }) => theme.COLORS.WHITE_1};
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .telemed_row {
      .t_left_col {
        .img_show {
          padding: 6% 22px 29px 40%;
          width: 100%;
          height: 163px;
          .label_top_show {
            .group_text {
              .text1 {
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
                &.fs_sub_title {
                  font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
                }
              }
              .text2 {
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
              }
            }
          }
          .box_btn {
            margin-top: 13px;
            max-width: 175px;
            .btn_itele {
              padding: 6px 12px;
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
              .icon_btn {
                display: none;
              }
              .icon_btn_mobile {
                display: block;
              }
            }
          }
        }
        .service_box {
          .service_title {
            margin-bottom: 16px;
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
          }
          .service_row {
            margin: auto;
            width: 100%;
            max-width: 283px;
            flex-direction: column;
            row-gap: 16px;
            .box_service_detail {
              display: flex;
              column-gap: 14px;
              .box_wrap {
                .box_image {
                  margin-bottom: 0px;
                  width: 90px;
                  height: 90px;
                }
              }
              .box_label {
                .button_show {
                  margin: unset;
                  margin-top: 4px;
                  margin-bottom: 0;
                  order: 2;
                  padding: 2px 13px;
                  font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
                }
                .text_row {
                  display: none;
                  .text_show {
                  }
                }
                .text_row_mobile {
                  display: block;
                  &.wrap {
                    display: flex;
                    flex-direction: column;
                  }
                  .text_show {
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }
      .t_right_col {
        .social_block {
          margin-bottom: 20px;
          .sb_title {
            margin-bottom: 20px;
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
          }
          .sb_row_2_col {
            margin: auto;
            max-width: 300px;
            width: 100%;
            flex-direction: column;
            row-gap: 20px;
            .sb_item {
              display: flex;
              column-gap: 14px;
              .icon_wrap {
                margin-bottom: 0px;
              }
              .sb_content {
                display: flex;
                flex-direction: column;
                .btn_social {
                  margin: unset;
                  margin-top: 4px;
                  margin-bottom: 0px;
                  padding: 2px 17px;
                  width: 116px;
                  order: 2;
                  font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
                }
                .lb_social {
                  order: 1;
                  text-align: left;
                  display: flex;
                  font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
                }
              }
            }
          }
        }
        .record_travel_block {
          .group_label {
            .label_show {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
              &.h1 {
                margin-bottom: 5px;
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
              }
              &.h2 {
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_48};
              }
            }
          }
          .sub_detail {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
          }
        }
      }
    }
  }
`;
