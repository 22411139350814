import React from 'react';

export default function AaPlatform({width="40", height="40"}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 40 40"
    >
      <defs>
        <filter
          id="Rectangle_31182"
          x="7.5"
          y="9.6"
          width="31.41"
          height="28.9"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="3" dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="2.5" result="blur" />
          <feFlood flood-opacity="0.09" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_90123"
        data-name="Group 90123"
        transform="translate(-936 -3350)"
      >
        <path
          id="Path_107606"
          data-name="Path 107606"
          d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z"
          transform="translate(936 3350)"
          fill="#ed799e"
        />
        <g id="Group_90122" data-name="Group 90122" transform="translate(2)">
          <g
            transform="matrix(1, 0, 0, 1, 934, 3350)"
            filter="url(#Rectangle_31182)"
          >
            <rect
              id="Rectangle_31182-2"
              data-name="Rectangle 31182"
              width="16.41"
              height="13.9"
              rx="0.5"
              transform="translate(12 14.1)"
              fill="#ed799e"
            />
          </g>
          <rect
            id="Rectangle_31183"
            data-name="Rectangle 31183"
            width="24"
            height="24"
            transform="translate(946 3354)"
            fill="none"
          />
          <g
            id="Group_90121"
            data-name="Group 90121"
            transform="translate(0 2.826)"
          >
            <path
              id="Union_348"
              data-name="Union 348"
              d="M.184,10.612a.628.628,0,0,1-.14-.676L1.223,6.982a.618.618,0,0,1,.108-.165L3.978,9.465a.609.609,0,0,1-.165.108L.86,10.751a.627.627,0,0,1-.676-.139ZM1.343,6.789,6.727,1.405h0L7.875.257,8.059.073l.01.01c.178-.126.374-.139.693.174l1.776,1.775c.331.367.311.54.181.7l0,0-.045.045c-.041.045-.09.09-.139.139L9.39,4.068h0L4.006,9.452Z"
              transform="translate(953.618 3356.76)"
              fill="#fff"
              stroke="rgba(0,0,0,0)"
              stroke-miterlimit="10"
              stroke-width="1"
            />
            <path
              id="Path_107604"
              data-name="Path 107604"
              d="M.628,148.9H15.779a.627.627,0,0,0,.628-.628v-11.34L12.531,140.8a1.872,1.872,0,0,1-.641.421L8.943,142.4a1.865,1.865,0,0,1-.7.134H3.181a.628.628,0,0,1,0-1.256H6.49a1.864,1.864,0,0,1,.006-1.327L7.675,137a1.846,1.846,0,0,1,.418-.634L9.457,135H.628a.627.627,0,0,0-.628.628v12.64a.627.627,0,0,0,.628.628m2.553-5.106H13.267a.628.628,0,0,1,0,1.256H3.181a.628.628,0,0,1,0-1.256"
              transform="translate(946 3226.278)"
              fill="#fff"
            />
          </g>
          <rect
            id="Rectangle_31185"
            data-name="Rectangle 31185"
            width="24"
            height="24"
            transform="translate(946 3354)"
            fill="none"
          />
        </g>
      </g>
    </svg>
  );
}
