import { KnownledItemStyled } from './styled';

const KnownledItem = ({ src, label }) => {
  return (
    <KnownledItemStyled>
      <div className="k_row">
        <div className="k_img_wrap">
          <img className="k_img" alt="herwill knownled" src={src} />
        </div>
        {label && (
          <div className="group_label">
            {label.map((e, i) => (
              <div key={i} className="k_label">
                {e}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="k_more">{`อ่านรายละเอียด >`}</div>
    </KnownledItemStyled>
  );
};

export default KnownledItem;
