export const RenderTag = (e) => {
  let tags = ''

  if (e && e.length > 0) {
    e.forEach((f) => {
      // tags.push(`#${f.tag}`)

      tags = tags + `#${f.tag} `
    })
  }
  return tags
};
