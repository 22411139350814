import { default as StandardNoSymptomForm } from './StandardNoSymptomForm';
import { default as StandardForm } from './StandardForm';
import { default as ImgAndButtonForm } from './ImgAndButtonForm';
import { default as SliderForm } from './SliderForm';
import { default as ColumnButtonAndTextForm } from './ColumnButtonAndTextForm';
import { default as TextAndButtonForm } from './TextAndButtonForm';
import { default as GridForm } from './GridForm';
import { default as ImgChoiceForm } from './ImgChoiceForm';
import { default as BodyQuardrantForm } from './BodyQuardrantForm';
import { default as MultipleButtonForm } from './MultipleButtonForm';
import { default as TextInputForm } from './TextInputForm';

const EXPORT = {
  StandardNoSymptomForm,
  StandardForm,
  ImgAndButtonForm,
  SliderForm,
  ColumnButtonAndTextForm,
  TextAndButtonForm,
  GridForm,
  ImgChoiceForm,
  BodyQuardrantForm,
  MultipleButtonForm,
  TextInputForm,
};

export default EXPORT;
