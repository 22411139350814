import styled from 'styled-components';

export const HerwillMainLayoutStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  height: 100%;
`;
