import styled from 'styled-components';

export const KnowledWidgetStyled = styled.div`
  min-height: 100vh;
  padding: 87px;
  background-image: url(/assets/images/backgrounds/knownled-bg.jpg);
  background-color: #cccccc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .group_title {
    .kl_title {
      text-align: center;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_48};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
    }
    .kl_hero_title {
      margin-bottom: 58px;
      text-align: center;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_65};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
      color: ${({ theme }) => theme.COLORS.PINK_8};
    }
  }
  .kl_content_row {
    display: flex;
    justify-content: center;
    column-gap: 25px;
    .ct_item_wrap {
      cursor: pointer;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MEDIUM_DESKTOP}) {
    .group_title {
      .kl_title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_44};
      }
      .kl_hero_title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_61};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    background-image: url(/assets/images/backgrounds/knownled-bg-moblie.png);
    .group_title {
      display: flex;
      align-items: baseline;
      column-gap: 10px;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 12px;
      .kl_title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_35};
      }
      .kl_hero_title {
        margin-bottom: 0px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_52};
      }
    }
    .kl_content_row {
      flex-direction: column;
      row-gap: 20px;
      .ct_item_wrap {
        width: 100%;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    background-size: cover;
    height: 511px;
    min-height: 511px;
    padding: 19px;
    .group_title {
      .kl_title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
      }
      .kl_hero_title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
      }
    }
    .kl_content_row {
      row-gap: 10px;
      .ct_item_wrap {
      }
    }
  }
`;
