import React from 'react';
import { LoadingStyled } from './styled';
import { Puff } from 'react-loader-spinner';

const Loading = () => {
  return (
    <LoadingStyled>
      <Puff color="#00BFFF" height={100} width={100} />
    </LoadingStyled>
  );
};

export default Loading;
