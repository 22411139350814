export const VALIDATE = {
  REQUIRE: (value) =>
    value || typeof value === 'number' ? undefined : 'Required',
  NUMBER: (value) =>
    value
      ? isNaN(Number(value))
        ? 'Must be a number'
        : undefined
      : 'Must be a number',
  NUMBER_ONLY: (value) =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined,
  EMAIL: (value) =>
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'email invalid'
      : undefined,
  MIN_LENGTH: (value) =>
    value
      ? value.length < 6
        ? `Must be 6 characters or more`
        : undefined
      : `Must be 6 characters or more`,
  MIN_PW_LENGTH: (value) =>
    value
      ? value.length < 8
        ? `Must be 8 characters or more`
        : undefined
      : `Must be 8 characters or more`,
  MIN_PW_4_LENGTH: (value) =>
    value
      ? value.length < 4
        ? `Must be 4 characters or more`
        : undefined
      : `Must be 4 characters or more`,
  PASSWORD_MATCH: (value, allValues) =>
    value !== allValues.password ? "Passwords don't match" : undefined,
};
