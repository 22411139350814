import { VideoRecWidgetStyled, ButtonArrow } from './styled';
import { Icons, Inputs } from 'components';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Cards } from 'components';
import { herwillService } from 'apiService';

const VideoRecWidget = () => {
  const [_options, _setOptions] = useState([]);
  const [_value, _setValue] = useState();
  const [_dataSuggest, _setDataSuggest] = useState();

  useEffect(() => {
    _fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _fetchData = async () => {
    let res = await herwillService.GET_HOME_CONTENT_LIST();
    if (res && res.success) {
      _setOptions(
        res.data.map((e) => ({ ...e, label: e.name_th, value: e.uuid }))
      );
      if (res.data && res.data.length > 0) {
        _setValue({
          ...res.data[0],
          label: res.data[0].name_th,
          value: res.data[0].uuid,
        });
      }
    }
    let res2 = await herwillService.GET_HOME_RECOMMEND_CONTENT();

    if (res2 && res2.success) {
      _setDataSuggest(res2.data);
    } else {
      _setDataSuggest(null);
    }
  };

  const _handleSelectCategroy = (e) => {
    _setValue(e);
  };

  return (
    <VideoRecWidgetStyled>
      <div className="vrw_header_row">
        <div className="vrw_icon_wrap">
          <Icons.CircleVideo />
        </div>
        <div className="vrw_icon_wrap_moblie">
          <Icons.CircleVideo width="16px" height="16px" />
        </div>
        <div className="vrw_title">
          <div>
            วิดีโอ{' '}
            <span className="sub_text">
              เพื่อการเรียนรู้มะเร็งเต้านม
              ผ่านแพทย์ผู้เชี่ยวชาญการรักษาและประสบการณ์ของผู้ป่วย
            </span>
          </div>
        </div>
      </div>
      <div className="preview_item">
        {_dataSuggest && (
          <Cards.PreviewVideoRec
            theme_suggest
            src={_dataSuggest.cover_uri}
            // hastagList={_dataSuggest.tags.map((f) => `#${f.tag} `)}
            topic={_dataSuggest.topic}
            viewCount={_dataSuggest.total_view_count}
            likeCount={_dataSuggest.like_count}
            shareCount={_dataSuggest.share_count}
            // postDate={moment(_dataSuggest.created_at)
            //   .tz('Asia/Bangkok')
            //   .format('DD MMM. YYYY')}
            desciption={_dataSuggest.short_body}
            uuid={_dataSuggest.uuid}
          />
        )}
      </div>
      <div className="box_filter">
        <Inputs.InputVideoSearch
          theme_pink
          label={'หมวดหมู่วีดีโอ'}
          options={_options}
          value={_value}
          onChange={_handleSelectCategroy}
        />
      </div>
      {_value && (
        <div className="video_block">
          <div className="vb_head_row">
            <div className="vb_title">{_value.name_th}</div>
          </div>
          <div className="carousel_container">
            <Carousel
              responsive={responsive}
              renderButtonGroupOutside
              arrows={false}
              customButtonGroup={
                <ButtonGroup count={_value ? _value.contents.length : 0} />
              }
              partialVisible={true}
            >
              {_value.contents &&
                _value.contents.map((f, j) => (
                  <div key={j} className="prview_item_wrap">
                    <Link to={`${ROUTE_PATH.VIDEOS}/${f.uuid}?sec=video`}>
                      <Cards.PreviewVideo
                        theme_home
                        src={f.cover_thumbnail_uri || f.cover_uri}
                        topic={f.topic}
                        viewCount={f.total_view_count}
                        likeCount={f.like_count}
                        shareCount={f.share_count}
                        isView={f.me.view_at}
                        isLike={f.me.like_at}
                      />
                    </Link>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      )}

      {_value && (
        <div className="video_block_mobile">
          <div className="vb_head_row">
            <div className="vb_title">{_value.name_th}</div>
          </div>
          <div className="carousel_container_mobile">
            {_value.contents &&
              _value.contents.map((f, j) => (
                <div key={j} className="prview_item_wrap">
                  <Link to={`${ROUTE_PATH.VIDEOS}/${f.uuid}?sec=video`}>
                    <Cards.PreviewVideo
                      src={f.cover_thumbnail_uri || f.cover_uri}
                      topic={f.topic}
                      viewCount={f.total_view_count}
                      likeCount={f.like_count}
                      shareCount={f.share_count}
                      isView={f.me.view_at}
                      isLike={f.me.like_at}
                    />
                  </Link>
                </div>
              ))}
          </div>
        </div>
      )}
    </VideoRecWidgetStyled>
  );
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  mediumTablet: {
    breakpoint: { max: 1536, min: 464 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 100, // this is needed to tell the amount of px that should be visible.
  },
};

const ButtonGroup = ({ next, previous, goToSlide, count, ...rest }) => {
  const {
    carouselState: { currentSlide, slidesToShow },
  } = rest;
  return (
    <div className="carousel_button_group">
      <CustomLeftArrow
        disbled={currentSlide === 0 ? 'disable' : ''}
        onClick={previous}
      />
      <CustomRightArrow
        disbled={currentSlide + slidesToShow >= count ? 'disable' : ''}
        onClick={next}
      />
    </div>
  );
};

const CustomLeftArrow = ({ onClick, disbled }) => {
  return (
    <ButtonArrow
      {...renderOffset().bg}
      onClick={onClick}
      color={disbled ? '#dfdedf' : '#5E0943'}
    >
      <Icons.CarouselLeftArrow {...renderOffset().arrow} color={'#ffffff'} />
    </ButtonArrow>
  );
};

const CustomRightArrow = ({ onClick, disbled }) => {
  return (
    <ButtonArrow
      {...renderOffset().bg}
      onClick={onClick}
      color={disbled ? '#dfdedf' : '#5E0943'}
    >
      <Icons.CarouselRightArrow {...renderOffset().arrow} color={'#ffffff'} />
    </ButtonArrow>
  );
};

const renderOffset = () => {
  let width = window.innerWidth;
  if (width > 540) {
    return {
      bg: { width: '40px', height: '40px' },
      arrow: { width: 12, height: 20 },
    };
  } else {
    return {
      bg: { width: '16px', height: '16px' },
      arrow: { width: 5, height: 8 },
    };
  }
};

export default VideoRecWidget;
