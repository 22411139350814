import styled from 'styled-components';

export const PreviewVideoRecStyled = styled.div`
  .preview_video_container {
    .top_icon_row {
      display: none;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      column-gap: 15px;
      align-items: center;
      margin-bottom: 30px;
      .top_svg_suggest {
        display: flex;
      }
      .top_svg_suggest_mobile {
        display: none;
      }
    }
    .link_box {
      cursor: pointer;
      display: flex;
      column-gap: 8px;
      .pv_left_col {
        width: 209px;
        .pv_figture {
          position: relative;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          .pv_img {
            margin-bottom: 15px;
            width: 209px;
            height: 118px;
            object-fit: cover;
          }
          .img_show {
            margin-bottom: 15px;
            width: 209px;
            height: 118px;
            // -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
            // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
            background: ${({ theme }) => theme.COLORS.PINK_5};
          }
          .pv_play_wrap {
            position: absolute;
          }
        }
      }
      .pv_right_col {
        flex: 1;
        overflow: auto;
        .icon_row {
          display: flex;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          column-gap: 15px;
          align-items: center;
          margin-bottom: 30px;
          .svg_suggest {
            display: flex;
          }
        }
        .hastag_row {
          margin-bottom: 3px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_13};
          color: ${({ theme }) => theme.COLORS.BLACK_7};
        }
        .pv_topic {
          margin-bottom: 2px;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_15};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          color: ${({ theme }) => theme.COLORS.BROWN_2};
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .pv_total_row {
          margin-bottom: 9px;
          display: flex;
          column-gap: 10px;
          .score_block {
            // padding: 0 10px;
            display: flex;
            align-items: center;
            column-gap: 5px;
            &.mobile {
              display: none;
            }
            .svg_view {
              display: flex;
            }
            .pv_score {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
              color: ${({ theme }) => theme.COLORS.BLACK_7};
            }
          }
        }
        .pv_desciption {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
          color: ${({ theme }) => theme.COLORS.BLACK_7};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
          letter-spacing: 0px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .theme_standard {
  }

  .theme_suggest {
    .preview_video_container {
      .link_box {
        .pv_left_col {
          width: 491px;
          .pv_figture {
            .pv_img {
              // width: 591px;
              width: 100%;
              height: 335px;
              margin-bottom: 0px;
            }
          }
        }
        .pv_right_col {
          margin-left: 40px;
          .pv_topic {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_30};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
            margin-bottom: 18px;
          }
          .pv_total_row {
            margin-bottom: 24px;
            column-gap: 24px;
            .score_block {
              column-gap: 24px;
              padding: 0px;
              border-right: 1px solid #9a9a9a40;
              &:last-child {
                border: none;
              }
              .svg_view {
              }
              .pv_score {
                padding-right: 24px;
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
                font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
              }
            }
          }
          .pv_desciption {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .theme_suggest {
      .preview_video_container {
        .top_icon_row {
          display: flex;
        }
        .link_box {
          flex-direction: column;
          .pv_left_col {
            width: 100%;
            .pv_figture {
              width: 100%;
              .pv_img {
                width: 100%;
                height: 275px;
              }
            }
          }
          .pv_right_col {
            margin-left: 0px;
            .icon_row {
              display: none;
            }
            .pv_topic {
              margin-top: 11px;
              margin-bottom: 0px;
            }
            .pv_total_row {
              margin-bottom: 11px;
            }
            .pv_desciption {
              overflow: hidden;
              white-space: normal;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .preview_video_container {
      .link_box {
        .pv_left_col {
          width: 170px;
          .pv_figture {
            .pv_img {
              width: 100%;
            }
          }
        }
        .pv_right_col {
          .pv_topic {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pv_desciption {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .hastag_row {
            .hastag_item {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
            }
          }
        }
      }
    }
    .theme_suggest {
      .preview_video_container {
        .top_icon_row {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
          margin-bottom: 8px;
          .top_svg_suggest {
            display: none;
          }
          .top_svg_suggest_mobile {
            display: flex;
          }
        }
        .link_box {
          flex-direction: column;
          .pv_left_col {
            width: 100%;
            .pv_figture {
              width: 100%;
              .pv_img {
                width: 100%;
                height: 157px;
              }
            }
          }
          .pv_right_col {
            .icon_row {
            }
            .pv_topic {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
            }
            .pv_total_row {
              column-gap: 10px;
              .score_block {
                column-gap: 10px;
                display: none;
                &.mobile {
                  display: flex;
                }
                .pv_score {
                  padding-right: 10px;
                  font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
                  color: ${({ theme }) => theme.COLORS.BLACK_7};
                }
              }
            }
            .pv_desciption {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
            }
          }
        }
      }
    }
  }
`;
