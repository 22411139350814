import styled from 'styled-components';

export const DropdownLibStyled = styled.div`
  .d_control {
    border-radius: 8px;
    border-color: ${({ theme }) => theme.COLORS.PINK_1};
    min-width: 230px;
    background: ${({ theme }) => theme.COLORS.PINK_1};
    font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    text-align: center;
    .d_arrow {
      position: absolute;
      right: 15px;
      top: 12px;
    }
  }

  .theme_standard {
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .d_control {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
      text-align: center;
      width: 115px;
      min-width: 0px;
      white-space: nowrap;
      .d_arrow {
        position: absolute;
        right: 15px;
        top: 12px;
      }
    }
  }
`;
