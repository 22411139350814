import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { PreviewVideoRecStyled } from './styled';
import { Icons } from 'components';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from 'utils/constants/routePath';
// import { FiEye } from 'react-icons/fi';
import { RiShareForwardFill } from 'react-icons/ri';
import { AiFillLike, AiFillEye } from 'react-icons/ai';

const PreviewVideoRec = ({
  theme_standard,
  theme_suggest,
  src,
  hastagList,
  topic,
  viewCount,
  shareCount,
  likeCount,
  postDate,
  desciption,
  uuid,
  handleFetchListVideo,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_suggest: theme_suggest,
  });

  return (
    <PreviewVideoRecStyled>
      <div className={customClass}>
        <div className="preview_video_container">
          {theme_suggest && (
            <div className="top_icon_row">
              <div className="top_svg_suggest">
                <Icons.VideoSuggest />
              </div>
              <div className="top_svg_suggest_mobile">
                <Icons.VideoSuggest width="14" height="14" />
              </div>
              <div>วิดีโอแนะนำสำหรับคุณ</div>
            </div>
          )}
          <Link
            to={`${ROUTE_PATH.VIDEOS}/${uuid}?sec=video`}
            className="link_box"
            onClick={() => handleFetchListVideo(uuid)}
          >
            <div className="pv_left_col">
              <div className="pv_figture">
                {src ? (
                  <img className="pv_img" alt="herwill video" src={src} />
                ) : (
                  <div className="img_show" />
                )}
                <div className="pv_play_wrap">
                  <Icons.Play />
                </div>
              </div>
            </div>
            <div className="pv_right_col">
              {theme_suggest && (
                <div className="icon_row">
                  <div className="svg_suggest">
                    <Icons.VideoSuggest />
                  </div>
                  <div>วิดีโอแนะนำสำหรับคุณ</div>
                </div>
              )}
              <div className="hastag_row">{hastagList}</div>
              <div className="pv_topic">{topic}</div>
              <div className="pv_total_row">
                {theme_suggest ? (
                  <>
                    <div className="score_block">
                      <div className="svg_view">
                        <AiFillEye color="#8e8e8e" fontSize={26} />
                      </div>
                      <div className="pv_score">{viewCount}</div>
                    </div>
                    <div className="score_block">
                      <div className="svg_view">
                        <AiFillLike color="#8e8e8e" fontSize={24} />
                      </div>
                      <div className="pv_score">{likeCount}</div>
                    </div>
                    <div className="score_block">
                      <div className="svg_view">
                        <RiShareForwardFill color="#8e8e8e" fontSize={32} />
                      </div>
                      <div className="pv_score">{shareCount}</div>
                    </div>
                    <div className="score_block mobile">
                      <div className="svg_view">
                        <AiFillEye color="#8e8e8e" fontSize={16} />
                      </div>
                      <div className="pv_score">{viewCount}</div>
                    </div>
                    <div className="score_block mobile">
                      <div className="svg_view">
                        <AiFillLike color="#8e8e8e" fontSize={14} />
                      </div>
                      <div className="pv_score">{likeCount}</div>
                    </div>
                    <div className="score_block mobile">
                      <div className="svg_view">
                        <RiShareForwardFill color="#8e8e8e" fontSize={22} />
                      </div>
                      <div className="pv_score">{shareCount}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="score_block">
                      <div className="svg_view">
                        <AiFillEye color="#8e8e8e" fontSize={12} />
                      </div>
                      <div className="pv_score">{viewCount}</div>
                    </div>
                  </>
                )}
                {postDate && (
                  <div className="score_block">
                    <Icons.Dot />
                    <div className="pv_score">{postDate}</div>
                  </div>
                )}
              </div>
              <div
                className="pv_desciption"
                dangerouslySetInnerHTML={{ __html: desciption }}
              />
            </div>
          </Link>
        </div>
      </div>
    </PreviewVideoRecStyled>
  );
};

PreviewVideoRec.propTypes = {};

export default PreviewVideoRec;
