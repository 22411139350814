import styled from 'styled-components';

export const HastagStyled = styled.div`
  .hastag_label {
    font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
    font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
    color: ${({ theme }) => theme.COLORS.BLACK_7};
  }

  .theme_standard {
    .hastag_label {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
    }
  } 
  
  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .theme_standard {
      .hastag_label {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_10};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
    } 
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .hastag_label {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
    }
    .theme_standard {
      .hastag_label {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_10};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
    } 
  }
`;
