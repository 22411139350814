import React from 'react';
import { InputSearchStyled } from './styled';
import cx from 'classnames';
import { Icons } from 'components';

const InputSearch = ({
  theme_standard,
  placeholder,
  input,
  disabled,
  type,
  value,
  onClickSearch,
  onClearText,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  // const clearText = () => {
  //   onClearText && onClearText()
  // }

  return (
    <InputSearchStyled>
      <div className={customClass}>
        <div className="input_search_container">
          <input
            className="input_search"
            {...props}
            {...input}
            type={type}
            value={input?.value || value}
            placeholder={placeholder}
            disabled={disabled}
          />
          {/* {
            value && value !== '' &&
            <div className="clear_icon" onClick={clearText}>
              <Icons.CloseX />
            </div>
          } */}
          <div className="search_icon" onClick={onClickSearch && onClickSearch}>
            <Icons.Search />
          </div>
          {/* {
            value && value !== '' &&
            <div className="clear_icon_moblie" onClick={clearText}>
              <Icons.CloseX width={14} height={14} />
            </div>
          } */}
          <div className="search_icon_moblie" onClick={onClickSearch && onClickSearch}>
            <Icons.Search width={14} height={14} />
          </div>
        </div>
      </div>
    </InputSearchStyled>
  );
};

export default InputSearch;
