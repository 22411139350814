import { WellBtnStyled } from './styled';
import cx from 'classnames';

const WellBtn = ({ theme_standard, theme_branner_3, prefixIcon, label, onClick }) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_branner_3: theme_branner_3,
  });
  return (
    <WellBtnStyled>
      <div className={customClass}>
        <div className="well_btn_container" onClick={onClick}>
          <div className="well_btn_icon_wrap">
            <div className="btn_cirle_bg">
              <img
                className="well_img"
                alt="well"
                src="/assets/images/icons/well-circle.png"
              />
            </div>
          </div>
          <div className="well_btn_label">
            {prefixIcon && (
              <img className="w_icon" alt="consult icon" src={prefixIcon} />
            )}{' '}
            <div className="w_label">{label}</div>
          </div>
        </div>
      </div>
    </WellBtnStyled>
  );
};

export default WellBtn;
