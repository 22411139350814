import { Banner2WidgetStyled } from './styled';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Buttons, Displays } from 'components';

const Banner2Widget = () => {
  const navigate = useNavigate();
  const { configProjectRedux } = useSelector((state) => state);

  const _handleClickSetQuick = () => {
    ReactGA.event({
      category: configProjectRedux.scheme + '_start',
      action: configProjectRedux.scheme + '_start_new_sess',
    });
    navigate(ROUTE_PATH.AUTO_SIGNUP);
  };

  return (
    <Banner2WidgetStyled>
      <div className="banner2_container">
        <div className="b2_title">
          "มะเร็งเต้านม"
          <span className="b2_t_black">
            รักษาหายได้มากกว่า ถ้าเจอในระยะเริ่มต้น
          </span>
        </div>
        <div className="b2_sub_title">
          ทำแบบทดสอบประเมินความเสี่ยงเบื้องต้น รู้ผลทันที
        </div>
        <div className="b_statistic_block">
          <div className="percent_row">
            <div className="p_label">ระยะที่ 1</div>
            <Displays.Percent theme_branner percent={98} color="#9BDEAC" />
          </div>
          <div className="percent_row">
            <div className="p_label">ระยะที่ 2-3</div>
            <Displays.Percent theme_branner percent={84} color="#F0A5C1" />
          </div>
          <div className="percent_row mb_0">
            <div className="p_label">ระยะที่ 4</div>
            <Displays.Percent theme_branner percent={24} color="#CC8190" />
          </div>
          <div className='text_det'>
            อัตราการหายขาดเมื่อตรวจพบในแต่ละระยะ
          </div>
        </div>
        <div className="b2_btn_block">
          <div className="icon_click_wrap">
            <img
              className="click_icon"
              alt="icon click"
              src="/assets/images/icons/click.png"
            />
          </div>
          <Buttons.WellBtn
            label={'คลิก ทำแบบทดสอบ'}
            onClick={_handleClickSetQuick}
          />
        </div>
        <div className="b2_hastag">
          #มะเร็งเต้านม รู้ไว โอกาสรักษาหายสูงกว่า
        </div>
        <div className='label_mth_2'>
          M-TH-00002355
        </div>
      </div>
    </Banner2WidgetStyled>
  );
};

export default Banner2Widget;
