import React from 'react';
import { VideoContainerStyled } from './styled';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { herwillService } from 'apiService';
import { withRouter } from 'navigations/withRouter';
import { connect } from 'react-redux';
import millify from 'millify';
import { RenderTag } from 'utils/functions/RenderTag';
// import { FiEye } from 'react-icons/fi';
// import { AiOutlineLike } from 'react-icons/ai';
import { RiShareForwardLine } from 'react-icons/ri';
import { AiFillLike, AiFillEye } from 'react-icons/ai';
import { Helmet } from 'react-helmet';
import { SCROLL_TO_TOP } from 'utils/functions/scroll';
import { ShareVideoWidget, FilterVideoWidget } from 'widgets';
import {
  Cards,
  Typhographys,
  Buttons,
  Icons,
  Modals,
  Displays,
} from 'components';

class VideoContainer extends React.Component {
  state = {
    isLoading: true,
    dataListSuggest: [],
    countLike: 0,
    actionLike: false,
    WindowWidth: window.innerWidth,
  };

  componentDidMount() {
    SCROLL_TO_TOP();
    this.fetchData();
    this.fetchListVideo(this.props.router.params?.id);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.router.params.id !== prevProps.router.params.id) {
      this.setState({
        isLoading: true,
      });
      this.fetchData();
      SCROLL_TO_TOP();
    }
  }

  fetchData = async () => {
    let res = await herwillService.GET_CONTENT_DETAIL(
      this.props.router.params?.id
    );

    if (res && res.success) {
      let tempVideoSelect;
      let catText = res.data.categories.map((e) => e.name_th).join(',');
      let tagText = '';
      if (res.data.video480_uri) {
        tempVideoSelect = res.data.video480_uri;
      } else if (res.data.video720_uri) {
        tempVideoSelect = res.data.video720_uri;
      } else if (res.data.video1080_uri) {
        tempVideoSelect = res.data.video1080_uri;
      }
      res.data.tags.forEach((e) => {
        tagText = tagText + `#${e.tag} `;
      });
      this.setState({
        data: { ...res.data, show_category: catText, show_tags: tagText },
        category: res.data.categories[0].uuid,
        actionLike: res.data.me.like_at ? true : false,
        countLike: res.data.like_count,
        videoSelect: tempVideoSelect,
        isLoading: false,
      });
    } else {
      this.props.router.navigate('/');
    }
  };

  fetchListVideo = async (e) => {
    let res = await herwillService.GET_CONTENT_SUGGEST_LIST(e);
    if (res && res.success) {
      this.setState({
        dataListSuggest: res.data,
      });
    }
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
    });
  };

  handleFetchListVideo = (e) => {
    this.fetchListVideo(e);
  };

  handleClickShare = () => {
    const { data } = this.state;
    const url = window.location.href;

    this.setState({
      isModal: true,
      renderModal: (
        <ShareVideoWidget
          onClose={this.handleClickCloseModal}
          url={url}
          content_uuid={data.uuid}
        />
      ),
    });
  };

  handleLikeUnLike = async () => {
    const { data, actionLike, countLike } = this.state;
    let params = {
      like_at: actionLike
        ? null
        : moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    };

    await herwillService.PATCH_CONTENT_DETAIL(data.uuid, params);

    if (actionLike) {
      this.setState({
        countLike: countLike - 1,
        actionLike: !actionLike,
      });
    } else {
      this.setState({
        countLike: countLike + 1,
        actionLike: !actionLike,
      });
    }
  };

  render() {
    const {
      isModal,
      renderModal,
      isLoading,
      data,
      category,
      videoSelect,
      dataListSuggest,
      countLike,
      actionLike,
      WindowWidth
    } = this.state;
    return (
      <VideoContainerStyled>
        <div className="filter_header_wrap">
          <FilterVideoWidget
            labelType="label"
            hideSearch
            initialValues={category}
            grayLabel
          />
        </div>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            <Helmet>
              <title>Herwill | {data?.topic}</title>
              <meta name="og:title" content={`"Herwill | ${data?.topic}"`} />
              <meta name="og:description" content={`"${data?.short_body}"`} />
              <meta name="og:hashtag" content={`"${data?.show_tags}"`} />
              <meta
                name="og:image"
                content={`"${data?.cover_thumbnail_uri}"`}
              />
            </Helmet>
            <div className="row">
              <div className="left_col">
                <div className="player_wrapper">
                  {videoSelect ? (
                    <ReactPlayer
                      className="react_player"
                      controls
                      url={videoSelect}
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <div className="img_show">
                      <Icons.Play />
                    </div>
                  )}
                </div>
                <div className="hr_row">
                  <div className="hr_left_col">

                  </div>
                  <div className="hr_right_col">
                    <Buttons.PrefixIconBtn
                      theme_small
                      icon={<Icons.VideoReport2 />}
                      label="Report"
                    />
                  </div>
                </div>
                <div className="topic_label">{data?.topic}</div>
                <div className="score_row">
                  <div className="s_left_col">
                    <div className='box_1'>
                      <Buttons.PrefixIconBtn
                        theme_standard_view_regular
                        // icon={<Icons.Dot />}
                        label={
                          data &&
                          moment(data.created_at)
                            .tz('Asia/Bangkok')
                            .format('DD MMM. YYYY')
                        }
                      />
                    </div>
                    <div className='box_2'> 
                      <Buttons.PrefixIconBtn
                        theme_standard_view_regular
                        icon={
                          <AiFillEye
                            color={`${data.me.view_at ? '#B14359' : '#8e8e8e'}`}
                            fontSize={WindowWidth < 768 ? 16 : 26}
                          />
                        }
                        label={data && millify(Number(data.total_view_count))}
                      />
                    </div>
                    <div className='box_2'>
                      <Buttons.PrefixIconBtn
                        theme_standard_view_regular
                        icon={
                          <AiFillLike
                            color={`${actionLike ? '#B14359' : '#8e8e8e'}`}
                            fontSize={WindowWidth < 768 ? 12 : 20}
                          />
                        }
                        label={data && millify(Number(countLike))}
                        onClick={this.handleLikeUnLike}
                      />
                    </div>
                    <div className='box_2 noline'>
                      <Buttons.PrefixIconBtn
                        theme_standard_view_regular
                        // icon={<Icons.VideoShared />}
                        icon={
                          <RiShareForwardLine 
                          fontSize={WindowWidth < 768 ? 18 : 24}
                          color="#8e8e8e" />
                        }
                        label={
                          data && millify(Number(data.share_count))
                        }
                        onClick={this.handleClickShare}
                      />
                    </div>
                  </div>
                </div>
                <div className="hr_row mb">
                  <div className="hr_left_col">
                    <Typhographys.Hastag
                      theme_standard
                      label={`${data?.show_category} : ${data?.show_tags}`}
                    />
                  </div>
                </div>
                {/* <div className="pink_line" /> */}
                <div
                  className="desciption"
                  dangerouslySetInnerHTML={{ __html: data?.body }}
                />
                <div className="moblie_layout_show">
                  <div className="pink_line_mobile" />
                  <div className="label_moblie">วิดีโอ Herwill อื่นๆ</div>
                </div>
              </div>
              <div className="right_col">
                <div className="label_destop">วิดีโอ Herwill อื่นๆ</div>
                {dataListSuggest &&
                  dataListSuggest.length > 0 &&
                  dataListSuggest.map((e, i) => (
                    <div key={i} className="preview_item_wrap">
                      <Cards.PreviewVideoRec
                        src={e.cover_thumbnail_uri}
                        hastagList={RenderTag(e.tags)}
                        topic={e.topic}
                        viewCount={millify(Number(e.total_view_count))}
                        postDate={moment(e.created_at)
                          .tz('Asia/Bangkok')
                          .format('DD MMM. YYYY')}
                        desciption={e.short_body}
                        uuid={e.uuid}
                        handleFetchListVideo={this.handleFetchListVideo}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
        <Modals.Modal
          theme_modal_standard
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
          bgColor="rgba(0, 0, 0, 0.8)"
        >
          {renderModal}
        </Modals.Modal>
      </VideoContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VideoContainer));
