import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';

const bounceAnimation = keyframes`${fadeInUp}`;

export const LogoLoadingFadeStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  animation: 1s ${bounceAnimation};

  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo_line_out {
    position: absolute;
    width: 100%;
    height: auto;
    -webkit-animation: rotation 2s infinite;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }

  .logo_line_in {
    position: absolute;
    width: 75%;
    height: auto;
    -webkit-animation: rotation 2s infinite;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }

  .log_char {
    width: 70px;
    height: auto;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
