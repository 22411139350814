import React from 'react';
import { TokenManagerStyled } from './styled';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { setReduxUserAuth } from 'store/actions';
import { userService } from 'apiService';
import { withRouter } from 'navigations/withRouter';
import { Displays } from 'components';

class TokenManager extends React.Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    if (
      this.props.location?.pathname !== '/login/facebook' &&
      this.props.location?.pathname !== '/login/line'
    ) {
      // this.checkTokenExpire();
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  checkTokenExpire = async () => {
    const sessionStorageTokenApi = sessionStorage.getItem('tokenList');
    if (sessionStorageTokenApi) {
      this.fetchProfileData();
    } else {
      const localStorageTokenApi = localStorage.getItem('tokenList');

      if (!localStorageTokenApi) {
        this.destroyStore();
      } else {
        let res = await userService.POST_LOGIN_WITH_TOKEN();
        if (res && res.success) {
          sessionStorage.setItem(
            'tokenList',
            JSON.stringify({
              token: res.token,
              isRemember: JSON.parse(localStorageTokenApi).isRemember,
            })
          );
          localStorage.setItem(
            'tokenList',
            JSON.stringify({
              token: res.token,
              isRemember: JSON.parse(localStorageTokenApi).isRemember,
            })
          );
          // this.nextPage();
          this.fetchProfileData();
        } else {
          this.destroyStore();
        }
      }
    }
  };

  fetchProfileData = async (loginWith) => {
    let res = await userService.GET_PROFILE_DETAIL();
    if (res && res.success) {
      this.setState({
        initialValues: { ...res.data, ...res.data.profile },
      });
      let params = {
        ...res.data,
        ...res.data.profile,
      };
      delete params.profile;
      this.props.setReduxUserAuth({ ...params });
      this.handleCheckProfile();
    }
  };

  handleCheckProfile = () => {
    const { authRedux } = this.props;
    if (authRedux.id && authRedux.firstname) {
      // Set userId for existing users
      ReactGA.set({
        userId: authRedux.id,
      });
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
        if (this.props.configProjectRedux.is_corporate && !authRedux.company) {
          if (this.props.configProjectRedux.is_pinkalert) {
            this.props.router.navigate(`${ROUTE_PATH.TERM_OF_USE}`);
          } else {
            this.props.router.navigate(
              `${ROUTE_PATH.WELL_TERM_OF_USE_REGISTRY}`
            );
          }
        } else {
          this.props.router.navigate(this.props.configProjectRedux.home_uri);
        }
      }, Math.floor(Math.random() * 800) + 200);
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
        if (this.props.configProjectRedux.is_pinkalert) {
          this.props.router.navigate(`${ROUTE_PATH.TERM_OF_USE}`);
        } else {
          this.props.router.navigate(`${ROUTE_PATH.WELL_TERM_OF_USE_REGISTRY}`);
        }
      }, Math.floor(Math.random() * 800) + 200);
    }
  };

  destroyStore = () => {
    this.setState({
      isLoading: false,
    });
    localStorage.removeItem('token');
    if (this.props.location.pathname === '/login') {
    } else {
      this.props.router.navigate('/');
    }
  };

  render() {
    const { children } = this.props;
    const { isLoading } = this.state;
    return (
      <TokenManagerStyled>
        {isLoading ? <Displays.Loading /> : children}
      </TokenManagerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.authenRedux,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TokenManager));
