import React from 'react';

export default function AaPlatform() {
  return (
    <svg
      id="_01-Play"
      data-name="01-Play"
      xmlns="http://www.w3.org/2000/svg"
      width="27.9"
      height="32.545"
      viewBox="0 0 27.9 32.545"
    >
      <path
        id="Path_87755"
        data-name="Path 87755"
        d="M29.769,14.053,9.812,2.523A3.754,3.754,0,0,0,7.929,2,3.929,3.929,0,0,0,4,5.929V30.675a3.871,3.871,0,0,0,5.928,3.278L29.909,21.33a4.243,4.243,0,0,0-.139-7.276Z"
        transform="translate(-4 -2)"
        fill="rgba(177,177,177,0.62)"
      />
    </svg>
  );
}
