import React from 'react';
import ReactGA from 'react-ga';
import { ShareBtnHerWillStyled } from './styled';
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from 'react-share';
import ReactTooltip from 'react-tooltip';

const ShareBtnHerWill = (props) => {
  const copyFunction = () => {
    var copyText = document.createElement('textarea');
    copyText.value = 'https://herwill.wellcancer.com';
    document.body.append(copyText);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    copyText.remove();

    // Tracking user event
    ReactGA.event({
      category: 'herwill_share',
      action: 'click_copy',
    });
  };

  const handleReactShare = (channel) => {
    // Tracking user event
    ReactGA.event({
      category: 'herwill_share',
      action: 'click_' + channel + (props.source && '_' + props.source),
    });

    return true;
  };

  return (
    <ShareBtnHerWillStyled>
      <div className="layout_share">
        <FacebookShareButton
          url={'https://herwill.wellcancer.com'}
          quote={
            'แด่ เธอ ผู้หญิงทุกคน วันนี้เราจะร่วมสู้มะเร็งเต้านมไปด้วยกัน คุณสามารถเข้าร่วมโครงการ Her Will ผ่านการตรวจความเสี่ยงตนเองฟรี ที่นี่ herwill.wellcancer.com และแชร์ข้อความนี้ต่อให้กับผู้หญิงรอบตัวท่านได้'
          }
          hashtag={'#HERWill'}
          beforeOnClick={() => handleReactShare('facebook')}
        >
          <img
            className="icon_share icon_share_logo"
            src={'/assets/images/icons/share_facebook.png'}
            alt="logo well"
          />
        </FacebookShareButton>

        <LineShareButton
          url={'https://herwill.wellcancer.com'}
          title={
            'แด่ เธอ ผู้หญิงทุกคน วันนี้เราจะร่วมสู้มะเร็งเต้านมไปด้วยกัน คุณสามารถเข้าร่วมโครงการ Her Will ผ่านการตรวจความเสี่ยงตนเองฟรี ที่นี่ herwill.wellcancer.com และแชร์ข้อความนี้ต่อให้กับผู้หญิงรอบตัวท่านได้'
          }
          beforeOnClick={() => handleReactShare('line')}
        >
          <img
            className="icon_share icon_share_logo"
            src={'/assets/images/icons/line_share.png'}
            alt="logo well"
          />
        </LineShareButton>

        <TwitterShareButton
          url={'https://herwill.wellcancer.com'}
          title={
            'แด่ เธอ ผู้หญิงทุกคน วันนี้เราจะร่วมสู้มะเร็งเต้านมไปด้วยกัน คุณสามารถเข้าร่วมโครงการ Her Will ผ่านการตรวจความเสี่ยงตนเองฟรี ที่นี่ herwill.wellcancer.com และแชร์ข้อความนี้ต่อให้กับผู้หญิงรอบตัวท่านได้'
          }
          hashtags={['HERWil', 'เพื่อเธอ']}
          beforeOnClick={() => handleReactShare('twitter')}
        >
          <img
            className="icon_share icon_share_logo"
            src={'/assets/images/icons/twit_share.png'}
            alt="logo well"
          />
        </TwitterShareButton>

        <div className="icon_share">
          <input
            type="text"
            value={'https://herwill.wellcancer.com'}
            id="myInput"
            hidden
          />
          <ReactTooltip
            className="box_tooltip"
            id="shareTip"
            place="top"
            effect="solid"
            afterShow={() => {
              setTimeout(ReactTooltip.hide, 2000);
            }}
          >
            copied to clipboard
          </ReactTooltip>
          <img
            onClick={copyFunction}
            className=" icon_share_logo"
            src={'/assets/images/icons/link_share.png'}
            alt="logo well"
            data-tip
            data-for="shareTip"
            data-event="click focus"
          />
        </div>
      </div>
    </ShareBtnHerWillStyled>
  );
};

export default ShareBtnHerWill;
