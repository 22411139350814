import styled from 'styled-components';

export const BoxNoticStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .top_notic {
    display: flex;
    align-items: center;
    column-gap: 24px;
    .svg {
      display: flex;
      background-image: url(/assets/images/icons/pink_notic.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 79px;
      height: 88px;
      justify-content: center;
      align-items: center;
      padding-left: 4px;
      .label_num {
        text-align: center;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_36};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
    }
    .tn_moblie {
      display: none;
      .svg_mobile {
        background-image: url(/assets/images/icons/pink_notic.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 21px;
        height: 19px;
        display: flex;
        padding-left: 2px;
        justify-content: center;
        align-items: center;
        .mo_num {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_13};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          color: ${({ theme }) => theme.COLORS.WHITE_1};
        }
      }
    }
    .tn_tablet {
      display: none;
      .svg_tablet {
        background-image: url(/assets/images/icons/pink_notic.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 57px;
        height: 63px;
        justify-content: center;
        align-items: center;
        padding-left: 4px;
        .tn_num {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_26};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          color: ${({ theme }) => theme.COLORS.WHITE_1};
        }
      }
    }
    .top_label {
      // font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_36};
      font-size: 1.8vw;
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
      color: ${({ theme }) => theme.COLORS.BROWN_1};
    }
  }
  .group_bottom {
    .svg_mid {
      display: flex;
      margin-top: 4px;
      justify-content: center;
      .img_1 {
        width: auto;
        height: 4.5vw;
      }
      &.mobile {
        display: none;
      }
    }
    .label_bottom {
      // font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_26};
      font-size: 1.27vw;
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
      color: ${({ theme }) => theme.COLORS.BROWN_1};
      text-align: center;
      margin-top: 10px;
    }
  }
  .theme_standard_box_notic {
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .top_notic {
      .svg {
        display: none;
      }
      .tn_tablet {
        display: flex;
        .svg_tablet {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .top_label {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_26};
      }
    }
    .group_bottom {
      display: flex;
      column-gap: 2.5px;
      padding-left: 62px;
      .svg_mid {
        .img_1 {
          width: auto;
          height: 40px;
        }
      }
      .label_bottom {
        text-align: left;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .top_notic {
      column-gap: 8px;
      .tn_tablet {
        display: none;
      }
      .tn_moblie {
        display: flex;
        
      }
      .top_label {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
      }
    } 
    .group_bottom {
      padding-left: 26px;
      .svg_mid {
        .img_1 {
          width: auto;
          height: 40px;
        }
      }
      .label_bottom {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
      }
    }
  }
`;
