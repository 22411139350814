import { ContentWidgetStyled, ButtonArrow } from './styled';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Cards, Icons } from 'components';
import { herwillService } from 'apiService';

const ContentWidget = ({ contentList }) => {
  const clickMore = async (e) => {
    herwillService.GET_CONTENT_DETAIL(e);
  };

  return (
    <ContentWidgetStyled>
      <div className="cw_header_row">
        <div className="cw_icon_wrap">
          <Icons.CircleContent />
        </div>
        <div className="cw_icon_wrap_mobile">
          <Icons.CircleContent height="16px" width="16px" />
        </div>
        <div className="cw_title">
          <div>
            บทความ{' '}
            <span className="sub_text">
              เพื่อการเรียนรู้เพิ่มเติมเกี่ยวกับมะเร็งเต้านม
            </span>
          </div>
        </div>
      </div>
      <div className="cw_content">
        {contentList && contentList.length > 0 ? (
          <Carousel
            responsive={responsive}
            autoPlay={false}
            renderButtonGroupOutside
            arrows={false}
            customButtonGroup={
              <ButtonGroup count={contentList ? contentList.length : 0} />
            }
            partialVisible={true}
          >
            {contentList &&
              contentList.map((e, i) => (
                <div key={i} className="content_item">
                  <Cards.ContentItem
                    src={e.cover_uri}
                    title={e.topic}
                    subTitle={e.short_body}
                    link_uri={e.link_uri}
                    uuid={e.uuid}
                    clickMore={clickMore}
                  />
                </div>
              ))}
          </Carousel>
        ) : (
          <div>ยังไม่มี content</div>
        )}
      </div>
    </ContentWidgetStyled>
  );
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4, // optional, default to 1.
  },
  mediumTablet: {
    breakpoint: { max: 1536, min: 464 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 100, // this is needed to tell the amount of px that should be visible.
  },
};

const ButtonGroup = ({ next, previous, goToSlide, count, ...rest }) => {
  const {
    carouselState: { currentSlide, slidesToShow },
  } = rest;
  return (
    <div className="carousel_button_group">
      <CustomLeftArrow
        disbled={currentSlide === 0 ? 'disable' : ''}
        onClick={previous}
      />
      <CustomRightArrow
        disbled={currentSlide + slidesToShow >= count ? 'disable' : ''}
        onClick={next}
      />
    </div>
  );
};

const CustomLeftArrow = ({ onClick, disbled }) => {
  return (
    <ButtonArrow
      {...renderOffset().bg}
      onClick={onClick}
      color={disbled ? '#dfdedf' : '#5E0943'}
    >
      <Icons.CarouselLeftArrow {...renderOffset().arrow} color={'#ffffff'} />
    </ButtonArrow>
  );
};

const CustomRightArrow = ({ onClick, disbled }) => {
  return (
    <ButtonArrow
      {...renderOffset().bg}
      onClick={onClick}
      color={disbled ? '#dfdedf' : '#5E0943'}
    >
      <Icons.CarouselRightArrow {...renderOffset().arrow} color={'#ffffff'} />
    </ButtonArrow>
  );
};

const renderOffset = () => {
  let width = window.innerWidth;
  if (width > 540) {
    return {
      bg: { width: '40px', height: '40px' },
      arrow: { width: 12, height: 20 },
    };
  }
  return {
    bg: { width: '16px', height: '16px' },
    arrow: { width: 5, height: 8 },
  };
};

export default ContentWidget;
