import styled from 'styled-components';

export const PinkAlertContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .navbar_pink_wrap {
    position: sticky;
    top: 0px;
    z-index: 2000;
  }
  .body_widget_show {
    .label_number {
      padding: 10px;
      padding-top: 2px;
      text-align: right;
      background: #fff4f9;
      color: ${({ theme }) => theme.COLORS.GRAY_20};
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {

  }
  
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .body_widget_show {
      .label_number {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
      }
    }
  }
`;
