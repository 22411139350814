import { Banner1WidgetStyled } from './styled';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Buttons } from 'components';

const Banner1Widget = () => {
  const navigate = useNavigate();
  const { configProjectRedux } = useSelector((state) => state);

  const _handleClickSetQuick = () => {
    ReactGA.event({
      category: configProjectRedux.scheme + '_start',
      action: configProjectRedux.scheme + '_start_new_sess',
    });
    navigate(ROUTE_PATH.AUTO_SIGNUP);
  };

  return (
    <Banner1WidgetStyled>
      <div className="banner_1_container">
        <div className="banner1_header_block">
          <div className="b1_sub_title">คุณมีความเสี่ยง</div>
          <div className="b1_title">
            "มะเร็งเต้านม" <span className="b1_t_black">หรือเปล่า?</span>
          </div>
        </div>
        <div className="banner1_description_block">
          <div className="b1_description">
            วันนี้คุณสามารถเช็คความเสี่ยงเบื้องต้น
          </div>
          <div className="b1_description">
            ด้วยการทำแบบทดสอบประเมินความเสี่ยง
          </div>
        </div>
        <div className="b1_btn_block">
          <div className="icon_click_wrap">
            <img
              className="click_icon"
              alt="icon click"
              src="/assets/images/icons/click.png"
            />
          </div>
          <Buttons.WellBtn
            label={'คลิก ทำแบบทดสอบ'}
            onClick={_handleClickSetQuick}
          />
        </div>
        <div className="b1_hastag">
          #มะเร็งเต้านม รู้ไว โอกาสรักษาหายสูงกว่า
        </div>
        <div className='label_mth_1'>
          M-TH-00002354
        </div>
      </div>
    </Banner1WidgetStyled>
  );
};

export default Banner1Widget;
