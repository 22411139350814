import styled from 'styled-components';

export const HospitalChooseContainerStyled = styled.div`
  /*===============================================
 Container
===============================================*/

  /*===============================================
Theme
===============================================*/
  min-height: 100vh;
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bg_top_share {
    display: none;
    position: absolute;
    z-index: -1;
    height: 53vw;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .group_share {
    padding: 20px 15px;
    .link_back {
      padding: 0px 60px;
      .destop {
        display: block;
      }
      .mobile {
        display: none;
      }
    }
    .container_share {
      max-width: 800px;
      margin: auto;
      .title_page {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
        font-weight: bold;
        color: ${({ theme }) => theme.COLORS.BLUE_2};
      }
      .sub_title_page {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        font-weight: bold;
        color: ${({ theme }) => theme.COLORS.BLUE_2};
        margin: 26px 0px 16px 0px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .group_share {
      .link_back {
        padding: 0px 20px;
      }
      .container_share {
        padding: 0px;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    .bg_top_share {
      display: block;
    }
    .group_share {
      .link_back {
        .destop {
          display: none;
        }
        .mobile {
          display: block;
        }
      }
      .container_share {
        .title_page {
          text-align: center;
          padding-top: 10vw;
        }
        .sub_title_page {
          margin-bottom: 26px;
        }
      }
    }
  }
`;
