import React from 'react';

export default function AaPlatform({
  color = '#9a9a9a',
  width = 27.199,
  height = 48,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27.199 48"
    >
      <path
        id="Path_110387"
        data-name="Path 110387"
        d="M.941,22.127,21.544.767a2.476,2.476,0,0,1,3.582,0l1.517,1.573a2.7,2.7,0,0,1,0,3.714L9.343,23.99l17.32,17.957a2.7,2.7,0,0,1,0,3.714l-1.517,1.572a2.475,2.475,0,0,1-3.582,0L.941,25.854a2.72,2.72,0,0,1,0-3.726"
        transform="translate(-0.203)"
        fill={color}
      />
    </svg>
  );
}
