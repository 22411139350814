import React from 'react';

export default function AaPlatform({
  width = "40",
  height = "40",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 40 40"
    >
      <defs>
        <filter
          id="Path_107533"
          x="9.5"
          y="10.5"
          width="25.371"
          height="23.61"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur" />
          <feFlood flood-opacity="0.051" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_90188"
        data-name="Group 90188"
        transform="translate(-2054 -4990)"
      >
        <path
          id="Path_107687"
          data-name="Path 107687"
          d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z"
          transform="translate(2054 4990)"
          fill="#ed799e"
        />
        <g
          transform="matrix(1, 0, 0, 1, 2054, 4990)"
          filter="url(#Path_107533)"
        >
          <path
            id="Path_107533-2"
            data-name="Path 107533"
            d="M90.7,8.208l-6.786,6.786L77.131,8.208a4.544,4.544,0,1,1,6.428-6.423l.359.359.359-.359A4.544,4.544,0,1,1,90.7,8.208"
            transform="translate(-63.73 12.62)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
