import styled from 'styled-components';

export const ShareContainerStyled = styled.div`
  /*===============================================
 Container
===============================================*/

  /*===============================================
Theme
===============================================*/
  background-color: #f8f8f8;
  height: calc(100vh - 60px);
  .bg_layout {
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon_logo {
      margin-top: 70px;
    }
  }
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .group_box {
    padding: 0px 20px;
    .box_layout {
      margin-top: -120px;
      margin-left: auto;
      margin-right: auto;
      max-width: 800px;
      width: 100%;
      /* height: 350px; */
      background-color: white;
      border: 2px solid rgba(155, 155, 155, 0.2);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 20px;
      .layout_share {
        display: flex;
        flex-direction: row;
        width: 220px;
        justify-content: space-between;
      }
      .txt_center {
        display: flex;
        align-items: center;
        .txt_normal {
          color: black;
          font-size: 16px;
        }
        .txt_pink {
          color: #e96ea5;
          font-size: 18px;
        }
        .txt_navy {
          color: #3e6689;
          font-size: 20px;
          margin-top: 40px;
          font-weight: 700;
        }
      }
      .icon_share {
        margin-top: 10px;
        margin-bottom: 30px;
        cursor: pointer;
      }
      .icon_share_logo {
        width: 45px;
        height: 45px;
        cursor: pointer;
      }
      .btn_end {
        height: 45px;
        width: 350px;
        border-radius: 20px;
        background-color: #3b96b7;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        box-shadow: 2px 1px 6px 0px rgba(0, 0, 0, 0.2),
          0 4px 20px 0 rgba(0, 0, 0, 0.19);
        .txt_btn {
          color: white;
          font-size: 18px;
        }
      }
      .btn_pink {
        height: 45px;
        width: 350px;
        border-radius: 20px;
        background-color: white;
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        box-shadow: 2px 1px 6px 0px rgba(0, 0, 0, 0.2),
          0 4px 20px 0 rgba(0, 0, 0, 0.19);
        .txt_btn {
          color: #e96ea5;
          font-size: 18px;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    height: 108vh;
    .bg_layout {
      margin-top: -40px;
      .icon_logo {
        margin-top: 70px;
        width: 150px;
      }
    }
    .group_box {
      .box_layout {
        margin-top: -200px;
        text-align: center;
        .txt_center {
          text-align: center;
          .txt_normal {
            font-size: 12px;
          }
          .txt_pink {
            font-size: 22px;
            margin-left: 5px;
          }
          .txt_navy {
            color: #3e6689;
            font-size: 15px;
            margin-top: 40px;
            font-weight: 700;
          }
        }
        .btn_end {
          width: 350px;
          height: 45px;
          .icon_layout {
            width: 35px;
            height: 35px;
            background-color: white;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            .icon_btn {
              width: 20px;
              resize: cover;
            }
          }
          .txt_btn {
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
    height: 108vh;
    .bg_layout {
      .icon_logo {
        margin-top: 70px;
        width: 150px;
      }
    }
    .group_box {
      .box_layout {
        margin-top: -200px;
        .txt_center {
          .txt_normal {
            font-size: 12px;
          }
          .txt_pink {
            font-size: 16px;
          }
          .txt_navy {
            color: #3e6689;
            font-size: 15px;
            margin-top: 40px;
            font-weight: 700;
          }
        }
        .btn_end {
          width: 210px;
          height: 40px;
          .icon_layout {
            width: 35px;
            height: 35px;
            background-color: white;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            .icon_btn {
              width: 20px;
              resize: cover;
            }
          }
          .txt_btn {
            text-align: center;
            font-size: 14px;
          }
        }
        .btn_pink {
          width: 210px;
          height: 40px;
          .txt_btn {
            color: #e96ea5;
            font-size: 14px;
          }
        }
      }
    }
  }
`;
