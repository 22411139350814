import React from 'react';

export default function AaPlatform({ color = '#4E5B7E', width = "93.056", height = "93" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 93.056 93">
      <defs>
        <filter id="Path_108870" x="0" y="0" width={width} height={height} filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.09" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_108870)">
        <path id="Path_108870-2" data-name="Path 108870" d="M71.069,26.013l2.386-2.386a5.47,5.47,0,0,0,0-7.737L68.491,10.93l2.521-2.52A3.127,3.127,0,1,0,66.59,3.988L64.07,6.509,59.113,1.551a5.6,5.6,0,0,0-7.741,0L48.987,3.938l-.819-.82a5.6,5.6,0,0,0-7.741,0L38.595,4.951a5.48,5.48,0,0,0,0,7.74l9.651,9.652L22.388,48.2l-9.651-9.663a5.482,5.482,0,0,0-7.737,0L3.168,40.368a5.47,5.47,0,0,0,0,7.74l.822.823L1.6,51.317a5.466,5.466,0,0,0,0,7.737l4.961,4.961-2.521,2.52a3.127,3.127,0,1,0,4.422,4.422l2.52-2.521,4.957,4.957a5.467,5.467,0,0,0,7.741,0l2.386-2.386.819.819a5.471,5.471,0,0,0,7.741,0L36.461,70a5.48,5.48,0,0,0,0-7.74l-9.651-9.651L52.668,26.749,62.318,36.4a5.477,5.477,0,0,0,7.737,0l1.833-1.832a5.467,5.467,0,0,0,0-7.737Z" transform="translate(6 6)" fill="#ed799e" />
      </g>
    </svg>
  );
}
