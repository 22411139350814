import { useEffect, useState } from 'react';
import { FilterVideoStyled } from './styled';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { herwillService } from 'apiService';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Inputs } from 'components';

const FilterVideo = ({ initialValues, labelType, onFilter, hideSearch, grayLabel }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { keywords, categories } = queryString.parse(location.search);

  const [_cateSearch, _setCateSearch] = useState();
  const [_keywordSearch, _setKeywordSearch] = useState();

  useEffect(() => {
    if (keywords) {
      _setKeywordSearch(keywords);
    }
    if (categories) {
      _setCateSearch(categories);
    }
    if (initialValues) {
      _setCateSearch(initialValues);
    }
  }, [location, initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleChangeSearch = (e) => {
    _setKeywordSearch(e.target.value);
  };

  const _renderLabelType = () => {
    switch (labelType) {
      case 'logo':
        return (
          <img
            className="logo"
            alt="herwill logo"
            src="/assets/images/icons/new_logo_her_will.png"
          />
        );
      case 'label':
        return <div className="f_label_show" style={{ color: grayLabel && '#9A9A9A' }}>หมวดหมู่วิดีโอ</div>;

      case 'categories':
        return <div className="cate_label">หมวดหมู่วิดีโอ</div>;

      default:
        break;
    }
  };

  const _handleEnterSubmit = (e) => {
    e.preventDefault();
    let query = [];
    if (_keywordSearch) {
      query.push(`keywords=${_keywordSearch}`);
    }
    if (_cateSearch) {
      query.push(`categories=${_cateSearch}`);
    }
    navigate(
      `${ROUTE_PATH.VIDEOS}${query.length === 0 ? '' : `?${query.join('&')}`}`
    );
  };

  const _handleChangeCate = (e) => {
    _setCateSearch(e.value);
    let query = [];
    if (e.value) {
      query.push(`categories=${e.value}`);
    }
    navigate(
      `${ROUTE_PATH.VIDEOS}${query.length === 0 ? '?sec=video' : `?sec=video&${query.join('&')}`
      }`
    );
    onFilter && onFilter(e);
  };

  const _handleClearText = () => {
    _setKeywordSearch('');
  };

  return (
    <FilterVideoStyled labelType={labelType}>
      <form className="fv_form" onSubmit={_handleEnterSubmit}>
        {_renderLabelType()}
        {!hideSearch ? (
          <>
            <div className="search_wrap">
              <Inputs.InputSearch
                placeholder="ค้นหา"
                value={_keywordSearch}
                onChange={_handleChangeSearch}
                onClickSearch={_handleEnterSubmit}
                onClearText={_handleClearText}
              />
            </div>
            <div className="dropdown_wrap">
              <Inputs.InputNormalSelect
                endPoint={herwillService.GET_CONTENT_CATEGORY}
                value={_cateSearch}
                placeholder="หมวดหมู่"
                onChange={_handleChangeCate}
              />
            </div>
          </>
        ) : (
          <div className="dropdown_wrap">
            <Inputs.InputNormalSelect
              theme_pink
              isInitial={initialValues}
              endPoint={herwillService.GET_CONTENT_CATEGORY}
              value={_cateSearch}
              placeholder="หมวดหมู่"
              onChange={_handleChangeCate}
            />
          </div>
        )}
      </form>
    </FilterVideoStyled>
  );
};

export default FilterVideo;
