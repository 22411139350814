import React from 'react';

export default function AaPlatform({ color = '#4E5B7E', width = "48", height = "48" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 48 48">
      <g id="Group_91915" data-name="Group 91915" transform="translate(0 0.297)">
        <g id="Group_91387" data-name="Group 91387" transform="translate(0 -0.297)">
          <g id="Group_91386" data-name="Group 91386" clip-path="url(#clip-path)">
            <path id="Path_108513" data-name="Path 108513" d="M10.834,31.025a.949.949,0,0,1,1.343,0l1.556,1.553,3.018-3.018A17.793,17.793,0,1,1,18.1,30.908l-3.017,3.017,1.554,1.552a.984.984,0,0,1,.276.676,1,1,0,0,1-.276.676L7,46.454a4.073,4.073,0,0,1-2.906,1.2,4.035,4.035,0,0,1-2.9-1.2,4.1,4.1,0,0,1,0-5.793ZM41.1,28.918A15.813,15.813,0,1,0,29.922,33.55,15.715,15.715,0,0,0,41.1,28.918" transform="translate(0.211 0.197)" fill="#b14359" />
          </g>
        </g>
        <g id="Group_91389" data-name="Group 91389" transform="translate(0 -0.297)">
          <g id="Group_91388" data-name="Group 91388" clip-path="url(#clip-path)">
            <path id="Path_108514" data-name="Path 108514" d="M85.651,47.774a5.846,5.846,0,1,1,5.841-5.85,5.85,5.85,0,0,1-5.841,5.85" transform="translate(-55.52 -30.502)" fill="#ed799e" />
            <path id="Path_108515" data-name="Path 108515" d="M66.241,116.66a.974.974,0,0,1,.743.352l2.268,2.849,2.278-2.849a.944.944,0,0,1,.743-.352,5.7,5.7,0,0,1,5.689,5.689v4.707a.95.95,0,0,1-.953.953H61.5a.955.955,0,0,1-.953-.953v-4.707a5.7,5.7,0,0,1,5.7-5.689" transform="translate(-39.121 -99.083)" fill="#ed799e" />
          </g>
        </g>
      </g>
    </svg>

  );
}
