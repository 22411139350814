import styled from 'styled-components';

export const VideoContainerStyled = styled.div`
  padding: 48px 21px 15px 40px;
  .filter_header_wrap {
    margin-bottom: 19px;
  }
  .row {
    display: flex;
    column-gap: 26px;
    .left_col {
      flex: 1;
      .player_wrapper {
        margin-bottom: 12px;
        position: relative;
        padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        .react_player {
          position: absolute;
          top: 0;
          left: 0;
        }
        .img_show {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: ${({ theme }) => theme.COLORS.PINK_5};
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .hr_row {
        // margin-bottom: 7px;
        &.mb {
          margin-bottom: 26px;
        }
        display: flex;
        justify-content: space-between;
        .hr_left_col {
        }
        .hr_right_col {
        }
      }
      .topic_label {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_30};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.BROWN_2};
      }
      .score_row {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        .s_left_col {
          display: flex;
          column-gap: 10px;
          .box_1 {
            margin-right: 60px;
          }
          .box_2 {
            padding-right: 16px;
            border-right: 1px solid #9A9A9A40;
            &.noline {
              border-right: none;
              padding-right: 0px;
            }
          }
        }
        .s_right_col {
          display: flex;
          column-gap: 16px;
        }
      }
      .pink_line {
        margin-top: 10px;
        margin-bottom: 12px;
        width: 100%;
        height: 3px;
        background: ${({ theme }) => theme.COLORS.PINK_4};
      }
      .desciption {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
        color: ${({ theme }) => theme.COLORS.GRAY_17};
      }
      .moblie_layout_show {
        display: none;
        .pink_line_mobile {
          display: none;
        }
      }
    }
    .right_col {
      flex-shrink: 0;
      width: 387px;
      .label_destop {
        margin-bottom: 20px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
        color: ${({ theme }) => theme.COLORS.GRAY_19};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .row {
     
      .left_col {
        .pink_line {
          display: none;
        }
        .hr_row {
          &.mb {
            margin-bottom: 20px;
          }
        }
        .topic_label {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
        }
        .desciption {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        }
        
        .score_row {
          .s_left_col {
            .box_1 {
              
            }
            .box_2 {
              
            }
          }
        }
      }
      .right_col {
        max-width: 310px;
        .label_destop {
          
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    padding: 12px;
    .row {
      flex-direction: column;
      .left_col {
        .pink_line {
          display: none;
        }
        .hr_row {
          &.mb {
            margin-bottom: 9px;
          }
        }
        .topic_label {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
        }
        .desciption {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        }
        .moblie_layout_show {
          display: block;
          margin: 12px 0px;
          .pink_line_mobile {
            display: block;
            width: 100%;
            height: 3px;
            background: ${({ theme }) => theme.COLORS.PINK_4};
          }
          .label_moblie {
            margin-top: 12px;
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
            color: ${({ theme }) => theme.COLORS.GRAY_19};
          }
        }
        .score_row {
          .s_left_col {
            .box_1 {
              margin-right: 30px;
            }
            .box_2 {
              padding-right: 10px;
            }
          }
        }
      }
      .right_col {
        width: 100%;
        .label_destop {
          display: none;
        }
      }
    }
  }
`;
