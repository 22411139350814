import styled from 'styled-components';

export const NavbarPinkAlertWidgetStyled = styled.div`
  -webkit-box-shadow: 2px 2px 6px 2px rgba(86, 65, 71, 0.28);
  box-shadow: 2px 2px 6px 2px rgba(86, 65, 71, 0.28);
  padding-left: 28px;
  padding-right: 64px;
  height: 112px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  .nav_pink_left_col {
    .nav_p_logo {
      width: 190px;
      height: auto;
    }
  }
  .nav_pink_right_col {
    padding-top: 12px;
    display: flex;
    align-items: center;
    column-gap: 84px;
    .nav_p_right_col {
      .nav_menu_item {
        border-bottom: 3px solid ${({ theme }) => theme.COLORS.WHITE_1};
        padding: 3px 10px 0px 10px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        color: ${({ theme }) => theme.COLORS.GRAY_20};
        cursor: pointer;
        &.active {
          border-color: ${({ theme }) => theme.COLORS.GREEN_4};
          color: ${({ theme }) => theme.COLORS.GREEN_4};
        }
      }
      .nav_serarch_container {
        position: relative;
        .nav_search {
          border-radius: 8px;
          border: 1px solid ${({ theme }) => theme.COLORS.PINK_8};
          padding-left: 20px;
          padding-right: 48px;
          height: 40px;
          min-width: 312px;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGUALR};
          color: ${({ theme }) => theme.COLORS.GRAY_20};
        }
        .nav_search_icon_wrap {
          position: absolute;
          top: 12px;
          right: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .nav_hamburger_right_col {
    display: none;
    padding-top: 12px;
    .nav_h_hamburger {
      cursor: pointer;
      margin-left: 20px;
    }
    .nav_h_search {
      cursor: pointer;
      &.hide {
        display: none;
      }
    }
    .mobile_serarch_container {
      position: relative;
      display: none;
      &.show {
        display: flex;
        justify-content: flex-end;
      }
      .mobile_search {
        border-radius: 8px;
        border: 1px solid ${({ theme }) => theme.COLORS.PINK_8};
        padding-left: 20px;
        padding-right: 48px;
        height: 48px;
        min-width: 312px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGUALR};
        color: ${({ theme }) => theme.COLORS.GRAY_20};
        background: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      .mobile_search_icon_wrap {
        position: absolute;
        top: 15px;
        right: 20px;
      }
    }
    .drawer_container {
      .dw_header_row {
        margin-bottom: 10px;
        -webkit-box-shadow: 2px 2px 6px 2px rgba(86, 65, 71, 0.28);
        box-shadow: 2px 2px 6px 2px rgba(86, 65, 71, 0.28);
        padding: 8px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .dwh_left_col {
          .dwh_logo {
            width:120px;
            height: auto;
          }
        }
        .dwh_right_col {
          padding-top: 8px;
          display: flex;
          align-items: center;
          column-gap: 23px;
          .hr_search {
            display: block;
            &.hide {
              display: none;
            }
          }
          .dwh_mobile_serarch_container {
            position: relative;
            display: none;
            &.show {
              display: flex;
              justify-content: flex-end;
            }
            .dwh_mobile_search {
              border-radius: 8px;
              border: 1px solid ${({ theme }) => theme.COLORS.PINK_8};
              padding-left: 20px;
              padding-right: 48px;
              height: 48px;
              min-width: 312px;
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGUALR};
              color: ${({ theme }) => theme.COLORS.GRAY_20};
              background: ${({ theme }) => theme.COLORS.WHITE_1};
            }
            .dwh_mobile_search_icon_wrap {
              position: absolute;
              top: 15px;
              right: 20px;
            }
          }
        }
      }
      .dw_body {
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 244, 249, 1) 100%
        );
        height: 100vh;
        position: relative;
        .body_menu {
          padding: 30px 16px;
          padding-top: 10px;
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          .menu_box {
            border-bottom: 1.5px solid #bea5ab80;
            padding: 0px 15px 20px 15px;
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGUALR};
            color: ${({ theme }) => theme.COLORS.GRAY_20};
            cursor: pointer;
            &.active {
              color: ${({ theme }) => theme.COLORS.GREEN_4};
            }
          }
        }
        .img_bottom {
          background-image: url(/assets/images/backgrounds/bg_menu_bottom.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
          height: 41%;
          position: absolute;
          bottom: 1%;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MEDIUM_DESKTOP}) {
    padding-left: 18px;
    padding-right: 34px;
    height: 102px;
    .nav_pink_left_col {
      .nav_p_logo {
        width: 150px;
        height: auto;
      }
    }
    .nav_pink_right_col {
      column-gap: 20px;
      .nav_p_right_col {
        .nav_menu_item {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
        }
        .nav_serarch_container {
          .nav_search {
            min-width: 212px;
            background:  ${({ theme }) => theme.COLORS.WHITE_1};
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    padding: 0px 16px;
    .nav_pink_left_col {
      .nav_p_logo {
        width: 140px;
      }
    }
    .nav_pink_right_col {
      display: none;
    }
    .nav_hamburger_right_col {
      display: flex;
      align-items: center;
      .drawer_container {
        .dw_body {
          .img_bottom {
            bottom: 1%;
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    padding: 0px 16px;
    height: 80px;
    .nav_pink_left_col {
      .nav_p_logo {
        width: 120px;
        height: auto;
      }
    }
    .nav_pink_right_col {
      display: none;
    }
    .nav_hamburger_right_col {
      display: flex;
      .mobile_serarch_container {
        .mobile_search {
          border-radius: 8px;
          border: 1px solid ${({ theme }) => theme.COLORS.PINK_8};
          padding: 0px 20px;
          height: 36px;
          min-width: 10px;
          width: 88%;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGUALR};
          color: ${({ theme }) => theme.COLORS.GRAY_20};
        }
        .mobile_search_icon_wrap {
          position: absolute;
          top: 10px;
          right: 8px;
        }
      } 
      .drawer_container {
        .dw_header_row {
          margin-bottom: 20px;
          .dwh_right_col {
            .dwh_mobile_serarch_container {
              .dwh_mobile_search {
                border-radius: 8px;
                border: 1px solid ${({ theme }) => theme.COLORS.PINK_8};
                padding: 0px 20px;
                padding-right: 30px;
                height: 36px;
                min-width: 10px;
                width: 88%;
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
                font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGUALR};
                color: ${({ theme }) => theme.COLORS.GRAY_20};
              }
              .dwh_mobile_search_icon_wrap {
                position: absolute;
                top: 10px;
                right: 8px;
              }
            }
          }
        }
        .dw_body {
          .img_bottom {
            bottom: 6%;
          }
        }
      }
    }
  }
`;
