import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { AutoSignupContainerStyled } from './styled';
import { userService } from 'apiService';
import { setReduxUserAuth } from 'store/actions';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { withRouter } from 'navigations/withRouter';
import { Displays } from 'components';

class AutoSignupContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.handleAutoRegister();
  }

  handleAutoRegister = async () => {
    let params = {
      email: this.makeid(16) + '@herwill.invitrace.app',
      password: this.makeid(8),
      provider: 'local',
    };
    let res = await userService.POST_SING_UP(params);
    if (res && res.success) {
      this.handleAutoLogin(params.email, params.password);
    }
  };

  handleAutoLogin = async (em, pw) => {
    let params = {
      email: em,
      password: pw,
    };
    let res = await userService.POST_LOGIN(params);
    if (res && res.success) {
      sessionStorage.setItem(
        'tokenList',
        JSON.stringify({ token: res.token, isRemember: false })
      );
      this.fetchProfileData('email');
    }
  };

  fetchProfileData = async (loginWith) => {
    let res = await userService.GET_PROFILE_DETAIL();
    if (res && res.success) {
      this.setState({
        initialValues: { ...res.data, ...res.data.profile },
      });
      let params = {
        ...res.data,
        ...res.data.profile,
        loginWith: loginWith,
      };
      delete params.profile;
      this.props.setReduxUserAuth({ ...params });
      this.handleCheckProfile();
    }
  };

  handleCheckProfile = () => {
    const { authRedux } = this.props;
    if (authRedux.id && authRedux.firstname) {
      // Set userId for existing users
      ReactGA.set({
        userId: authRedux.id,
      });
      this.props.router.navigate(this.props.configProjectRedux.home_uri, { replace: true });
    } else {
      this.props.router.navigate(ROUTE_PATH.TERM_OF_USE, { replace: true });
    }
  };

  makeid = (length) => {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  render() {
    return (
      <AutoSignupContainerStyled>
        <Displays.Loading />
      </AutoSignupContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.authenRedux,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AutoSignupContainer));
