import React from 'react';
import { PinkAlertMainLayoutStyled } from './styled';
import { Outlet } from 'react-router-dom';

class PinkAlertMainLayout extends React.Component {
  render() {
    return (
      <PinkAlertMainLayoutStyled>
        <Outlet />
      </PinkAlertMainLayoutStyled>
    );
  }
}

export default PinkAlertMainLayout;
