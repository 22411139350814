import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { PreviewVideoStyled } from './styled';
import { Icons } from 'components';
// import { FiEye } from 'react-icons/fi';
// import { AiOutlineLike } from 'react-icons/ai';
// import { RiShareForwardLine } from 'react-icons/ri';
import { RiShareForwardFill } from 'react-icons/ri';
import { AiFillLike, AiFillEye } from 'react-icons/ai';

const PreviewVideo = ({
  theme_standard,
  theme_home,
  src,
  topic,
  viewCount,
  likeCount,
  shareCount,
  isView,
  isLike,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_home: theme_home,
  });
  return (
    <PreviewVideoStyled src={src}>
      <div className={customClass}>
        <div className="preview_video_container">
          <div className="pv_figture">
            <div className="pv_play_wrap">
              <Icons.Play />
            </div>
          </div>
          <div className="pv_topic">{topic}</div>
          <div className="pv_total_row">
            <div className="score_block">
              <div className="view_big">
                <AiFillEye color={isView ? '#B14359' : '#bebebe'} fontSize={26} />
              </div>
              <div className="view_small">
                <AiFillEye color={isView ? '#B14359' : '#bebebe'} fontSize={26} />
              </div>
              <div className="pv_score">{viewCount}</div>
            </div>
            <div className="pv_line_between" />
            <div className="score_block">
              <div className="view_big">
                <AiFillLike color={isLike ? '#B14359' : '#bebebe'} fontSize={24} />
              </div>
              <div className="view_small">
                <AiFillLike color={isLike ? '#B14359' : '#bebebe'} fontSize={24} />
              </div>
              <div className="pv_score">{likeCount}</div>
            </div>
            <div className="pv_line_between" />
            <div className="score_block">
              <div className="view_big">
                <RiShareForwardFill color="#bebebe" fontSize={32} />
              </div>
              <div className="view_small">
                <RiShareForwardFill color="#bebebe" fontSize={32} />
              </div>
              <div className="pv_score">{shareCount}</div>
            </div>
          </div>
          <div className="pv_total_row mobile">
            <div className="score_block">
              <div className="view_big">
                <AiFillEye color={isView ? '#B14359' : '#bebebe'} fontSize={12} />
              </div>
              <div className="view_small">
                <AiFillEye color={isView ? '#B14359' : '#bebebe'} fontSize={12} />
              </div>
              <div className="pv_score">{viewCount}</div>
            </div>
            <div className="pv_line_between" />
            <div className="score_block">
              <div className="view_big">
                <AiFillLike color={isLike ? '#B14359' : '#bebebe'} fontSize={12} />
              </div>
              <div className="view_small">
                <AiFillLike color={isLike ? '#B14359' : '#bebebe'} fontSize={12} />
              </div>
              <div className="pv_score">{likeCount}</div>
            </div>
            <div className="pv_line_between" />
            <div className="score_block">
              <div className="view_big">
                <RiShareForwardFill color="#bebebe" fontSize={14} />
              </div>
              <div className="view_small">
                <RiShareForwardFill color="#bebebe" fontSize={14} />
              </div>
              <div className="pv_score">{shareCount}</div>
            </div>
          </div>
        </div>
      </div>
    </PreviewVideoStyled>
  );
};

PreviewVideo.propTypes = {};

export default PreviewVideo;
