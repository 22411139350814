import styled from 'styled-components';

export const Banner2WidgetStyled = styled.div`
  position: relative;
  background-image: url(/assets/images/backgrounds/banner2-bg.jpg);
  background-color: #cccccc;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 112px);
  display: flex;
  align-items: center;
  .banner2_container {
    margin-left: auto;
    margin-rigth: 0;
    max-width: 74.5vw;
    width: 100%;
    padding: 0 20px;
    .b2_title {
      margin-bottom: 10px;
      text-align: center;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_63};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
      color: ${({ theme }) => theme.COLORS.PINK_8};
      .b2_t_black {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_46};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
    }
    .b2_sub_title {
      margin-bottom: 42px;
      text-align: center;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_38};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
    }
    .b_statistic_block {
      margin: auto;
      margin-bottom: 15px;
      max-width: 432px;
      .percent_row {
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        &.mb_0 {
          margin-bottom: 0px;
        }
        .p_label {
          flex-shrink: 0;
          flex-basis: 85px;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          color: ${({ theme }) => theme.COLORS.BROWN_1};
        }
      }
      .text_det {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        text-align: right;
        margin-top: 20px;
      }
    }
    .b2_btn_block {
      margin-bottom: 39px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .icon_click_wrap {
        margin-bottom: -34px;
        .click_icon {
          width: 56px;
          height: 56px;
        }
      }
    }
    .b2_hastag {
      text-align: center;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_36};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
    }
    .label_mth_2 {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
      color: ${({ theme }) => theme.COLORS.GRAY_20};
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MEDIUM_DESKTOP}) {
    .banner2_container {
      .b2_title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_59};
        .b2_t_black {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_42};
        }
      }
      .b2_sub_title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_34};
      }
      .b2_hastag {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_33};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    background-image: url(/assets/images/backgrounds/banner2-bg-mobile.jpg);
    height: calc(100vh - 80px);
    min-height: unset;
    .banner2_container {
      max-width: 68.5vw;
      height: 100%;
      padding: 20px 20px 0px 20px;
      position: relative;
      .b2_title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_39};
        .b2_t_black {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
        }
      }
      .b2_sub_title {
        margin-bottom: 24px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
      }
      .b_statistic_block {
        margin-bottom: 0px;
        .percent_row {
          margin-bottom: 10px;
          .p_label {
            flex-basis: 54px;
          }
        }
      }
      .b2_btn_block {
        position: absolute;
        left: -27px;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 20%;
        margin-bottom: 0px;
      }
      .b2_hastag {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: 8%;
        margin-bottom: 0px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_30};
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    height: 434px;
    // height: calc(100vh - 200px);
    min-height: unset;
    .banner2_container {
      padding: 30px 20px 0px 20px;
      .b2_title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
        .b2_t_black {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        }
      }
      .b2_sub_title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      }
      .b_statistic_block {
        .percent_row {
          .p_label {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_8};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          }
        }
        .text_det {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
          text-align: right;
          margin-top: 10px;
        }
      }
      .b2_btn_block {
        bottom: 13.5%;
        .icon_click_wrap {
          margin-bottom: -14px;
          .click_icon {
            width: 24px;
            height: 24px;
          }
        }
      }
      .b2_hastag {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      }
      .label_mth_2 {
        display: block;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_7};
        position: absolute;
        right: 10px;
        bottom: 8px;
      }
    }
  }
`;
