import { HerwillCampaignWidgetStyled } from './styled';
import { Icons } from 'components';

const HerwillCampaignWidget = () => {
  return (
    <HerwillCampaignWidgetStyled>
      <div className="hc_container"> 
        <div className="hc_left_col">
          <img
            className="hc_logo"
            alt="herwill logo"
            src="/assets/images/icons/logo_HerWill.svg"
          />
        </div>
        <div className="hc_right_col">
          <div className="hcr_title">
            HER Will แคมเปญมีเป้าหมายและความตั้งใจหลัก
          </div>
          <div className="hcr_row">
            <div className="hcr_circle">
              <Icons.Campaign1 />
            </div>
            <div className="hcr_circle_mobile">
              <Icons.Campaign1  width='20' height='20'/>
            </div>
            <div className="hcr_description">
              รณรงค์ให้ผู้หญิงตรวจคัดกรอง และตรวจหามะเร็งในระยะเริ่มต้น
            </div>
          </div>
          <div className="hcr_row">
            <div className="hcr_circle">
              <Icons.Campaign2 />
            </div>
            <div className="hcr_circle_mobile">
              <Icons.Campaign2  width='20' height='20'/>
            </div>
            <div className="hcr_description">
              สร้างความเข้าใจและให้มุมมอง ว่า “มะเร็งเต้านมยิ่งตรวจพบเร็ว
              ยิ่งมีโอกาสรักษาหายขาดมากขึ้น”
            </div>
          </div>
          <div className="hcr_row">
            <div className="hcr_circle">
              <Icons.Campaign3 />
            </div>
            <div className="hcr_circle_mobile">
              <Icons.Campaign3  width='20' height='20'/>
            </div>
            <div className="hcr_description">
              ให้ความรู้ การรักษามะเร็งเต้านม โดยเฉพาะชนิดที่มียีนส์เฮอร์ทูเป็นบวก (HER2-positive) รวมถึงข้อมูล การรับมือ ดูแลรักษาแก่ผู้ป่วยและญาติ
            </div>
          </div>
        </div>
      </div>
    </HerwillCampaignWidgetStyled>
  );
};

export default HerwillCampaignWidget;
