import React from 'react';
import { ShowLabelStyled } from './styled';
import cx from 'classnames';

const ShowLabel = ({
  handleClick,
  theme_red,
  theme_blue,
  theme_gray,
  theme_white,
  theme_gray2,
  label,
  fontSize,
  tabindex,
  noPadding,
}) => {
  const customClass = cx({
    theme_red: theme_red,
    theme_blue: theme_blue,
    theme_gray: theme_gray,
    theme_white: theme_white,
    theme_gray2: theme_gray2,
  });
  return (
    <ShowLabelStyled fontSize={fontSize} noPadding={noPadding}>
      {tabindex ? (
        <div className={customClass} tabindex={tabindex}>
          {label}
        </div>
      ) : (
        <div className={customClass} onClick={handleClick}>
          {label}
        </div>
      )}
    </ShowLabelStyled>
  );
};

export default ShowLabel;
