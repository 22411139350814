import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { DropdownLibStyled } from './styled';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Icons } from 'components';

const DropdownLib = ({
  theme_standard,
  options,
  value,
  onChange,
  placeholder,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <DropdownLibStyled>
      <div className={customClass}>
        <Dropdown
          controlClassName={'d_control'}
          options={options}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          arrowClosed={
            <div className="d_arrow">
              <Icons.ArrowVDown />
            </div>
          }
          arrowOpen={
            <div className="d_arrow">
              <Icons.ArrowVUp />
            </div>
          }
        />
      </div>
    </DropdownLibStyled>
  );
};

DropdownLib.propTypes = {};

export default DropdownLib;
