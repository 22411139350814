import { useEffect, useState } from 'react';
import { TelemedicineWidgetStyled } from './styled';
import AnimatedNumber from 'animated-number-react';
import { RenderCommaNumber } from 'utils/functions';
import { InView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { userService } from 'apiService';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Icons } from 'components';

const TelemedicineWidget = () => {
  const { configProjectRedux } = useSelector((state) => state);
  const navigate = useNavigate();

  const [_count, _setCount] = useState(0);

  useEffect(() => {
    _fetchData();
  }, []);

  const _fetchData = async () => {
    let res = await userService.GET_HERWILL_LANDING_COUNT();
    if (res && res.success) {
      _setCount(res.data);
    }
  };

  const _handleClickConsultDocotr = () => {
    ReactGA.event({
      category: configProjectRedux.scheme + '_fromLanding',
      action: 'click_goIteleList',
    });
    navigate(ROUTE_PATH.CONSULT_DOCTOR);
  };

  const _handleClickPartner = (key) => {
    switch (key) {
      case 'fb_tbcc':
        ReactGA.event({
          category: 'click_partner',
          action: 'click_join_fb_tbcc',
        });
        window.open('https://www.facebook.com/tbcccommunity', '_blank');

        break;

      case 'fb_pink':
        ReactGA.event({
          category: 'click_partner',
          action: 'click_join_fb_pink_alert',
        });
        window.open('https://www.facebook.com/PinkAlertProject', '_blank');

        break;


      case 'line_tbcc':
        ReactGA.event({
          category: 'click_partner',
          action: 'click_join_line_tbcc',
        });
        window.open('https://lin.ee/1HzRzAq', '_blank');

        break;

      default:
        break;
    }
  };

  const _handleClickToBookAppointment = () => {
    ReactGA.event({
      category: configProjectRedux.scheme + '_fromLanding',
      action: 'click_goCancerHospital',
    });
    navigate(ROUTE_PATH.PINK_HOSPITAL_CHOOSE);
  };

  const _handleClickHealth = () => {
    ReactGA.event({
      category: configProjectRedux.scheme + '_fromLanding',
      action: 'click_healthInsurancer',
    });
    navigate(ROUTE_PATH.HEALTH_INSURANCER);
  };

  const _handleClickMemmogram = () => {
    ReactGA.event({
      category: configProjectRedux.scheme + '_fromLanding',
      action: 'click_goMammogram',
    });
    navigate(ROUTE_PATH.LOCATION_NEAR);
  };

  return (
    <TelemedicineWidgetStyled>
      <div className="telemed_row">
        <div className="t_left_col">
          <div
            className="img_show"
            style={{
              backgroundImage: `url('/assets/images/backgrounds/itelemedicine-bg.jpg')`,
            }}
          >
            <img
              className="img_itele"
              src="/assets/images/icons/itele_2.svg"
              alt="icon show"
            />
            <div className="label_top_show">
              <div className="group_text">
                <div className="text1">Telemedicine</div>
                <div className="text1 fs_sub_title">
                  ปรึกษาแพทย์ได้ทุกที่ทุกเวลา
                </div>
                <div className="text2">ด้วยระบบวิดีโอคอล</div>
              </div>
            </div>
            <div className="box_btn">
              <div className="btn_itele" onClick={_handleClickConsultDocotr}>
                <div className="icon_btn">
                  <Icons.Camera />
                </div>
                <div className="icon_btn_mobile">
                  <Icons.Camera width={11} height={6.7} />
                </div>
                ปรึกษาแพทย์ผ่าน VDO Call
              </div>
            </div>
          </div>
          <div className="service_box">
            <div className="service_title">บริการอื่นๆ</div>
            <div className="service_row">
              <div
                className="box_service_detail"
                onClick={_handleClickMemmogram}
              >
                <div className="box_wrap">
                  <img
                    className="box_image"
                    src="/assets/images/icons/case.svg"
                    alt="icon show"
                  />
                </div>
                <div className="box_label">
                  <div className="button_show">ข้อมูลศูนย์ตรวจ</div>
                  <div className="text_row">
                    <div className="text_show">เช็คศูนย์ตรวจ</div>
                    <div className="text_show">แมมโมแกรมใกล้คุณ</div>
                  </div>
                  <div className="text_row_mobile">
                    <span className="text_show">เช็คศูนย์ตรวจแมมโมแกรมใกล้คุณ</span>
                  </div>
                </div>
              </div>
              <div
                className="box_service_detail"
                onClick={_handleClickToBookAppointment}
              >
                <div className="box_wrap">
                  <img
                    className="box_image"
                    src="/assets/images/icons/nurse.svg"
                    alt="icon show"
                  />
                </div>
                <div className="box_label">
                  <div className="button_show">จองคิวตรวจมะเร็ง</div>
                  <div className="text_row">
                    <div className="text_show">แพคเกจพิเศษตรวจเต้านมกับ</div>
                    <div className="text_show">โรงพยาบาลพันธมิตร</div>
                  </div>
                  <div className="text_row_mobile wrap">
                    <span className="text_show">แพคเกจพิเศษตรวจเต้านมกับ</span>
                    <span className="text_show">โรงพยาบาลพันธมิตร</span>
                  </div>
                </div>
              </div>
              <div className="box_service_detail" onClick={_handleClickHealth}>
                <div className="box_wrap">
                  <img
                    className="box_image"
                    src="/assets/images/icons/asure.svg"
                    alt="icon show"
                  />
                </div>
                <div className="box_label">
                  <div className="button_show">ประกันสุขภาพ</div>
                  <div className="text_row">
                    <div className="text_show">วางแผนการเงิน</div>
                    <div className="text_show">เพื่อสุขภาพของคุณ</div>
                  </div>
                  <div className="text_row_mobile">
                    <span className="text_show">วางแผนการเงินเพื่อสุขภาพของคุณ</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="t_right_col">
          <div className="social_block">
            <div className="sb_title">รู้จักกลุ่มผู้ป่วยมะเร็งเต้านม TBCC</div>
            <div className="sb_row_2_col">
              <div className="sb_item">
                <div className="icon_wrap">
                  <Icons.SocialFB />
                </div>
                <div className="sb_content">
                  <div
                    className="btn_social"
                    onClick={() => _handleClickPartner('fb_tbcc')}
                  >
                    เข้าเฟสบุ๊คเพจ
                  </div>
                  <div className="lb_social">
                    <div>
                      ชมรมผู้ป่วยมะเร็งเต้านม
                    </div>
                    <div>
                      แห่งประเทศไทย
                    </div>
                  </div>
                </div>
              </div>
              <div className="sb_item">
                <div className="icon_wrap">
                  <Icons.SocialFB />
                </div>
                <div className="sb_content">
                  <div
                    className="btn_social"
                    onClick={() => _handleClickPartner('fb_pink')}
                  >
                    เข้าเฟสบุ๊คเพจ
                  </div>
                  <div className="lb_social">
                    <div>
                      Herwill
                    </div>
                    <div>
                      Pink Alert Project
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="sb_item">
                <div className="icon_wrap">
                  <Icons.SocialLine />
                </div>
                <div className="sb_content">
                  <div
                    className="btn_social"
                    onClick={() => _handleClickPartner('line_tbcc')}
                  >
                    แอด Line@
                  </div>
                  <div className="lb_social">
                    <div>
                      ชมรมผู้ป่วยมะเร็งเต้านม
                    </div>
                    <div>
                      แห่งประเทศไทย
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="record_travel_block">
            <div className="group_label">
              <div className="label_show h1">จุดบันทึกแห่งการเดินทาง</div>
              <div className="label_show regular">มีคนร่วมแคมเปญแล้ว</div>
              <div className="label_show h2">
                <InView triggerOnce={true}>
                  {({ inView, ref, entry }) => (
                    <div ref={ref}>
                      {inView && (
                        <AnimatedNumber
                          value={_count}
                          formatValue={(count) =>
                            RenderCommaNumber(parseInt(count))
                          }
                          duration={1000}
                        />
                      )}
                    </div>
                  )}
                </InView>
              </div>
            </div>
            <div className="sub_detail">
              <div className="group_sub">
                <span className="h1">เพื่อโลก...</span>
                <span>ที่มีผู้ป่วยมะเร็งเต้านมลดลง</span>
              </div>
              <div className="group_sub">
                <span className="h1">เพื่อโลก...</span>
                <span>ที่ผู้ป่วยมะเร็งเต้านมสามารถหายขาดได้</span>
              </div>
              <div className="group_sub">
                <span className="h1">เพื่อโลก...</span>
                <span>ที่ผู้ป่วยมะเร็งเต้านมยังสวย และมั่นใจได้</span>
              </div>
              <div className="group_sub">
                <span>เพราะไม่จำเป็นต้องตัดเต้านมออก</span>
              </div>
              <div className="group_sub">
                <span className="h1">และเพื่อโลก...</span>
                <span>ที่มะเร็งเต้านมไม่ใช่เรื่องน่ากลัวอีกต่อไป</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TelemedicineWidgetStyled>
  );
};

export default TelemedicineWidget;
