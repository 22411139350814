import React from 'react';

export default function AaPlatform({
  width = "33",
  height = "33"
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 33 33"
    >
      <g
        id="Group_94657"
        data-name="Group 94657"
        transform="translate(-1385 -1920)"
      >
        <circle
          id="Ellipse_6142"
          data-name="Ellipse 6142"
          cx="16.5"
          cy="16.5"
          r="16.5"
          transform="translate(1385 1920)"
          fill="#b14359"
        />
        <path
          id="Union_378"
          data-name="Union 378"
          d="M11070.258,22424.531l-5.5-3.471a1.466,1.466,0,0,1-.683-1.24v-8.324a1.464,1.464,0,0,1,1.466-1.465h10.99a1.464,1.464,0,0,1,1.465,1.465v8.324a1.466,1.466,0,0,1-.683,1.24l-5.5,3.471a1.464,1.464,0,0,1-1.564,0Zm1.212-5.072.833.439a.923.923,0,0,0,1.34-.973l-.158-.928a.926.926,0,0,1,.266-.818l.673-.656a.922.922,0,0,0-.511-1.574l-.934-.137a.919.919,0,0,1-.694-.5l-.416-.844a.923.923,0,0,0-1.656,0l-.417.844a.917.917,0,0,1-.694.5l-.933.137a.923.923,0,0,0-.512,1.574l.676.656a.925.925,0,0,1,.264.818l-.158.928a.924.924,0,0,0,1.34.973l.833-.439a.944.944,0,0,1,.859,0Zm-5.925-10.527a1.466,1.466,0,1,1,0-2.932h10.99a1.466,1.466,0,0,1,1.424,1.121v.689a1.466,1.466,0,0,1-1.424,1.121Z"
          transform="translate(-9669.539 -20478.879)"
          fill="#fff"
          stroke="rgba(0,0,0,0)"
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
    </svg>
  );
}
