import { PartnerWidgetStyled } from './styled';

const PartnerWidget = ({ smallMenu, showLabel, noBg }) => {
  return (
    <PartnerWidgetStyled noBg={noBg}>
      {!smallMenu ? (
        <div className="group_normal">
          {
            showLabel &&
            <div className="band_label">
              พันธมิตรที่เข้าร่วมโครงการ
              <div className="pw_title_line" />
            </div>
          }
          <div className='large_img'>
            <div className="top_img">
              {/* <img
                className="band_imb tbs"
                src="/assets/images/icons/tbs_logo.svg"
                alt="icon show"
              />
              <img
                className="band_imb tbcc"
                src="/assets/images/icons/tbcc_logo.svg"
                alt="icon show"
              />
              <img
                className="band_imb"
                src="/assets/images/icons/roche_logo.svg"
                alt="icon show"
              />
              <img
                className="band_imb"
                src="/assets/images/icons/well_logo_svg.svg"
                alt="icon show"
              />
              <img
                className="band_imb"
                src="/assets/images/icons/loreal-logo.svg"
                alt="icon show"
              />
            </div>
            <div className="top_img mt4">
              <img
                className="band_imb"
                src="/assets/images/icons/pyt_lgo.svg"
                alt="icon show"
              />
              <img
                className="band_imb"
                src="/assets/images/icons/paolo_logo.svg"
                alt="icon show"
              />
              <img
                className="band_imb"
                src="/assets/images/icons/praram9-logo.svg"
                alt="icon show"
              />
              <img
                className="band_imb mana"
                src="/assets/images/icons/mana-logo.svg"
                alt="icon show"
              />
              <img
                className="band_imb jura"
                src="/assets/images/icons/jura-logo.svg"
                alt="icon show"
              /> */}

              <img
                className="band_imb_new her_will"
                src="/assets/images/icons/logo_HerWill.png"
                alt="icon show"
              />
              <img
                className="band_imb_new loreal"
                src="/assets/images/icons/loreal.png"
                alt="icon show"
              />
              <img
                className="band_imb_new mercure_rayong"
                src="/assets/images/icons/mercure_rayong.png"
                alt="icon show"
              />
              <img
                className="band_imb_new pullman"
                src="/assets/images/icons/pullman.png"
                alt="icon show"
              />
            </div>
            <div className="top_img s_gap">
              <img
                className="band_imb_new pyt1"
                src="/assets/images/icons/PYT1.png"
                alt="icon show"
              />
              <img
                className="band_imb_new PYT3"
                src="/assets/images/icons/PYT3.jpg"
                alt="icon show"
              />
              <img
                className="band_imb_new PTY_nawa"
                src="/assets/images/icons/PTY_nawa.jpg"
                alt="icon show"
              />
              <img
                className="band_imb_new pyt_sri"
                src="/assets/images/icons/pyt_sri.png"
                alt="icon show"
              />
            </div>
            <div className="top_img">
              <img
                className="band_imb_new praram9"
                src="/assets/images/icons/praram9.png"
                alt="icon show"
              />
              <img
                className="band_imb_new chula3"
                src="/assets/images/icons/chula3.png"
                alt="icon show"
              />
              <img
                className="band_imb_new Paolo"
                src="/assets/images/icons/Paolo.png"
                alt="icon show"
              />
              <img
                className="band_imb_new novo"
                src="/assets/images/icons/novo.jpg"
                alt="icon show"
              />
            </div>
          </div>
          {/* ************************************************************ */}
          <div className='small_img'>
            <div className="small_top_img">
              <img
                className="sm_band_imb_new her_will"
                src="/assets/images/icons/logo_HerWill.png"
                alt="icon show"
              />
              <img
                className="sm_band_imb_new loreal"
                src="/assets/images/icons/loreal.png"
                alt="icon show"
              />
            </div>
            <div className='small_img'>
              <div className="small_top_img">
                <img
                  className="sm_band_imb_new mercure_rayong"
                  src="/assets/images/icons/mercure_rayong.png"
                  alt="icon show"
                />
                <img
                  className="sm_band_imb_new pullman"
                  src="/assets/images/icons/pullman.png"
                  alt="icon show"
                />
              </div>
            </div>
            <div className='small_img'>
              <div className="small_top_img">
                <img
                  className="sm_band_imb_new pyt1"
                  src="/assets/images/icons/PYT1.png"
                  alt="icon show"
                />
                <img
                  className="sm_band_imb_new PYT3"
                  src="/assets/images/icons/PYT3.jpg"
                  alt="icon show"
                />
              </div>
            </div>
            <div className='small_img'>
              <div className="small_top_img">
                <img
                  className="sm_band_imb_new PTY_nawa"
                  src="/assets/images/icons/PTY_nawa.jpg"
                  alt="icon show"
                />
                <img
                  className="sm_band_imb_new pyt_sri"
                  src="/assets/images/icons/pyt_sri.png"
                  alt="icon show"
                />
              </div>
            </div>
            <div className='small_img'>
              <div className="small_top_img">
                <img
                  className="sm_band_imb_new praram9"
                  src="/assets/images/icons/praram9.png"
                  alt="icon show"
                />
                <img
                  className="sm_band_imb_new chula3"
                  src="/assets/images/icons/chula3.png"
                  alt="icon show"
                />
              </div>
            </div>
            <div className='small_img'>
              <div className="small_top_img">
                <img
                  className="sm_band_imb_new Paolo"
                  src="/assets/images/icons/Paolo.png"
                  alt="icon show"
                />
                <img
                  className="sm_band_imb_new novo"
                  src="/assets/images/icons/novo.jpg"
                  alt="icon show"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="group_small">
          <img
            className="small_brand tbs"
            src="/assets/images/icons/tbs_logo.svg"
            alt="icon show"
          />
          <img
            className="small_brand tbcc"
            src="/assets/images/icons/tbcc_logo.svg"
            alt="icon show"
          />
          <img
            className="small_brand"
            src="/assets/images/icons/roche_logo.svg"
            alt="icon show"
          />
          <img
            className="small_brand"
            src="/assets/images/icons/well_logo_svg.svg"
            alt="icon show"
          />
          <img
            className="small_brand"
            src="/assets/images/icons/paolo_logo.svg"
            alt="icon show"
          />
          <img
            className="small_brand"
            src="/assets/images/icons/pyt_lgo.svg"
            alt="icon show"
          />
          <img
            className="small_brand"
            src="/assets/images/icons/loreal-logo.svg"
            alt="icon show"
          />
          <img
            className="small_brand"
            src="/assets/images/icons/praram9-logo.svg"
            alt="icon show"
          />
        </div>
      )}
    </PartnerWidgetStyled>
  );
};

export default PartnerWidget;
