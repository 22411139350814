import { Provider } from 'react-redux';
import { store, persistor } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from 'styles/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme.json';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navigations from 'navigations';
import { Helmet } from "react-helmet";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <GlobalStyle />
          <ThemeProvider theme={theme}>
            <Helmet>
                <title>Herwill | เพื่อเธอ เพื่อชีวิตที่ดีขึ้นของผู้ป่วยมะเร็ง</title>
                <meta name="og:title" content={`"Herwill | เพื่อเธอ เพื่อชีวิตที่ดีขึ้นของผู้ป่วยมะเร็ง"`} />
                <meta name="og:description" content={`"แด่ เธอ ผู้หญิงทุกคน
            วันนี้เราจะร่วมสู้มะเร็งเต้านมไปด้วยกัน คุณสามารถเข้าร่วมโครงการ Her Will
            ผ่านการตรวจความเสี่ยงตนเองฟรี ที่นี่ https://herwill.wellcancer.com
            และแชร์ข้อความนี้ต่อให้กับผู้หญิงรอบตัวท่านได้"`} />
                <meta name="og:hashtag" content={`"#Herwill"`} />
                <meta name="og:image" content={`"${process.env.REACT_APP_SELF_ENDPOINT}/herwill/herwill_logo_th.png"`} />
            </Helmet>
            <ToastContainer />
            <Navigations />
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
