import { useEffect, useState, useRef } from 'react';
import { TestShortcutWidgetStyled } from './styled';

const TestShortcutWidget = ({onClickSetQuck}) => {
  let scrollRef = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(
      scrollRef.current.getBoundingClientRect().bottom < 1150 ? true : false
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const _handleClickSetQuick = () => {
    onClickSetQuck()
  };

  return (
    <TestShortcutWidgetStyled ref={scrollRef} isBottom={scrollPosition}>
      <div className="box_notic" onClick={_handleClickSetQuick}>
        <div>ประเมินความเสี่ยง</div>
        <div>5 นาที</div>
      </div>
    </TestShortcutWidgetStyled>
  );
};

export default TestShortcutWidget;
