import React, { useState, useEffect } from 'react';
import { DropDownCustomStyled } from './styled';
import cx from 'classnames';

const DropDownCustom = ({
  theme_standard_dropdown,
  theme_normal_dropdown,
  them_pink,
  theme_herwill,
  theme_well,
  label,
  options,
  onChange,
  onChangeCustom,
  initialvalue,
  notDataNull,
  old_Value,
  showIcon,
  fontSize,
  input,
  labelShow,
  ...props
}) => {
  const customClass = cx({
    theme_standard_dropdown: theme_standard_dropdown,
    theme_normal_dropdown: theme_normal_dropdown,
    theme_herwill: theme_herwill,
    theme_well: theme_well,
    them_pink: them_pink,
  });
  const [_intialValue, setInitialValue] = useState();
  const [_value, setValue] = useState();

  useEffect(() => {
    if ((initialvalue && !_intialValue) || (input && input.value)) {
      setInitialValue(true);
      setValue(initialvalue || input.value);
    }
  }, [initialvalue, _intialValue, input]);

  const _onChange = (e) => {
    setValue(e.target.value);
    onChange && onChange(e.target.value);
    onChangeCustom && onChangeCustom(e.target.value);
    input && input.onChange(e.target.value);
  };
  return (
    <DropDownCustomStyled {...props}>
      <div className={customClass}>
        {showIcon && (
          <img src={'/assets/images/icons/calendar_blue2.png'} alt="calendar" />
        )}
        <select onChange={_onChange} value={_value}>
          {!old_Value ? (
            <option value={null}>{labelShow ? labelShow : 'กรุณาเลือก'}</option>
          ) : (
            <option value={old_Value.key}>{old_Value.name}</option>
          )}
          {options &&
            options.map((e, i) => (
              <option key={i} value={e.value}>
                {e.label}
              </option>
            ))}
        </select>
      </div>
    </DropDownCustomStyled>
  );
};

export default DropDownCustom;
