import React from 'react';
import { BodyQuardrantFormStyled } from './styled';
import { Displays, Buttons } from 'components';

class BodyQuardrantForm extends React.Component {
  state = {
    answer: [],
    loading: true,
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
  };

  handleClickButtonAns = (e) => {
    const { answer } = this.state;
    const { question, handleSubmitAns } = this.props;
    let arr_select = [...answer];
    if (question.is_multiple) {
      if (e.is_unknown) {
        arr_select = [{ id: e.id }];
      } else {
        if (
          arr_select.find((item) =>
            question.answers.find(
              (item2) => item2.is_unknown && item.id === item2.id
            )
          )
        ) {
          arr_select = [];
        }
        if (arr_select.find((item) => item.id === e.id)) {
          for (let i = 0; i < arr_select.length; i++) {
            if (arr_select[i].id === e.id) {
              arr_select.splice(i, 1);
            }
          }
        } else {
          arr_select = [...arr_select, { id: e.id }];
        }
      }
    } else {
      arr_select = [{ id: e.id }];
    }
    this.setState({
      answer: arr_select,
    });
    if (!question.is_multiple) {
      handleSubmitAns([{ id: e.id }]);
    }
  };

  handleSubmit = () => {
    const { answer } = this.state;
    const { handleSubmitAns } = this.props;
    handleSubmitAns(answer);
  };
  render() {
    const { answer, loading } = this.state;
    const { question, gender } = this.props;
    return (
      <BodyQuardrantFormStyled>
        {question && question.answers && (
          <>
            {loading ? (
              <Displays.LogoLoadingFade />
            ) : (
              <img
                className="img_logo"
                src={'/assets/images/icons/logo_question.png'}
                alt="logo well"
              />
            )}
          </>
        )}
        <div className="title">{question.label}</div>
        <div className="img_box">
          <img
            src={
              gender === 'male'
                ? '/assets/images/icons/Body4_male.png'
                : '/assets/images/icons/Body4_female.png'
            }
            alt="body"
          />
          <div
            className={
              answer.find((item) => item.id === question.answers[0].id)
                ? 'tag_left_top_active'
                : 'tag_left_top'
            }
            onClick={() => this.handleClickButtonAns(question.answers[0])}
          >
            <div
              className={
                answer.find((item) => item.id === question.answers[0].id)
                  ? ''
                  : 'btn_item'
              }
            >
              {question.answers[0].label}
            </div>
          </div>
          <div
            className={
              answer.find((item) => item.id === question.answers[1].id)
                ? 'tag_right_top_active'
                : 'tag_right_top'
            }
            onClick={() => this.handleClickButtonAns(question.answers[1])}
          >
            <div
              className={
                answer.find((item) => item.id === question.answers[1].id)
                  ? ''
                  : 'btn_item'
              }
            >
              {question.answers[1].label}
            </div>
          </div>
          <div
            className={
              answer.find((item) => item.id === question.answers[2].id)
                ? 'tag_left_bottom_active'
                : 'tag_left_bottom'
            }
            onClick={() => this.handleClickButtonAns(question.answers[2])}
          >
            <div
              className={
                answer.find((item) => item.id === question.answers[2].id)
                  ? ''
                  : 'btn_item'
              }
            >
              {question.answers[2].label}
            </div>
          </div>
          <div
            className={
              answer.find((item) => item.id === question.answers[3].id)
                ? 'tag_right_bottom_active'
                : 'tag_right_bottom'
            }
            onClick={() => this.handleClickButtonAns(question.answers[3])}
          >
            <div
              className={
                answer.find((item) => item.id === question.answers[3].id)
                  ? ''
                  : 'btn_item'
              }
            >
              {question.answers[3].label}
            </div>
          </div>
          <div className="box_choice">
            {question.answers.slice(4, question.answers.length).map((e, i) => (
              <div
                key={i}
                className={
                  answer.find((item) => item.id === e.id)
                    ? 'item_choice_active'
                    : 'item_choice'
                }
                onClick={() => this.handleClickButtonAns(e)}
              >
                {e.label}
              </div>
            ))}
          </div>
        </div>
        <div className="btn_bottom">
          {answer.length > 0 && (
            <Buttons.Button
              width="300px"
              height="45px"
              theme_standard
              style={{ marginTop: 30 }}
              handleClickButton={() => this.handleSubmit()}
            >
              ต่อไป
            </Buttons.Button>
          )}
        </div>
      </BodyQuardrantFormStyled>
    );
  }
}

export default BodyQuardrantForm;
