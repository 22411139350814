import styled from 'styled-components';

export const TermOfUseContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  background-color: #f8f8f8;
  min-height: 100vh;
  background-image: url('/assets/images/icons/dot_bg.png');
  background-repeat: repeat;
  background-repeat-y: no-repeat;
  background-position: top;
  overflow-y: auto;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .box_layout {
    padding-top: 50px;
    width: 450px;
    color: #131313;
    .title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    .sub_title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .content_container {
      padding: 1rem;
      border: 1px solid #9b9b9b;
      border-radius: 8px;
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 20px;
      .desciption {
        margin-bottom: 30px;
        font-weight: bold;
      }
      .title_question {
        font-weight: 700;
        text-decoration: underline;
        margin-bottom: 20px;
      }
      .question_container {
        .question_item {
          margin-bottom: 10px;
          font-weight: 700;
          .text_highlight {
            color: #3b96b7;
            font-weight: 700;
            &.herwill {
              color: ${({ theme }) => theme.COLORS.PINK_1};
            }
          }
        }
      }
      .text_mid {
        margin-top: 42px;
        color: ${({ theme }) => theme.COLORS.PINK_1};
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
        font-weight: 700;
        text-align: center;
        margin-bottom: 40px;
      }
      .accept_container {
        margin-top: 51px;
        .input_group {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          input {
            width: 15px;
            height: 15px;
          }
          label {
            margin-left: 5px;
            font-weight: 700;
            color: #3b96b7;
            &.herwill {
              color: ${({ theme }) => theme.COLORS.BLACK_3};
            }
          }
        }
        .accept_detail {
          font-size: 13px;
          color: ${({ theme }) => theme.COLORS.GRAY_8};
          .text_highlight {
            text-decoration: underline;
            a {
              &.herwill {
                color: ${({ theme }) => theme.COLORS.GRAY_8};
              }
            }
          }
        }
      }
    }
    .btn_accept {
      cursor: pointer;
      width: 100%;
      height: 45px;
      margin-top: 10px;
      background-color: #3b96b7;
      &.herwill {
        background-color: ${({ theme }) => theme.COLORS.PINK_1};
      }

      font-size: 13px;
      border: none;
      border-radius: 30px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      font-weight: 700;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
  }
`;
