import React from 'react';

export default function AaPlatform({ color = '#4E5B7E', width = "88.582", height = "99" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 88.582 99">
      <defs>
        <filter id="Union_363" x="0" y="0" width={width} height={height} filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.09" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_91926" data-name="Group 91926" transform="translate(-283.522 -1697.5)">
        <g transform="matrix(1, 0, 0, 1, 283.52, 1697.5)" filter="url(#Union_363)">
          <path id="Union_363-2" data-name="Union 363" d="M16095.133,15683.658a5,5,0,0,1-5-5v-51.127h5.3v8.507a9.681,9.681,0,1,0,19.361,0v-8.507h21.308v51.127a5,5,0,0,1-5,5Zm45.665-9.877v-9.987h.823a4.991,4.991,0,0,0,4.991-4.991v-11.1a4.991,4.991,0,0,0-4.991-4.992h-.823v-9.99h2.485a13.321,13.321,0,0,1,13.32,13.319v14.423a13.318,13.318,0,0,1-13.32,13.316Zm-40.67-37.738v-13.194h-10.205a9.141,9.141,0,0,1,10.822-14.589,3.237,3.237,0,0,0,4.087-.733,10.826,10.826,0,0,1,16.588,0,3.236,3.236,0,0,0,4.087.733,9.14,9.14,0,0,1,10.818,14.589h-26.21v13.194a4.994,4.994,0,1,1-9.987,0Z" transform="translate(-16080.52 -15597.16)" fill="#ed799e" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" />
        </g>
      </g>
    </svg>
  );
}
