import React from 'react';

export default function AaPlatform({ color = '#4E5B7E', width = "114.482", height = "93" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 114.482 93">
      <defs>
        <filter id="Path_108871" x="0" y="0" width={width} height={height} filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.09" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_108871)">
        <path id="Path_108871-2" data-name="Path 108871" d="M88,0H8.48A8.49,8.49,0,0,0,0,8.48V66.52A8.49,8.49,0,0,0,8.48,75H88a8.49,8.49,0,0,0,8.48-8.48V8.48A8.49,8.49,0,0,0,88,0M48.241,9.045A8.857,8.857,0,1,1,39.384,17.9a8.866,8.866,0,0,1,8.857-8.857m-25.628,0A8.857,8.857,0,1,1,13.756,17.9a8.866,8.866,0,0,1,8.857-8.857m0,56.91A8.857,8.857,0,1,1,31.47,57.1a8.866,8.866,0,0,1-8.857,8.857m25.628,0A8.857,8.857,0,1,1,57.1,57.1a8.866,8.866,0,0,1-8.857,8.857m25.628,0A8.857,8.857,0,1,1,82.726,57.1a8.866,8.866,0,0,1-8.857,8.857M79.9,40.327H16.583a2.827,2.827,0,1,1,0-5.653H79.9a2.827,2.827,0,0,1,0,5.653m-6.03-13.568A8.857,8.857,0,1,1,82.726,17.9a8.866,8.866,0,0,1-8.857,8.857" transform="translate(6 6)" fill="#ed799e" />
      </g>
    </svg>
  );
}
