import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { HospitalChooseContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { userService } from 'apiService';
import { setReduxHospitalList } from 'store/actions';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Cards, Displays, Modals } from 'components';
import { BannerPopup } from 'widgets'

class HospitalChooseContainer extends React.Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.fetchDataCheckUp();
    this.scrollToTop();
    this.disableLoader();

    // Tracking user pageview
    ReactGA.set({ page: this.props.router.location.pathname });
    ReactGA.pageview(this.props.router.location.pathname);
  }

  scrollToTop = () => {
    // window.scrollTo(0, 0);
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0;
  };

  fetchDataCheckUp = async () => {
    let res = await userService.GET_SERVICE_CATEGORIES_HER_WELL_SERVICE_LIST();
    if (res && res.success) {
      this.setState({
        dataCheckup: res.data,
      });
      this.props.setReduxHospitalList(res.data);
    }
  };

  disableLoader = () => {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  };

  handleClickServiceOrder = async (e) => {
    if (e.popup_image_uri) {
      this.renderModal(e.popup_image_uri)
    } else {
      ReactGA.event({
        category: 'herwill_cancer_hospital',
        action: 'click_chooseHospital_' + e.id,
      });
      this.props.router.navigate(
        `${ROUTE_PATH.PINK_INSURANCE_PACKAGE}?id=${e.id}`
      );
    }
  };

  handleClickBackFirstPage = () => {
    this.props.router.navigate(-1);
  };

  handleClickBackPage = () => {
    this.props.router.navigate(-1);
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };

  renderModal = (e) => {
    this.setState({
      isModal: true,
      renderModal: (
        <BannerPopup imgSrc={e} handleClickCloseModal={this.handleClickCloseModal} />
      ),
    });
  };

  render() {
    const { dataCheckup, loading, renderModal, isModal } = this.state;
    return (
      <HospitalChooseContainerStyled>
        {loading ? (
          <div className="loader_layout">
            <Displays.LogoLoadingFade />
          </div>
        ) : (
          <>
            <div
              className="bg_top_share"
              style={{
                backgroundImage: `url('/assets/images/backgrounds/head_bg2.jpg')`,
              }}
            />
            <div className="group_share">
              <div
                className="link_back"
                onClick={() => this.handleClickBackPage()}
              >
                <div className="destop">
                  <Displays.BoxLink
                    theme_standard_box_link
                    showArrow
                    label="ย้อนกลับ"
                  />
                </div>
                <div className="mobile">
                  <Displays.BoxLink
                    theme_white_box_link
                    showArrow
                    label="ย้อนกลับ"
                  />
                </div>
              </div>
              <div className="container_share">
                <div className="title_page">จองตรวจคัดกรองมะเร็งเต้านม</div>
                <div className="sub_title_page">
                  โรงพยาบาลที่เข้าร่วมโครงการในราคาพิเศษ
                </div>
                <Cards.HospitalList
                  theme_standard
                  handleClickBackFirstPage={() =>
                    this.handleClickBackFirstPage()
                  }
                  dataCheckup={dataCheckup}
                  handleClickServiceOrder={this.handleClickServiceOrder}
                />
              </div>
            </div>
          </>
        )}
        <Modals.Modal
          theme_top_layout
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
          bgColor="rgba(0, 0, 0, 0.8)"
        >
          {renderModal}
        </Modals.Modal>
      </HospitalChooseContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxHospitalList: (data) => dispatch(setReduxHospitalList(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HospitalChooseContainer));
