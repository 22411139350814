import React from 'react';

export default function AaPlatform({ color = '#cf1a71', width = '24.068', height = '24.111' }) {
  return (
    <svg
      id="loupe"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24.068 24.111"
    >
      <g id="Group_60469" data-name="Group 60469">
        <path
          id="Path_87821"
          data-name="Path 87821"
          d="M23.774,22.4,16.93,15.542a9.542,9.542,0,1,0-1.418,1.421l6.844,6.856a1,1,0,1,0,1.418-1.42ZM9.527,17.081a7.535,7.535,0,1,1,7.521-7.535A7.536,7.536,0,0,1,9.527,17.081Z"
          transform="translate(0 -0.003)"
          fill={color}
        />
      </g>
    </svg>
  );
}
