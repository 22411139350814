import React from 'react';
import { SliderFormStyled } from './styled';
import Slider from '@material-ui/core/Slider';
import theme from 'styles/theme.json';
import { Displays, Buttons } from 'components';

class SliderForm extends React.Component {
  state = {
    id: [],
    loading: true,
  };

  componentDidMount = () => {
    const { question } = this.props;

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1500);
    this.setState({
      id: [{ id: question.answers[0].id }],
    });
  };

  handleSubmit = () => {
    const { id } = this.state;
    const { handleSubmitAns } = this.props;
    handleSubmitAns(id);
  };

  handleSliderChange = (e, value) => {
    const { question } = this.props;

    for (let index = 0; index < question.answers.length; index++) {
      if (question.answers[index].order === value + 1) {
        this.setState({
          id: [{ id: question.answers[index].id }],
        });
      }
    }
  };

  render() {
    const { question, scheme } = this.props;
    const { loading } = this.state;

    return (
      <SliderFormStyled scheme={scheme}>
        {question && question.answers && (
          <>
            {loading ? (
              <Displays.LogoLoadingFade />
            ) : (
              <img
                className="img_logo"
                src={'/assets/images/icons/logo_question.png'}
                alt="logo well"
              />
            )}
          </>
        )}
        <div className="title">{question && question.label}</div>
        {question && question.description && (
          <div className="title2">{question.description}</div>
        )}
        <div className="title_slide">ระดับความรุนแรง</div>
        <div>
          <Slider
            defaultValue={0}
            getAriaValueText=""
            aria-labelledby="discrete-slider"
            onChange={this.handleSliderChange}
            step={1}
            min={0}
            max={question.answers.length - 1}
          />
          <div className="label_layout">
            {question &&
              question.answers.map((e, i) => <div key={i}>{e.label}</div>)}
          </div>
        </div>
        <div className="btn_bottom">
          <Buttons.Button
            width="100%"
            height="40px"
            theme_standard
            style={{ marginTop: 30 }}
            handleClickButton={() => this.handleSubmit()}
            backgroundColor={scheme && theme.COLORS.PINK_1}
          >
            ต่อไป
          </Buttons.Button>
        </div>
      </SliderFormStyled>
    );
  }
}

export default SliderForm;
