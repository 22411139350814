import styled from 'styled-components';

export const PrefixIconBtnStyled = styled.div`
  .prefix_icon_conatianer {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    .pi_label {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
      color: ${({ theme }) => theme.COLORS.BLACK_7};
    }
  }

  .theme_standard_view {
    .prefix_icon_conatianer {
      column-gap: 4px;
      .pi_label {
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
      }
    }
  }

  .theme_standard_view_regular {
    .prefix_icon_conatianer {
      column-gap: 24px;
      .pi_label {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      }
    }
  }

  .theme_small {
    .prefix_icon_conatianer {
      column-gap: 4px;
      .pi_label {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .theme_standard_view_regular {
      .prefix_icon_conatianer {
        column-gap: 10px;
        .pi_label {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
          padding-top: 1px;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .theme_standard_view {
      .prefix_icon_conatianer {
        .pi_label {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
          padding-top: 1px;
        }
      }
    }
    .theme_standard_view_big {
      .prefix_icon_conatianer {
        .pi_label {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
          padding-top: 1px;
        }
      }
    }
    .theme_standard_view_regular {
      .prefix_icon_conatianer {
        column-gap: 10px;
        .pi_label {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_BOLD};
          padding-top: 1px;
        }
      }
    }
  }
`;
