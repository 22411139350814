import React, { useEffect, useState } from 'react';
import { InputVideoSearchStyled } from './styled';
import cx from 'classnames';
import { useComponentVisible } from 'utils/functions/detectClickOutside';
import { Icons } from 'components';

const InputVideoSearch = ({
  theme_standard,
  theme_pink,
  value,
  onChange,
  placeholder = 'หมวดหมู่',
  options,
  label,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_pink: theme_pink,
  });

  const {
    ref,
    isComponentVisible,
    // setIsComponentVisible
  } = useComponentVisible();

  const [_active, _setActive] = useState(false);
  const [_value, _setValue] = useState();

  useEffect(() => {
    if (isComponentVisible === false) {
      // _setInsertHeartRateModalShow(false);
      _setActive(false);
    }
  }, [isComponentVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (options && value && value !== _value) {
      // let find = options.find((e) => e.value === value);
      _setValue(value);
    }
  }, [value, options, isComponentVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleClick = () => {
    _setActive(!_active);
  };

  const _handleSelect = (e) => {
    _setValue(e);
    _setActive(false);
    onChange && onChange(e);
  };

  return (
    <InputVideoSearchStyled>
      <div className={customClass}>
        {label && <div className="cate_label">{label}</div>}
        <div ref={ref} className="input_dropdown_video">
          <div className="body_dropdown" onClick={_handleClick}>
            <div className="label_show">
              {_value ? _value.label : placeholder}
            </div>
            {theme_pink ? (
              <>
                <div className="d_arrow">
                  {_active ? (
                    <Icons.ArrowPinkUpDown />
                  ) : (
                    <Icons.ArrowPinkUpDown isUp />
                  )}
                </div>
                <div className="d_arrow mobile">
                  {_active ? (
                    <Icons.ArrowPinkUpDown width="14" height="14" />
                  ) : (
                    <Icons.ArrowPinkUpDown isUp width="14" height="14" />
                  )}
                </div>
              </>
            ) : (
              <div className="d_arrow">
                {_active ? <Icons.ArrowVUp /> : <Icons.ArrowVDown />}
              </div>
            )}
            {_active && (
              <div className="list_dropdown">
                {options &&
                  options.length > 0 &&
                  options.map((e, i) => (
                    <div
                      key={i + 1}
                      className="dropdown_item"
                      onClick={() => _handleSelect(e)}
                    >
                      {e.label}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </InputVideoSearchStyled>
  );
};

export default InputVideoSearch;
