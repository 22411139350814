import { useEffect, useState } from 'react';
import { NavbarPinkAlertWidgetStyled } from './styled';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import queryString from 'query-string';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Icons } from 'components';

const NavbarPinkAlertWidget = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationSplit = location.pathname.split('/');

  const [_active, _setActive] = useState('home');
  const [_isOpen, _setIsOpen] = useState(false);
  const [_isOpenSearch, _setIsOpenSearch] = useState(false);
  const [_value, _setValue] = useState('');

  useEffect(() => {
    if (locationSplit[locationSplit.length - 1] === 'riskfactor') {
      _setActive('how');
    } else {
      const { sec } = queryString.parse(location.search);
      if (sec) {
        _setActive(sec);
        setTimeout(() => {
          _handleScroll(sec);
        }, 400);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _toggleDrawer = () => {
    _setIsOpen((prevState) => !prevState);
  };

  const _handleClickMenu = (e) => {
    _setActive(e.key);
    _handleScroll(e.key);
    navigate(e.key === 'how' ? ROUTE_PATH.RISK_FACTOR : `/?sec=${e.key}`);
  };

  const _handleClickOpenSearch = () => {
    _setValue('');
    _setIsOpenSearch(!_isOpenSearch);
  };

  const _handleSetSearch = (e) => {
    _setValue(e);
  };

  const renderOffset = () => {
    let width = window.innerWidth;
    if (width > 1536) {
      return -112;
    }
    if (width > 1024) {
      return -102;
    }
    if (width > 540) {
      return -102;
    }
    return -80;
  };

  const _handleScroll = (key) => {
    _setIsOpen(false);
    scroller.scrollTo(key, {
      duration: 1000,
      delay: 100,
      smooth: true,
      // containerId: 'ContainerElementID',
      offset: renderOffset(), // Scrolls to element + 50 pixels down the page
    });
  };

  const _handleEnterSearchVideoSubmit = (e) => {
    e.preventDefault();
    let query = [];
    if (e.target.search.value) {
      query.push(`keywords=${e.target.search.value}`);
    }
    navigate(
      `${ROUTE_PATH.VIDEOS}${
        query.length === 0 ? '?sec=video' : `?sec=video&${query.join('&')}`
      }`
    );
  };

  const _handleClickSearch = () => {
    // e.preventDefault();
    let query = [];
    _setIsOpenSearch(false);
    if (_value) {
      query.push(`keywords=${_value}`);
    }
    navigate(
      `${ROUTE_PATH.VIDEOS}${
        query.length === 0 ? '?sec=video' : `?sec=video&${query.join('&')}`
      }`
    );
  };

  return (
    <NavbarPinkAlertWidgetStyled>
      <div className="nav_pink_left_col">
        <img
          className="nav_p_logo"
          alt="herwill logo"
          src="/assets/images/icons/logo_HerWill.svg"
        />
      </div>
      <div className="nav_pink_right_col">
        {menuList &&
          menuList.map((e, i) => (
            <div key={i} className="nav_p_right_col">
              <div
                className={`nav_menu_item ${e.key === _active ? 'active' : ''}`}
                onClick={() => _handleClickMenu(e)}
              >
                {e.label}
              </div>
            </div>
          ))}
        <div className="nav_p_right_col">
          <form onSubmit={_handleEnterSearchVideoSubmit}>
            <div className="nav_serarch_container">
              <input
                className="nav_search"
                placeholder="ค้นหาวิดีโอ"
                type="search"
                name="search"
                value={_value}
                onChange={(e) => {
                  _handleSetSearch(e.target.value);
                }}
              />
              <div
                className="nav_search_icon_wrap"
                onClick={_handleClickSearch}
              >
                <Icons.Search width="19px" height="19px" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="nav_hamburger_right_col">
        <div
          className={`nav_h_search ${_isOpenSearch ? 'hide' : ''}`}
          onClick={_handleClickOpenSearch}
        >
          <Icons.Search width="16" height="16" />
        </div>
        <form onSubmit={_handleEnterSearchVideoSubmit}>
          <div
            className={`mobile_serarch_container ${
              _isOpenSearch ? 'show' : ''
            }`}
          >
            <input
              className="mobile_search"
              placeholder="ค้นหาวิดีโอ"
              type="search"
              name="search"
              value={_value}
              onChange={(e) => {
                _handleSetSearch(e.target.value);
              }}
            />
            <div
              className="mobile_search_icon_wrap"
              onClick={_handleClickOpenSearch}
            >
              <Icons.Search width="16" height="16" />
            </div>
          </div>
        </form>
        <div className="nav_h_hamburger" onClick={_toggleDrawer}>
          <Icons.Hamburger />
        </div>
        <Drawer
          className="drawer_container"
          open={_isOpen}
          onClose={_toggleDrawer}
          // direction="right"
          size="100vh"
          direction="top"
          zIndex="300"
        >
          <div className="dw_header_row">
            <div className="dwh_left_col">
              <img
                className="dwh_logo"
                alt="herwill logo"
                src="/assets/images/icons/logo_HerWill.svg"
              />
            </div>
            <form onSubmit={_handleEnterSearchVideoSubmit}>
              <div className="dwh_right_col">
                <div
                  className={`hr_search ${_isOpenSearch ? 'hide' : ''}`}
                  onClick={_handleClickOpenSearch}
                >
                  <Icons.Search color="#ED799E" width="16" height="16" />
                </div>
                <div
                  className={`dwh_mobile_serarch_container ${
                    _isOpenSearch ? 'show' : ''
                  }`}
                >
                  <input
                    className="dwh_mobile_search"
                    placeholder="ค้นหาวิดีโอ"
                    type="search"
                    name="search"
                    value={_value}
                    onChange={(e) => {
                      _handleSetSearch(e.target.value);
                    }}
                  />
                  <div
                    className="dwh_mobile_search_icon_wrap"
                    // onClick={_handleClickOpenSearch}
                    onClick={_handleClickSearch}
                  >
                    <Icons.Search width="16" height="16" />
                  </div>
                </div>
                <div className="hr_close" onClick={_toggleDrawer}>
                  <Icons.CloseX2 />
                </div>
              </div>
            </form>
          </div>
          <div className="dw_body">
            <div className="body_menu">
              {menuList &&
                menuList.map((e, i) => (
                  <div
                    className={`menu_box ${e.key === _active ? 'active' : ''}`}
                    onClick={() => _handleClickMenu(e)}
                  >
                    {e.label}
                  </div>
                ))}
            </div>
            <div className="img_bottom" />
          </div>
        </Drawer>
      </div>
    </NavbarPinkAlertWidgetStyled>
  );
};

const menuList = [
  {
    key: 'home',
    label: 'หน้าแรก',
  },
  {
    key: 'how',
    label: 'ทำอย่างไรเมื่อมีความเสี่ยง',
  },
  {
    key: 'video',
    label: 'วีดีโอ',
  },
  {
    key: 'content',
    label: 'บทความ',
  },
];

export default NavbarPinkAlertWidget;
