import { COOKIE_PERMISSION } from '../actions/actionTypes';

const initialState = false;

const configProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case COOKIE_PERMISSION:
      state = action?.data;
      return state;
    default:
      return state;
  }
};

export default configProjectReducer;
