import React from 'react';

export default function AaPlatform({
  width = "67.827",
  height = "62.841",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 67.827 62.841"
    >
      <defs>
        <filter
          id="Rectangle_31204"
          x="-0.075"
          y="-0.159"
          width="68"
          height="63"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur" />
          <feFlood flood-opacity="0.051" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Union_350"
          x="0"
          y="0"
          width="67.827"
          height="62.841"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur-2" />
          <feFlood flood-opacity="0.051" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_31205"
            data-name="Rectangle 31205"
            width="58"
            height="53"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Group_90177"
        data-name="Group 90177"
        transform="translate(-1483.075 -5228.159)"
      >
        <g
          transform="matrix(1, 0, 0, 1, 1483.07, 5228.16)"
          filter="url(#Union_350)"
        >
          <path
            id="Union_350-2"
            data-name="Union 350"
            d="M24.808,53A29.148,29.148,0,0,1,14.53,49.495a.48.48,0,0,1-.18-.664.5.5,0,0,1,.675-.176,28.379,28.379,0,0,0,27.927,0,.5.5,0,0,1,.675.176.48.48,0,0,1-.18.664A29.148,29.148,0,0,1,33.169,53Zm-8.734-6.135a.482.482,0,0,1-.18-.664.5.5,0,0,1,.675-.176,25.238,25.238,0,0,0,24.838,0,.5.5,0,0,1,.675.176.482.482,0,0,1-.18.664,26.242,26.242,0,0,1-25.828,0Zm1.544-2.629a.482.482,0,0,1-.18-.664.5.5,0,0,1,.675-.176,22.1,22.1,0,0,0,21.749,0,.5.5,0,0,1,.675.176.482.482,0,0,1-.18.664,23.107,23.107,0,0,1-22.739,0Zm-8.4-19.353A19.6,19.6,0,0,1,28.988,5.455,19.6,19.6,0,0,1,48.757,24.883,19.6,19.6,0,0,1,28.988,44.312,19.6,19.6,0,0,1,9.219,24.883Zm47.694,0A27.463,27.463,0,0,0,42.952,1.113a.481.481,0,0,1-.18-.663.5.5,0,0,1,.675-.177A28.434,28.434,0,0,1,57.9,24.883a.495.495,0,0,1-.989,0Zm-6.178,0A21.388,21.388,0,0,0,39.863,6.372a.479.479,0,0,1-.18-.663.5.5,0,0,1,.675-.177A22.359,22.359,0,0,1,51.723,24.883a.494.494,0,0,1-.988,0Zm-44.482,0A22.361,22.361,0,0,1,17.618,5.531a.5.5,0,0,1,.675.177.48.48,0,0,1-.18.663A21.39,21.39,0,0,0,7.241,24.883a.494.494,0,0,1-.988,0Zm-3.089,0A25.4,25.4,0,0,1,16.074,2.9a.5.5,0,0,1,.675.177.48.48,0,0,1-.18.663A24.427,24.427,0,0,0,4.153,24.883a.494.494,0,0,1-.988,0Zm-3.09,0A28.434,28.434,0,0,1,14.53.273.5.5,0,0,1,15.2.45a.481.481,0,0,1-.18.663A27.463,27.463,0,0,0,1.064,24.883a.49.49,0,0,1-.494.486A.491.491,0,0,1,.075,24.883Zm53.749,0A24.424,24.424,0,0,0,41.407,3.743a.481.481,0,0,1-.18-.664A.5.5,0,0,1,41.9,2.9a25.4,25.4,0,0,1,12.91,21.982.494.494,0,0,1-.988,0Z"
            transform="translate(2.93 2.84)"
            fill="#9bdeac"
            stroke="rgba(0,0,0,0)"
            stroke-miterlimit="10"
            stroke-width="1"
          />
        </g>
        <g
          id="Group_90176"
          data-name="Group 90176"
          transform="translate(1486 5231)"
        >
          <g
            id="Group_90175"
            data-name="Group 90175"
            transform="translate(0 0)"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_107637"
              data-name="Path 107637"
              d="M160.914,133.6a3.707,3.707,0,1,1-3.707-3.643,3.675,3.675,0,0,1,3.707,3.643"
              transform="translate(-128.219 -108.713)"
              fill="#fff"
            />
            <path
              id="Path_107638"
              data-name="Path 107638"
              d="M200.986,78.776h8.7a1.225,1.225,0,0,0,1.234-1.306,16.926,16.926,0,0,0-7.469-12.713,1.251,1.251,0,0,0-1.774.393l-4.352,7.407a1.191,1.191,0,0,0,.271,1.532,6.041,6.041,0,0,1,2.166,3.664,1.233,1.233,0,0,0,1.219,1.023"
              transform="translate(-164.689 -53.893)"
              fill="#fff"
            />
            <path
              id="Path_107639"
              data-name="Path 107639"
              d="M84.881,72.557,80.53,65.151a1.251,1.251,0,0,0-1.774-.393A16.928,16.928,0,0,0,71.287,77.47a1.225,1.225,0,0,0,1.234,1.306h8.7a1.234,1.234,0,0,0,1.219-1.023,6.044,6.044,0,0,1,2.166-3.664,1.191,1.191,0,0,0,.271-1.532"
              transform="translate(-59.547 -53.893)"
              fill="#fff"
            />
            <path
              id="Path_107640"
              data-name="Path 107640"
              d="M134.567,187.57a6.242,6.242,0,0,1-2.167-.385,1.237,1.237,0,0,0-1.487.534l-4.352,7.408a1.207,1.207,0,0,0,.537,1.705,17.571,17.571,0,0,0,14.938,0,1.207,1.207,0,0,0,.537-1.705l-4.352-7.408a1.237,1.237,0,0,0-1.487-.534,6.243,6.243,0,0,1-2.167.385"
              transform="translate(-105.579 -156.615)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
