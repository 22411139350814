import styled from 'styled-components';

export const BannerPartnerWidgetStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .line_box {
    width: 100%;
    position: relative;
    height: 16px;
    .line {
      margin: auto;
      position: absolute;
      top: 7px;
      left: 0;
      right: 0;
      height: 1px;
      background: #9898981a;
      max-width: 584px;
      width: 100%;
      z-index: 0;
    }
    .line_txt {
      z-index: 2;
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      width: fit-content;
      margin: auto;
      padding: 0 10px;
      font-size: 11px;
      color: #9e9e9e;
      font-weight: 500;
      background: #f8f8f8;
      white-space: nowrap;
    }
  }
  .img_partner {
    width: 100%;
    max-width: 458px;
    height: auto;
  }
`;
