import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { ShareContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { setReduxUserAuth } from 'store/actions';
import { HerWillContainer } from './SubProjects';
import { ShareGroup } from 'widgets';
import { Displays } from 'components';

class ShareContainer extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.router.location.pathname });
    ReactGA.pageview(this.props.router.location.pathname);
  }

  handleClickToHome = (scheme) => {
    // Tracking user event
    ReactGA.event({
      category: scheme + '_exit',
      action: 'click_goHome',
    });
    this.setState({
      loading: true,
    });
    // this.clearToken();
    setTimeout(() => {
      this.setState({
        loading: false,
      });
      this.props.router.navigate('/');
    }, Math.floor(Math.random() * 500) + 100);
  };

  handleClickToPinkAlert = () => {
    // Tracking user event
    ReactGA.event({
      category: 'pinkalert_exit',
      action: 'click_goPinkAlert',
    });

    window.location = process.env.REACT_APP_PINKALERT_FB_URI;
  };

  copyFunction = () => {
    var copyText = document.createElement('textarea');
    copyText.value = process.env.REACT_APP_SELF_ENDPOINT;
    document.body.append(copyText);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    copyText.remove();
    alert('Copied the URL: ' + copyText.value);
  };

  clearToken = () => {
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    this.props.setReduxUserAuth(false);
  };

  renderBody = (key) => {
    switch (key) {
      case 'herwill':
        return (
          <HerWillContainer
            propsHitory={this.props.history}
            handleClickNext={this.handleClickNext}
            handleClickHome={() => this.handleClickToHome(key)}
          />
        );
      case 'pinkalert':
        const { authRedux } = this.props;
        return (
          <>
            <div className="bg_layout">
              <img
                className="icon_logo"
                src={'/assets/images/icons/logo_pink.png'}
                alt="logo well"
              />
            </div>
            <div className="group_box">
              <div className="box_layout">
                <div className="txt_center">
                  <div
                    className="txt_normal"
                    style={({ fontWeight: 700 }, { fontSize: '18px' })}
                  >
                    ขอบคุณ
                  </div>
                  <div className="txt_pink">
                    คุณ{authRedux && authRedux.firstname}
                  </div>
                </div>
                <div className="txt_center">
                  <div className="txt_normal">คุณได้เข้าร่วมเป็น 1 ใน</div>
                  <div className="txt_pink">
                    {authRedux && authRedux.surveyResults.order_id}
                  </div>
                </div>
                <div className="txt_center">
                  <div className="txt_normal">
                    ของผู้ได้รับการตรวจคัดกรองมะเร็งเต้านม
                  </div>
                </div>
                <div className="txt_center">
                  <div className="txt_navy">
                    แชร์ลิงค์ ชวนเพื่อนมาเช็คมะเร็งเต้านม
                  </div>
                </div>
                <ShareGroup.ShareBtnPinkAlert />
                <button
                  className="btn_end"
                  onClick={() => this.handleClickToHome(key)}
                >
                  <div className="txt_btn">กลับสู่หน้าหลัก</div>
                </button>

                <button
                  className="btn_pink"
                  onClick={this.handleClickToPinkAlert}
                >
                  <div className="txt_btn">เยี่ยมชมโครงการ Pink Alert</div>
                </button>
              </div>
            </div>
          </>
        );
      default:
        break;
    }
  };

  render() {
    const { loading } = this.state;
    const { configProjectRedux } = this.props;
    const { scheme } = configProjectRedux;
    return (
      <ShareContainerStyled
        src={loading ? '' : '/assets/images/icons/bg_pink.png'}
      >
        {loading ? <Displays.Loading /> : this.renderBody(scheme)}
      </ShareContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.authenRedux,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShareContainer));
