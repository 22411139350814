import { default as ContentItem } from './ContentItem';
import { default as HospitalList } from './HospitalList';
import { default as InsuranceItem } from './InsuranceItem';
import { default as KnownledItem } from './KnownledItem';
import { default as PackageList2 } from './PackageList2';
import { default as PreviewVideo } from './PreviewVideo';
import { default as PreviewVideoRec } from './PreviewVideoRec';
import { default as TreatmentItemWidget } from './TreatmentItemWidget';

const EXPORT = {
  ContentItem,
  HospitalList,
  InsuranceItem,
  KnownledItem,
  PackageList2,
  PreviewVideo,
  PreviewVideoRec,
  TreatmentItemWidget,
};

export default EXPORT;
