import { PercentStyled } from './styled';
import cx from 'classnames';
import { Icons } from 'components';

const Percent = ({ theme_standard, theme_branner, percent, color }) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_branner: theme_branner,
  });
  return (
    <PercentStyled percent={percent} color={color}>
      <div className={customClass}>
        <div className="percent_container">
          <div className="icon_per">
            <Icons.TreatHeartFamale color={color} />
          </div>
          <div className="icon_per_mobile">
            <Icons.TreatHeartFamale color={color} width="6.61" height="11.92" />
          </div>
          <div className="percent_item">
            <div className="pi_active">
              {percent} <span className="fs_23">%</span>
            </div>
          </div>
        </div>
      </div>
    </PercentStyled>
  );
};

export default Percent;
