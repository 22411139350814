import styled from 'styled-components';

export const InputTextStyled = styled.div`
  /*===============================================
 Container 
===============================================*/
  width: ${(props) => (props.width ? props.width : '100%')};
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /*===============================================
Theme 
===============================================*/
  .theme_standard {
    input {
      height: 45px;
      width: ${({ width }) => (width ? width : '300px')};
      border-radius: 15px;
      box-shadow: 2px 1px 6px 0px rgba(61, 163, 193, 0.2),
        0 4px 20px 0 rgba(61, 163, 193, 0.19);
      border: none;
      outline: none;
      background-color: white;
      padding: 0 10px;
    }
    .error {
      padding-top: 5px;
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  .theme_standard_2 {
    input {
      height: 50px;
      width: 100%;
      border-radius: 15px;
      box-shadow: 2px 1px 6px 0px rgba(61, 163, 193, 0.2),
        0 4px 20px 0 rgba(61, 163, 193, 0.19);
      border: none;
      outline: none;
      background-color: white;
      padding: 0 10px;
    }
    .error {
      padding-top: 5px;
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  .theme_herwell {
    input {
      height: 35px;
      width: ${({ width }) => (width ? width : '300px')};
      background: transparent;
      border: none;
      border-bottom: 2px solid ${({ theme }) => theme.COLORS.BLACK_8};
      color: ${({ theme }) => theme.COLORS.BLACK_6};
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_23};
      font-weight: 600;
      outline: none;
      padding: 0 10px;
    }
    .error {
      padding-top: 5px;
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  .theme_well {
    input {
      height: 35px;
      width: ${({ width }) => (width ? width : '300px')};
      background: transparent;
      border: none;
      border-bottom: 2px solid ${({ theme }) => theme.COLORS.WHITE_1};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
      outline: none;
      padding: 0 10px;
    }
    .error {
      padding-top: 5px;
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  .theme_well_login {
    input {
      height: 35px;
      width: ${({ width }) => (width ? width : '300px')};
      background: transparent;
      border: none;
      border-bottom: 2px solid #2eb7b8;
      color: ${({ theme }) => theme.COLORS.BLACK_2};
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
      outline: none;
      padding: 0 10px;
    }
    .error {
      padding-top: 5px;
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  .theme_normal {
    width: 100%;
    input {
      width: ${({ width }) => (width ? width : '100%')};
      background: inherit;
      border: none;
      outline: none;
      border-bottom: 2px solid #2eb7b8;
      padding-bottom: 3px;
    }
    .error {
      padding-top: 5px;
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
`;
