import React from 'react';

export default function AaPlatform({
  color = '#4E5B7E',
  width = "98",
  height = "98"
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 98 98">
      <defs>
        <filter id="Path_108869" x="0" y="0" width={width} height={height} filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.09" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_108869)">
        <path id="Path_108869-2" data-name="Path 108869" d="M72.727,0H7.273A7.28,7.28,0,0,0,0,7.273V72.727A7.28,7.28,0,0,0,7.273,80H72.727A7.28,7.28,0,0,0,80,72.727V7.273A7.28,7.28,0,0,0,72.727,0M63.141,23.066,52.857,33.35a1.817,1.817,0,0,1-2.572,0,14.787,14.787,0,0,0-13.8-3.662l-3.919-8.623a1.818,1.818,0,0,0-3.31,1.506l3.82,8.4a13.521,13.521,0,0,0-3.36,2.376,1.817,1.817,0,0,1-2.571,0L16.86,23.066a1.817,1.817,0,0,1,0-2.571,32.723,32.723,0,0,1,46.282,0,1.818,1.818,0,0,1,0,2.571" transform="translate(6 6)" fill="#ed799e" />
      </g>
    </svg>
  );
}
