import { useEffect, useState } from 'react';
import { TokenManager } from 'contexts';
import { Routes, Route } from 'react-router-dom';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { herwillService, userService } from 'apiService';
import { useDispatch } from 'react-redux';
import { setReduxConfigProject } from 'store/actions';
import pinkAlertRoutes from './pinkAlertRoutes';
import { Mainlayouts, Displays } from 'components';
import PinkAlertContainer from 'containers/LandingPageSystem/PinkAlertContainer';
import RiskFactorContainer from 'containers/LandingPageSystem/RiskFactorContainer';
import AutoSignupContainer from 'containers/MemberSystem/AutoSignupContainer';
import VideoContainer from 'containers/VideoSystem/VideoContainer';
import VideoSearchContainer from 'containers/VideoSystem/VideoSearchContainer';

const Navigations = () => {
  const dispatch = useDispatch();

  const [_isLoading, _setIsLoading] = useState(true);

  useEffect(() => {
    _fetchConfig();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _fetchConfig = async () => {
    let res = await Promise.all([_autoLogin(), _fetchDataConfigDomain()]);
    if (res) {
      _setIsLoading(false);
    }
  };

  const _autoLogin = () => {
    return new Promise(async (resolve) => {
      const herwillToken = localStorage.getItem('herwill_token');
      if (herwillToken) {
        let res = await herwillService.POST_LOGIN_WITH_TOKEN();
        if (res && res.success) {
          resolve(true);
        } else {
          localStorage.removeItem('herwill_token');
          _autoLogin();
        }
      } else {
        let res = await herwillService.POST_LOGIN();
        if (res && res.success) {
          localStorage.setItem('herwill_token', res.token);
          resolve(true);
        }
      }
    });
  };

  const _fetchDataConfigDomain = () => {
    return new Promise(async (resolve) => {
      const hostname =
        process.env.REACT_APP_CUSTOM_NODE_ENV === 'development'
          ? domainList[3]
          : window.location.hostname.toLowerCase();
      let res = await userService.GET_SETTING_CONFIG_BY_DOMAIN(hostname);
      if (res && res.success) {
        dispatch(setReduxConfigProject(res.data));
        resolve(true);
      }
    });
  };

  return _isLoading ? (
    <Displays.Loading />
  ) : (
    <TokenManager>
      <Routes>
        <Route index element={<PinkAlertContainer />} />
        <Route
          path={ROUTE_PATH.RISK_FACTOR}
          element={<RiskFactorContainer />}
        />
        <Route element={<Mainlayouts.HerwillMainLayout />}>
          <Route
            path={ROUTE_PATH.AUTO_SIGNUP}
            element={<AutoSignupContainer />}
          />
          <Route path={ROUTE_PATH.VIDEOS} element={<VideoSearchContainer />} />
          <Route path={ROUTE_PATH.VIDEOS_DETAIL} element={<VideoContainer />} />
          {/* <Route path="*" element={<PinkAlertContainer />} /> */}
        </Route>
        <Route
          path={ROUTE_PATH.HOME_PATH}
          element={<Mainlayouts.PinkMainLayout />}
        >
          {pinkAlertRoutes &&
            pinkAlertRoutes.map((e, i) => (
              <Route
                key={i}
                index={e.index}
                path={e.path}
                element={e.element}
              />
            ))}
        </Route>
      </Routes>
    </TokenManager>
  );
};

const domainList = [
  'well-pinkalert-beta.invitrace.app',
  'well-herwill-beta.invitrace.app',
  'well-herwill-corporate-beta.invitrace.app',
  'herwill-dev.invitrace.app',
  'herwill-corporate-dev.invitrace.app',
  'pinkalert-dev.invitrace.app',
  'herwill-admin-dev.invitrace.app',
  false,
];

export default Navigations;
