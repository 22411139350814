import React from 'react';

export default function AaPlatform({
  width = "78.919",
  height = "73"
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox="0 0 78.919 73">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_31191" data-name="Rectangle 31191" width={width} height={height} fill="#ed799e" />
        </clipPath>
      </defs>
      <g id="Group_90179" data-name="Group 90179" transform="translate(0 -0.313)">
        <g id="Group_90130" data-name="Group 90130" transform="translate(0 0.313)">
          <g id="Group_90129" data-name="Group 90129" clip-path="url(#clip-path)">
            <path id="Path_107613" data-name="Path 107613" d="M57.337,161.192a4.342,4.342,0,0,0-7.3-3.28l-7.3,6.9a4.337,4.337,0,1,0,5.952,6.31l7.3-6.9a4.309,4.309,0,0,0,1.353-3.03" transform="translate(-32.741 -124.329)" fill="#ed799e" />
            <path id="Path_107614" data-name="Path 107614" d="M160.088,1.246A21.471,21.471,0,0,0,144.662,7.12c-30.893,29.171-1.01.955-14.61,13.8a2.984,2.984,0,0,0-.915,2.036c-.078,3.146,5.457,5.609,8.947,2.308l10.487-9.9a4.215,4.215,0,0,1,5.958.172L175.252,37.5c12.46-13.37,3.546-35.706-15.164-36.252" transform="translate(-102.209 -1.248)" fill="#ed799e" />
            <path id="Path_107615" data-name="Path 107615" d="M132.921,241.307a4.342,4.342,0,0,0-7.3-3.28l-7.3,6.9a4.337,4.337,0,1,0,5.952,6.309l7.3-6.9a4.308,4.308,0,0,0,1.353-3.029" transform="translate(-92.563 -187.737)" fill="#ed799e" />
            <path id="Path_107616" data-name="Path 107616" d="M72.3,40.178c-.14-.1.3.32-21.734-23.029a1.4,1.4,0,0,0-1.987-.056l-10.486,9.9a8.559,8.559,0,0,1-12.12-.354,5.7,5.7,0,0,1,.226-8.071L37.705,7.7A21.562,21.562,0,1,0,6.328,37.28L8.32,39.388c.124-.14.013-.015,7.328-6.92A7.135,7.135,0,0,1,27.56,38.949,7.079,7.079,0,0,1,35.441,47.3a7.086,7.086,0,0,1,7.881,8.357,7.064,7.064,0,0,1,7.923,8.03,4.331,4.331,0,0,0,5.324-6.719L37.851,37.135A1.405,1.405,0,0,1,39.9,35.206L58.613,55.043a4.337,4.337,0,0,0,6.307-5.955L46.2,29.252a1.4,1.4,0,0,1,2.044-1.927l18.72,19.836A4.341,4.341,0,0,0,74.458,44.3,5.436,5.436,0,0,0,72.3,40.178" transform="translate(-0.292 -0.933)" fill="#ed799e" />
            <path id="Path_107617" data-name="Path 107617" d="M166.483,276.847a4.31,4.31,0,0,0-3.1,1.18l-7.3,6.9a4.337,4.337,0,0,0,5.954,6.309l7.3-6.9a4.344,4.344,0,0,0-2.852-7.491" transform="translate(-122.453 -219.386)" fill="#ed799e" />
          </g>
        </g>
        <g id="Group_90132" data-name="Group 90132" transform="translate(0 0.313)">
          <g id="Group_90131" data-name="Group 90131" clip-path="url(#clip-path)">
            <path id="Path_107618" data-name="Path 107618" d="M95.251,201.249a4.342,4.342,0,0,0-7.3-3.28l-7.3,6.9a4.338,4.338,0,0,0,5.953,6.31l7.306-6.9a4.309,4.309,0,0,0,1.351-3.028" transform="translate(-62.775 -156.032)" fill="#ed799e" />
          </g>
        </g>
      </g>
    </svg>

  );
}
