import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';

const bounceAnimation = keyframes`${fadeInUp}`;
export const MultipleButtonFormStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  // max-width: ${(props) => props.window_width + 'px'};
  max-width: 400px;
  .img_logo {
    width: 50px;
    height: 50px;
  }
  .txt_grey {
    display: none;
  }
  .txt_detail {
    color: #003564;
    font-size: 18px;
    margin-top: 20px;
    max-width: 400px;
  }
  .txt_detail2 {
    color: grey;
    font-size: 16px;
    margin-top: 10px;
    max-width: 400px;
  }
  .title {
    font-weight: 700;
    font-size: 18px;
    color: #2f4f74;
    margin: 20px 0 0 10px;
    animation: 1s ${bounceAnimation};
  }
  .title2 {
    font-size: 14px;
    color: #2f4f74;
    margin: 3px 0 10px 10px;
    animation: 1s ${bounceAnimation};
  }
  .button_layout {
    display: flex;
    justify-content: center;
    margin-left: 10px;

    .width_btn {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      animation: 2s ${bounceAnimation};
    }
  }
  .button_layout_carousel {
    display: none;
    .width_btn_carousel {
      display: none;
    }
  }

  .btn_bottom {
    margin-top: 50px;
    margin-left: 0px;
    margin-bottom: 20px;
    width: 100%;
    height: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
    .
  }
  .theme_border_question {
    .btn_layout {
      padding: 10px;
      width: 180px;
      text-align: center;
      font-size: 11px;
    }
  }

  @media (max-width: 700px) {
    max-width: 80vw;
    margin-top: 50px;

    .carousel_wrap {
      width: 100%;
      .txt_grey {
        display: block;
        color: #8398a0;
        text-align: center;
        font-size: 12px;
      }
    }
    .button_layout {
      display: none;
      .width_btn {
        display: none;
        justify-content: none;
      }
    }
    .react-multi-carousel-dot button {
      width: 35px;
      border-radius: 0;
      margin-right: 0;
      height: 8px;
      border-color: #f7f7f7;
      border: none;
      background-color: #8398a0;
    }
    .react-multi-carousel-dot--active button {
      background: #348bae;
    }
    .button_layout_carousel {
      display: block;
      .width_btn_carousel {
        /* width: 650px; */
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        animation: 2s ${bounceAnimation};
      }
    }
    .react-multi-carousel-list {
      display: flex;
      align-items: center;
      /* height: 500px; */
      overflow: hidden;
      position: relative;
    }
    .layout_carousel {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 5px;
      margin-top: 10px;
      padding-bottom: 30px;
    }
    .theme_standard {
      width: 300px;
      display: flex;
      justify-content: center;
      .btn_layout {
        width: 300px;
      }
    }
  }

  @media (max-width: 700px) {
    max-width: 320px;
    margin-top: 0px;
    .theme_standard {
      width: 300px;
      display: flex;
      justify-content: center;
      .btn_layout {
        width: 300px;
      }
    }

    .theme_border_question {
      .btn_layout {
        padding: 10px;
        width: 130px;
        text-align: center;
      }
    }
  }
`;
