import React from 'react';
import { BoxHospitalStyled } from './styled';
import cx from 'classnames';
import { Buttons } from 'components';

const BoxHospital = ({ theme_box_hos_1, handleClickServiceOrder, data }) => {
  const customClass = cx({
    theme_box_hos_1: theme_box_hos_1,
  });
  return (
    <BoxHospitalStyled>
      <div className={customClass}>
        <div className="is_desktop">
          <div className="container_box_h">
            <div className="left_layout">
              <div
                className="box_img"
                style={
                  data && { backgroundImage: `url(${data.square_image_uri})` }
                }
              ></div>
            </div>
            <div className="right_layout">
              <div className="detail">
                <div
                  className="name_title"
                  dangerouslySetInnerHTML={data && { __html: data.name }}
                />
                <div
                  className="detail_sub"
                  dangerouslySetInnerHTML={
                    data && { __html: data.short_description }
                  }
                />
              </div>
              <div
                className="btn"
                onClick={() => handleClickServiceOrder(data)}
              >
                <Buttons.ButtonIcon
                  theme_pink_2
                  lable1Size="17px"
                  label1="เลือก"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="is_mobile">
          <div className="container_box_h">
            <div className="left_layout">
              <div
                className="box_img"
                style={
                  data && { backgroundImage: `url(${data.square_image_uri})` }
                }
              ></div>
            </div>
            <div className="right_layout">
              <div className="detail">
                <div
                  className="name_title"
                  dangerouslySetInnerHTML={data && { __html: data.name }}
                />
                <div
                  className="detail_sub"
                  dangerouslySetInnerHTML={
                    data && { __html: data.short_description }
                  }
                />
              </div>
              <div
                className="btn"
                onClick={() => handleClickServiceOrder(data)}
              >
                <Buttons.ButtonIcon
                  theme_pink_2
                  lable1Size="17px"
                  label1="เลือก"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BoxHospitalStyled>
  );
};

export default BoxHospital;
