import apiService from '../apiService';

const apiPath = '/api';
const apiUserPath = '/user-api';

export const userService = {
  POST_LOGIN_WITH_TOKEN: (params) => {
    return apiService.post(`${apiPath}/loginWithToken`, params);
  },

  POST_LOGIN: (params) => {
    return apiService.post(`${apiPath}/login`, params);
  },

  POST_SING_UP: (params) => {
    return apiService.post(`${apiPath}/users`, params);
  },

  GET_PROFILE_DETAIL: () => {
    return apiService.get(`${apiPath}/users/me`);
  },

  //ADDRESS
  GET_CHANGWAT_LIST: () => {
    return apiService.get(`${apiPath}/changwats`);
  },
  GET_AMPHOES_LIST: (changwat_id) => {
    return apiService.get(`${apiPath}/changwats/${changwat_id}/amphoes`);
  },
  GET_AMPHOES_NEAREST_LIST: (lat, long) => {
    return apiService.get(
      `${apiPath}/amphoes/nearest?latitude=${lat}&longitude=${long}`
    );
  },

  //COMPANY
  GET_COMPANY_LIST: () => {
    return apiService.get(`${apiPath}/companies`);
  },

  PUT_DOCTOR_NOTE_ADD: (params) => {
    return apiService.put(`${apiPath}/profiles/me`, params);
  },

  GET_SETTING_CONFIG_BY_DOMAIN: (domain) => {
    return apiService.get(`${apiPath}/settings/${domain}`);
  },

  // PinkAlert Campaign
  GET_PINKALERT_QUESTION_LIST: () => {
    return apiService.get(`${apiPath}/campaigns/pinkalert/questions`);
  },
  POST_PINKALERT_SEND_QUESTION: (params) => {
    return apiService.post(
      `${apiPath}/profiles/me/campaigns/pinkalert/screenings`,
      params
    );
  },
  // Herwill Campaign
  GET_HERWILL_QUESTION_LIST: () => {
    return apiService.get(`${apiPath}/campaigns/herwill/questions`);
  },
  POST_HERWILL_SEND_QUESTION: (params) => {
    return apiService.post(
      `${apiPath}/profiles/me/campaigns/herwill/screenings`,
      params
    );
  },

  GET_GATEWAY: (gateway_type, queryString) => {
    return apiService.get(
      `${apiPath}/gateways/${gateway_type}${queryString ? queryString : ''}`
    );
  },
  POST_GETWAY: (profile_id, answer) => {
    return apiService.post(
      `${apiPath}/profiles/${profile_id}/gateway_screenings`,
      answer
    );
  },

  POST_HERWILL_SEND_EMAIL: (screening_id, params) => {
    return apiService.post(
      `${apiPath}/profiles/me/campaigns/herwill/screenings/${screening_id}/shares`,
      params
    );
  },

  GET_HERWILL_LANDING_COUNT: () => {
    return apiService.get(`${apiPath}/campaigns/herwill/landing_count`);
  },
  GET_HERWILL_SCREEN_COUNT: () => {
    return apiService.get(`${apiPath}/campaigns/herwill/screening_count`);
  },

  GET_HEALTH_INSURANCE_SERVICE_LIST: () => {
    return apiService.get(
      `${apiPath}/service_categories/health_insurance_herwill/services`
    );
  },

  GET_MAMMOGRAM_CENTERS_LIST: (queryString) => {
    return apiService.get(
      `${apiPath}/mammogram_centers${queryString ? queryString : ''}`
    );
  },

  GET_SERVICE_CATEGORIES_HER_WELL_SERVICE_LIST: () => {
    return apiService.get(
      `${apiPath}/service_categories/cancer_checkup_herwill/services`
    );
  },

  // SERVICE ORDER
  POST_SERVICE_ORDER: (profile_id, params) => {
    return apiService.post(
      `${apiPath}/profiles/${profile_id}/service_orders`,
      params
    );
  },

  GET_CONTENT_CATEGORY: () => {
    return apiService.get(`${apiUserPath}/home_content_categories`);
  },
};
