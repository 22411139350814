import React from 'react';

export default function AaPlatform({
  color = '#9bdeac',
  width = "16.672",
  height = "30.061",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 16.672 30.061"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_31196"
            data-name="Rectangle 31196"
            width="16.067"
            height="30.061"
            fill={color}
          />
        </clipPath>
      </defs>
      <g
        id="Group_92321"
        data-name="Group 92321"
        transform="translate(9933.166 18830.001)"
      >
        <path
          id="Path_107619"
          data-name="Path 107619"
          d="M15.63,94.8l-3.076-8.781a1.357,1.357,0,0,0-1.33-.866H4.735a1.357,1.357,0,0,0-1.33.866L.328,94.8A1.327,1.327,0,0,0,1,96.452,1.3,1.3,0,0,0,2.771,95.7l1.522-4.343v.9l-1.936,6.58a.946.946,0,0,0,.51.971.958.958,0,0,0,.441.1H4.319v7.034a1.59,1.59,0,0,0,1.486,1.6,1.561,1.561,0,0,0,1.637-1.559v-6.541a.538.538,0,1,1,1.075,0v6.5a1.591,1.591,0,0,0,1.486,1.6,1.561,1.561,0,0,0,1.637-1.56V99.9h1.012a.959.959,0,0,0,.441-.1.946.946,0,0,0,.51-.97l-1.936-6.58v-.9L13.187,95.7a1.3,1.3,0,0,0,1.771.752A1.327,1.327,0,0,0,15.63,94.8"
          transform="translate(-9933.417 -18908.49)"
          fill={color}
        />
        <g
          id="Group_90143"
          data-name="Group 90143"
          transform="translate(-9932.562 -18830)"
        >
          <g
            id="Group_90142"
            data-name="Group 90142"
            transform="translate(0)"
            clip-path="url(#clip-path)"
          >
            <path
              id="Path_107620"
              data-name="Path 107620"
              d="M68.767,2.829A2.829,2.829,0,1,1,65.938,0a2.829,2.829,0,0,1,2.829,2.829"
              transform="translate(-58.794)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
