import styled from 'styled-components';

export const ContentWidgetStyled = styled.div`
  padding: 80px 64px;
  background: ${({ theme }) => theme.COLORS.PINK_9};
  .cw_header_row {
    margin-bottom: 58px;
    display: flex;
    column-gap: 16px;
    .cw_icon_wrap {
      padding-top: 6px;
      flex-shrink: 0;
    }
    .cw_icon_wrap_mobile {
      display: none;
    }
    .cw_title {
      display: flex;
      column-gap: 20px;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_30};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      .sub_text {
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
      }
    }
  }
  .cw_content {
    position: relative;
    padding: 0 72px;
    .content_item {
      height: 100%;
      padding: 8px 16px;
      width: 100%;
      max-width: 392px;
    }
  }

  .carousel_button_group {
    position: absolute;
    left: -39px;
    right: 0;
    width: calc(100vw - 64px);
    display: flex;
    justify-content: space-between;
    top: 45%;
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MEDIUM_DESKTOP}) {
    .cw_content {
      position: relative;
      padding: 0 18px;
      .content_item {
        padding: 8px 8px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    padding: 30px 25px 19px 25px;
    .cw_header_row {
      align-items: center;
      column-gap: 8px;
      margin-bottom: 10px;
      .cw_icon_wrap {
        display: none;
      }
      .cw_icon_wrap_mobile {
        display: flex;
      }
      .cw_title {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
        column-gap: 5px;
        align-items: center;
        .sub_text {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        }
      }
    }
    .cw_content {
      padding: 0px 20px;
      .content_item {
        padding: 4px;
      }
    }
    .carousel_button_group {
      width: 92vw;
      left: -11px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
    .carousel_button_group {
      width: 92vw;
      left: -11px;
    }
  }
`;

export const ButtonArrow = styled.div`
  border-radius: 40px;
  width: ${({ width }) => (width ? width : '40px')};
  height: ${({ height }) => (height ? height : '40px')};
  background: ${({ color }) => color};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
