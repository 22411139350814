import React from 'react';

export default function AaPlatform({ color = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
    >
      <path
        id="Path_274"
        data-name="Path 274"
        d="M7.757,10.586,9.172,9.172,12,12l2.828-2.828,1.414,1.414L12,14.828Z"
        transform="translate(-3.5 -3.5)"
        fill={color}
      />
      <path
        id="Path_275"
        data-name="Path 275"
        d="M1,4.091A3.091,3.091,0,0,1,4.091,1H14.909A3.091,3.091,0,0,1,18,4.091V14.909A3.091,3.091,0,0,1,14.909,18H4.091A3.091,3.091,0,0,1,1,14.909ZM4.091,2.545H14.909a1.545,1.545,0,0,1,1.545,1.545V14.909a1.545,1.545,0,0,1-1.545,1.545H4.091a1.545,1.545,0,0,1-1.545-1.545V4.091A1.545,1.545,0,0,1,4.091,2.545Z"
        transform="translate(-1 -1)"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
