import { RiskFactorContainerStyled } from './styled';
import { NavbarPinkAlertWidget } from 'widgets';
import { Buttons, Icons, Displays } from 'components';
import { PartnerWidget, TestShortcutWidget } from 'widgets';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'navigations/withRouter';
import ReactGA from 'react-ga';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { setReduxUserAuth } from 'store/actions';
import { Element } from 'react-scroll';
import queryString from 'query-string';
import { scroller } from 'react-scroll';

class RiskFactorContainer extends React.Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    const { scrollto } = queryString.parse(this.props.router.location.search);
    if (scrollto) {
      scroller.scrollTo(scrollto, {
        duration: 1000,
        delay: 100,
        smooth: true,
        // containerId: 'ContainerElementID',
        offset: this.renderOffset(), // Scrolls to element + 50 pixels down the page
      });
    }

    // Tracking user pageview
    ReactGA.set({ page: this.props.router.location.pathname });
    ReactGA.pageview(this.props.router.location.pathname);

    ReactGA.event({
      category: this.props.configProjectRedux.scheme + '_risk',
      action: this.props.configProjectRedux.scheme + '_risk_view',
    });
  }

  renderOffset = () => {
    let width = window.innerWidth;
    if (width > 1536) {
      return -112;
    }
    if (width > 1024) {
      return -102;
    }
    if (width > 540) {
      return -102;
    }
    return -80;
  };

  handleClickSetQuick = () => {
    ReactGA.event({
      category: this.props.configProjectRedux.scheme + '_start',
      action: this.props.configProjectRedux.scheme + '_start_new_sess',
    });
    this.props.router.navigate(ROUTE_PATH.AUTO_SIGNUP);
  };

  render() {
    const { isLoading } = this.state;
    return (
      <RiskFactorContainerStyled>
        <div className="navbar_pink_wrap">
          <NavbarPinkAlertWidget />
        </div>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <div className="risk_container">
            <div className="brand_show">
              <div className="box_label">
                <div className="title_show">คุณมีความเสี่ยงมากไหม ?</div>
                <div className="title_show">
                  กับ
                  <span className="bigText">"มะเร็งเต้านม"</span>
                </div>
                <div className="risk_btn_block">
                  <div className="icon_click_wrap">
                    <Icons.Click />
                  </div>
                  <div className="icon_click_wrap mobile">
                    <Icons.Click width="24" height="24" />
                  </div>
                  <Buttons.WellBtn
                    label={'คลิก ทำแบบทดสอบ'}
                    onClick={this.handleClickSetQuick}
                  />
                </div>
                <div className="risk_hastag">
                  #มะเร็งเต้านม รู้ไว โอกาสรักษาหายสูงกว่า
                </div>
                <div className="more_label">
                  อ่านเพิ่มเติมเกี่ยวกับอาการหรือลักษณะทั่วไปของกลุ่มเสี่ยง
                </div>
              </div>
            </div>
            <div className="risk_body">
              <img
                className="ribbin"
                alt="well"
                src="/assets/images/icons/rib_pink.png"
              />
              <Element name="risk_sec_1_desktop">
                <div className="risk_sec_1">
                  <div className="risk_title_1">
                    <div className="top_risk_title_1">
                      <img
                        className="zoom_icon"
                        alt="zoom"
                        src="/assets/images/icons/pinkZoom.png"
                      />
                      <div className="label_title_1">
                        คนที่มีความเสี่ยงในการเป็นมะเร็งเต้านม
                      </div>
                    </div>
                    <div className="sub_title_1">
                      แม้ว่าปัจจัยเสี่ยงต่อการเกิดมะเร็งเต้านม
                      มีทั้งที่ไม่สามารถควบคุมได้ และสามารถควบคุมได้
                      ลองมาดูว่าอะไรบ้างที่เป็นปัจจัยเสี่ยง
                      ต่อการเกิดมะเร็งเต้านม และค่อยๆ
                      ปรับพฤติกรรมเพื่อลดโอกาสในการเกิดมะเร็งเต้านม
                      เพื่อลดความเสี่ยงการเกิดโรค
                    </div>
                  </div>
                  <div className="risk_case">
                    <div className="box_label_top">
                      <img
                        className="yellow_label_1"
                        src="/assets/images/backgrounds/yellow_label.png"
                        alt="background"
                      />
                      <div className="notic_1">
                        ตัวอย่าง ปัจจัยเสี่ยงของมะเร็งเต้านม
                      </div>
                    </div>
                    <div className="group_notic">
                      <div className="box_line mb_4_vw">
                        <div className="notic_show">
                          <Displays.BoxNotic
                            title="น้ำหนักตัว"
                            detail="ไขมันในร่างกายทำหน้าที่หลัก ในการสร้างเอสโตรเจนแทนรังไข่ ทำให้เพิ่มความเสี่ยงมากขึ้น"
                            num="1"
                          />
                        </div>
                        <div className="notic_show">
                          <Displays.BoxNotic
                            title="การออกกำลังกาย"
                            detail="การออกกำลังกายตั้งแต่ 45-60 นาที ตั้งแต่ 5 วันขึ้นไปต่อสัปดาห์ สามารถลดความเสี่ยงจากการเป็นมะเร็งเต้านมได้"
                            num="2"
                          />
                        </div>
                      </div>
                      <div className="box_line mb_49">
                        <div className="notic_show">
                          <Displays.BoxNotic
                            title="การดื่มแอลกอฮอล์"
                            detail="แอลกอฮอล์ทำให้การทำงานของตับลดลง และสูญเสียการควบคุมปริมาณเอสโตรเจนในร่างกาย ซึ่งเป็นสาเหตุที่ทำให้เกิดมะเร็ง"
                            num="3"
                          />
                        </div>
                        <div className="notic_show ">
                          <Displays.BoxNotic
                            title="ยาคุมกำเนิด"
                            detail="การใช้ยาคุมกำเนิดในช่วงเวลาที่จำกัด มีความเสี่ยงในการเพิ่มโอกาสเป็นมะเร็งเต้านมเพียงเล็กน้อย"
                            num="4"
                          />
                        </div>
                      </div>
                      <div className="link_text">
                        ที่มา :
                        https://www.thaibreast.org/TH/knowledge/______.html
                      </div>
                    </div>
                  </div>
                </div>
              </Element>
              <Element name="risk_desktop">
                <div className="risk_sec_2">
                  <div className="risk_title_2 bottom_show">
                    <div className="top_risk_title_2">
                      <img
                        className="zoom_icon_bottom"
                        alt="zoom"
                        src="/assets/images/icons/pinkZoom.png"
                      />
                      <div className="label_title_2">อาการมะเร็งเต้านม</div>
                    </div>
                    <div className="sub_title_2">
                      โรคมะเร็งเต้านมในระยะแรกๆ มักจะไม่แสดงอาการ
                      เราจึงจำเป็นที่จะต้องหมั่นสังเกตอาการเตือนต่างๆ
                      ที่เกิดขึ้นกับเต้านมเพราะหากว่าพบเร็วโอกาสการรักษาให้หายก็จะดีกว่า
                      และลดโอกาสในการตัดเต้านมออกทั้งเต้า
                    </div>
                  </div>
                  <div className="risk_case_bottom">
                    <div className="box_label_bottom">
                      <img
                        className="yellow_label_2"
                        alt="notic"
                        src="/assets/images/backgrounds/yellow_label_2.png"
                      />
                      <div className="notic_2">
                        3 อาการเริ่มต้นที่อาจสังเกตได้
                      </div>
                    </div>
                    <div className="group_notic_2">
                      <div className="group_sym">
                        <div className="box_sym">
                          <img
                            className="sym_img"
                            alt="sym"
                            src={'/assets/images/icons/symptom_1.png'}
                          />
                          <img
                            className="sym_img moblie"
                            alt="sym"
                            src={'/assets/images/icons/symptom_1_moblie.png'}
                          />
                          <div className="text_des">
                            คลำพบก้อนเนื้อ บริเวณเต้านมหรือรักแร้
                          </div>
                        </div>
                        <div className="box_sym">
                          <img
                            className="sym_img"
                            alt="sym"
                            src={'/assets/images/icons/symptom_2.png'}
                          />
                          <img
                            className="sym_img moblie"
                            alt="sym"
                            src={'/assets/images/icons/symptom_2_moblie.png'}
                          />
                          <div className="text_des">
                            เจ็บหรือปวดเต้านม ที่ไม่สัมพันธ์กับประจำเดือน
                          </div>
                        </div>
                        <div className="box_sym">
                          <img
                            className="sym_img"
                            alt="sym"
                            src={'/assets/images/icons/symptom_3.png'}
                          />
                          <img
                            className="sym_img moblie"
                            alt="sym"
                            src={'/assets/images/icons/symptom_3_moblie.png'}
                          />
                          <div className="text_des">
                            เต้านมมีขนาดหรือรูปร่าง ที่เปลี่ยนแปลงไป
                          </div>
                        </div>
                      </div>
                      <div className="link_text_2">
                        ที่มา :
                        https://www.thaibreast.org/TH/knowledge/______.html
                      </div>
                    </div>
                  </div>
                </div>
              </Element>
            </div>
            <div className="mobile_risk_body">
              <img
                className="ribbin_mobile"
                alt="well"
                src="/assets/images/icons/rib_pink.png"
              />
              <Element name="risk_sec_1_mobile">
                <div className="m_sec_1">
                  <div className="m_header_row">
                    <img
                      className="zoom_icon_bottom_mobile"
                      alt="zoom"
                      src="/assets/images/icons/pinkZoom.png"
                    />
                    <span className="m_title">
                      คนที่มีความเสี่ยงในการเป็นมะเร็งเต้านม
                    </span>
                  </div>
                  <div className="m_sub_title">
                    แม้ว่าปัจจัยเสี่ยงต่อการเกิดมะเร็งเต้านม มีทั้งที่ไม่สามารถ
                    ควบคุมได้ และสามารถควบคุมได้ ลองมาดูว่าอะไรบ้างที่เป็น
                    ปัจจัยเสี่ยงต่อการเกิดมะเร็งเต้านมและค่อย ๆ ปรับพฤติกรรม
                    เพื่อลดโอกาสในการเกิดมะเร็งเต้านม
                    เพื่อลดความเสี่ยงการเกิดโรค
                  </div>
                  <div className="m_title_highligh">
                    ตัวอย่าง ปัจจัยเสี่ยงของมะเร็งเต้านม
                  </div>
                  <div className="s1_body">
                    <div className="box_item_wrap">
                      <Displays.BoxNotic
                        title="น้ำหนักตัว"
                        detail="ไขมันในร่างกายทำหน้าที่หลัก ในการสร้างเอสโตรเจนแทนรังไข่ ทำให้เพิ่มความเสี่ยงมากขึ้น"
                        num="1"
                      />
                    </div>
                    <div className="box_item_wrap">
                      <Displays.BoxNotic
                        title="การออกกำลังกาย"
                        detail="การออกกำลังกายตั้งแต่ 45-60 นาที ตั้งแต่ 5 วันขึ้นไปต่อสัปดาห์ สามารถลดความเสี่ยงจากการเป็นมะเร็งเต้านมได้"
                        num="2"
                      />
                    </div>
                    <div className="box_item_wrap">
                      <Displays.BoxNotic
                        title="การดื่มแอลกอฮอล์"
                        detail="แอลกอฮอล์ทำให้การทำงานของตับลดลง และสูญเสียการควบคุมปริมาณเอสโตรเจนในร่างกาย ซึ่งเป็นสาเหตุที่ทำให้เกิดมะเร็ง"
                        num="3"
                      />
                    </div>
                    <div className="box_item_wrap mb_8">
                      <Displays.BoxNotic
                        title="ยาคุมกำเนิด"
                        detail="การใช้ยาคุมกำเนิดในช่วงเวลาที่จำกัด มีความเสี่ยงในการเพิ่มโอกาสเป็นมะเร็งเต้านมเพียงเล็กน้อย"
                        num="4"
                      />
                    </div>
                  </div>
                  <div className="s1_footer">
                    <div className="s1_f_link">
                      ที่มา : https://www.thaibreast.org/TH
                      /knowledge/______.html
                    </div>
                    <img
                      className="s1_f_img"
                      alt="risk"
                      src="/assets/images/icons/risk-famale.png"
                    />
                  </div>
                </div>
              </Element>
              <Element name="risk_mobile">
                <div className="m_sec_2">
                  <div className="m_header_row">
                    <img
                      className="zoom_icon_bottom"
                      alt="zoom"
                      src="/assets/images/icons/pinkZoom.png"
                    />
                    <div className="m_title">อาการมะเร็งเต้านม</div>
                  </div>
                  <div className="m_sub_title">
                    โรคมะเร็งเต้านมในระยะแรกๆ มักจะไม่แสดงอาการ เราจึงจำ
                    เป็นที่จะต้องหมั่นสังเกตอาการเตือนต่างๆ ที่เกิดขึ้นกับเต้านม
                    เพราะหากว่าพบเร็วโอกาสการรักษาให้หายก็จะดีกว่า และลด
                    โอกาสในการตัดเต้านมออกทั้งเต้า
                  </div>
                  <div className="m_title_highligh">
                    3 อาการเริ่มต้นที่อาจสังเกตได้
                  </div>
                  <div className="s2_body">
                    <div className="m_box_sym_item">
                      <img
                        className="m_sym_img"
                        alt="sym"
                        src={'/assets/images/icons/symptom_1_moblie.png'}
                      />
                      <div className="m_text_des">
                        คลำพบก้อนเนื้อ บริเวณเต้านมหรือรักแร้
                      </div>
                    </div>
                    <div className="m_box_sym_item">
                      <img
                        className="m_sym_img"
                        alt="sym"
                        src={'/assets/images/icons/symptom_2_moblie.png'}
                      />
                      <div className="m_text_des">
                        เจ็บหรือปวดเต้านม ที่ไม่สัมพันธ์กับประจำเดือน
                      </div>
                    </div>
                    <div className="m_box_sym_item">
                      <img
                        className="m_sym_img"
                        alt="sym"
                        src={'/assets/images/icons/symptom_3_moblie.png'}
                      />
                      <div className="m_text_des">
                        เต้านมมีขนาดหรือรูปร่าง ที่เปลี่ยนแปลงไป
                      </div>
                    </div>
                  </div>
                  <div className="s2_footer">
                    <div className="s2_f_link">
                      ที่มา : https://www.thaibreast.org/TH
                      /knowledge/______.html{' '}
                    </div>
                    <img
                      className="s2_f_img"
                      alt="risk"
                      src="/assets/images/icons/risk-famale-2.png"
                    />
                  </div>
                </div>
              </Element>
            </div>
            <div className="footer_layout">
              <PartnerWidget noBg />
              <TestShortcutWidget onClickSetQuck={this.handleClickSetQuick} />
              <div className="label_number">M-TH-00002353</div>
            </div>
          </div>
        )}
      </RiskFactorContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RiskFactorContainer));
