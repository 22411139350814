import styled from 'styled-components';

export const HerwillCampaignWidgetStyled = styled.div`
  background: ${({ theme }) => theme.COLORS.PINK_9};
  padding: 47px 12px 42px 12px;
  .hc_container {
    margin: auto;
    max-width: 1483px;
    display: flex;
    align-items: center;
    column-gap: 56px;
    .hc_left_col {
      flex-shrink: 0;
      flex-basis: 376px;
      .hc_logo {
        width: 100%;
        height: auto;
      }
    }
    .hc_right_col {
      flex: 1;
      .hcr_title {
        margin-bottom: 42px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_36};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
      }
      .hcr_row {
        margin-bottom: 32px;
        display: flex;
        column-gap: 24px;
        &.mb_0 {
          margin-bottom: 0px;
        }
        .hcr_circle {
          flex-shrink: 0;
        }
        .hcr_circle_mobile {
          flex-shrink: 0;
          display: none;
        }
        .hcr_description {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_26};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          color: ${({ theme }) => theme.COLORS.BROWN_1};
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MEDIUM_DESKTOP}) {
    .hc_container {
      .hc_left_col {
        flex-basis: 306px;
      }
      .hc_right_col {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_32};
        .hcr_row {
          .hcr_description {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    padding: 10px 29px;
    .hc_container {
      flex-direction: column;
      .hc_left_col {
        flex-basis: auto;
      }
      .hc_right_col {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_28};
        .hcr_title {
          margin-bottom: 18px;
        }
        .hcr_row {
          margin-bottom: 12px;
          .hcr_description {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
          }
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    padding: 10px 29px;
    .hc_container {
      flex-direction: column;
      .hc_left_col {
        flex-basis: auto;
        .hc_logo {
          width: 115px;
        }
      }
      .hc_right_col {
        .hcr_title {
          margin-bottom: 12px;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        }
        .hcr_row {
          margin-bottom: 12px;
          .hcr_circle {
            display: none;
          }
          .hcr_circle_mobile {
            display: flex;
          }
          .hcr_description {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          }
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
    padding: 10px 29px;
    .hc_container {
      flex-direction: column;
      .hc_left_col {
        flex-basis: auto;
        .hc_logo {
          width: 140px;
        }
      }
    }
  }
`;
