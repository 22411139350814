import styled from 'styled-components';

export const DateAntStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  .ant-picker {
    background: none;
    border: none;
    border-bottom: 2px solid #2eb7b8;
    width: 320px;
    color: black;
  }
  @media (max-width: 700px) {
    .ant-picker {
      width: 320px;
    }
  }
`;
