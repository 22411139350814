import React from 'react';

export default function AaPlatform({
  width = "40",
  height = "40",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 40 40"
    >
      <defs>
        <filter
          id="Union_352"
          x="4.965"
          y="7"
          width="33.518"
          height="30.777"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="1.5" result="blur" />
          <feFlood flood-opacity="0.051" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_90189"
        data-name="Group 90189"
        transform="translate(-1998 -4990)"
      >
        <path
          id="Path_107680"
          data-name="Path 107680"
          d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z"
          transform="translate(1998 4990)"
          fill="#ed799e"
        />
        <g transform="matrix(1, 0, 0, 1, 1998, 4990)" filter="url(#Union_352)">
          <path
            id="Union_352-2"
            data-name="Union 352"
            d="M6.235,20.109l-1.66-3.873,2.17-.541,1.52,3.543a1.1,1.1,0,0,1-2.03.87Zm9.455-3.168a1.108,1.108,0,0,1-.506-.684l-.068-.271a2.387,2.387,0,0,1-.321-.171c-1.539-.965-4.446-1.018-6.234-.571l-.81.2L6.209,9.26l1.542,6.184-3.7.922a1.088,1.088,0,0,1-1.318-.792L1.722,11.5a1.086,1.086,0,0,1,.792-1.318l3.7-.922.81-.2c1.789-.445,4.33-1.857,5.236-3.431a2.456,2.456,0,0,1,.2-.3l2.244,9L12.391,5.054a1.112,1.112,0,0,1,2.157-.538l2.794,11.2a1.112,1.112,0,0,1-1.651,1.221ZM1.2,15.256a1.431,1.431,0,0,1-.653-.883l-.2-.781A1.437,1.437,0,0,1,1.394,11.85l.391-.1.889,3.568-.391.1A1.43,1.43,0,0,1,1.2,15.256ZM21.865,9.141c-.729-.857-1.729-2.03-2.531-3.106C18.183,4.49,17.951,3.82,17.959,3.53a4.806,4.806,0,0,1,.6-2.209,4.828,4.828,0,0,0,.808,1.9,40.421,40.421,0,0,0,4.287,5.25L22.622,10.04C22.435,9.81,22.175,9.507,21.865,9.141Zm-4.5-.674c.714-.783,1.034-1.173,1.6-1.858l.2-.24c.341.454.725.935,1.131,1.429-.4.484-.79.941-1.133,1.343-.31.365-.571.669-.757.9Zm3.292-4.009a14.151,14.151,0,0,0,1.473-2.366,3.488,3.488,0,0,0,.282-.853,4.792,4.792,0,0,1,.65,2.292c.007.278-.206.912-1.255,2.343Q21.156,5.1,20.659,4.459ZM18.959.715a2.038,2.038,0,0,1,3.1,0c.086.231.055.657-.331,1.436a2.364,2.364,0,0,0-1.219-.337,2.39,2.39,0,0,0-1.316.4A1.93,1.93,0,0,1,18.959.715Z"
            transform="translate(7.7 10)"
            fill="#fff"
            stroke="rgba(0,0,0,0)"
            stroke-miterlimit="10"
            stroke-width="1"
          />
        </g>
      </g>
    </svg>
  );
}
