import React from 'react';
import { VideoSearchContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import moment from 'moment';
import 'moment-timezone';
import { herwillService } from 'apiService';
import queryString from 'query-string';
import { SCROLL_TO_TOP } from 'utils/functions/scroll';
import { FilterVideoWidget } from 'widgets';
import { Cards, Displays } from 'components';

class VideoSearchContainer extends React.Component {
  state = { isLoading: false };

  componentDidMount() {
    SCROLL_TO_TOP();
    const { keywords, categories } = queryString.parse(
      this.props.router.location.search
    );
    let query = [];
    if (keywords) {
      query.push(`keywords=${keywords}`);
    }
    if (categories) {
      query.push(`categories=${categories}`);
    }
    query = query.length > 0 ? `?${query.join('&')}` : false;
    this.fetchData(query);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.router.location.search !== prevProps.router.location.search
    ) {
      const { keywords, categories } = queryString.parse(
        this.props.router.location.search
      );
      let query = [];
      if (keywords) {
        query.push(`keywords=${keywords}`);
      }
      if (categories) {
        query.push(`categories=${categories}`);
        this.handleCheckCategory(categories);
      }
      query = query.length > 0 ? `?${query.join('&')}` : false;
      this.fetchData(query);
    }
  }

  fetchData = async (query) => {
    let res = await herwillService.GET_CONTENT_LIST(query);
    if (res && res.success) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    }
  };

  handleFilter = (e) => {
    const { categories } = queryString.parse(this.props.router.location.search);
    this.setState((state) => ({
      filterSelected: e,
      categoryCurrent: categories === e.value ? e.label : state.categoryCurrent,
    }));
  };

  handleCheckCategory = (categories) => {
    const { filterSelected } = this.state;
    this.setState((state) => ({
      categoryCurrent:
        categories === filterSelected.value
          ? filterSelected.label
          : state.categoryCurrent,
    }));
  };

  render() {
    const { data, isLoading, categoryCurrent } = this.state;
    return (
      <VideoSearchContainerStyled>
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <>
            <FilterVideoWidget
              labelType="label"
              hideSearch
              onFilter={this.handleFilter}
              grayLabel
            />
            {/* <div className="pink_line" /> */}
            <div className="content_container">
              <div className="category_label">{categoryCurrent}</div>
              {!data || data.length === 0 ? (
                <div className="label_empty">ไม่พบการค้นหา</div>
              ) : (
                data.map((e, i) => (
                  <div key={i} className="preview_item">
                    <Cards.PreviewVideoRec
                      src={e.cover_uri}
                      hastagList={e.tags.map((f) => `#${f.tag} `)}
                      topic={e.topic}
                      viewCount={e.total_view_count}
                      postDate={moment(e.created_at)
                        .tz('Asia/Bangkok')
                        .format('DD MMM. YYYY')}
                      desciption={e.short_body}
                      uuid={e.uuid}
                    />
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </VideoSearchContainerStyled>
    );
  }
}

export default withRouter(VideoSearchContainer);
