import styled from 'styled-components';

export const BannerPopupStyled = styled.div`
.box_popup {
    position: relative;
    .svg_close {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        background: white;
        display: flex;
        padding: 5px;
    }
    .img_branner {
        max-width: 500px;
    }
}

@media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
    .box_popup {
        .img_branner {
          max-width: 340px;
        }
      }
}

  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_PHONE}) {
    .box_popup {
      .img_branner {
        max-width: 250px;
      }
    }
  }
`;
