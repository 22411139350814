import React from 'react';

export default function AaPlatform({ color = '#8e8e8e' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="6"
      viewBox="0 0 6 6"
    >
      <circle
        id="Ellipse_6034"
        data-name="Ellipse 6034"
        cx="3"
        cy="3"
        r="3"
        fill={color}
      />
    </svg>
  );
}
