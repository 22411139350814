import styled from 'styled-components';

export const VideoHerwillWidgetStyled = styled.div`
  padding: 68px 80px;
  .vh_line_between {
    margin: 42px 0;
    height: 2px;
    background: ${({ theme }) => theme.COLORS.PINK_4};
  }
  .video_block_mobile {
    display: none;
    margin-bottom: 19px;
    padding-bottom: 30px;
    border-bottom: 2px solid ${({ theme }) => theme.COLORS.PINK_6};
    &:last-child {
      margin-bottom: 0px;
      border-bottom: none;
      padding-bottom: 0px;
    }
    .vb_head_row {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      .vb_title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
        font-weight: 600;
        color: ${({ theme }) => theme.COLORS.BLACK_6};
        &.symbol {
          font-weight: 400;
          color: ${({ theme }) => theme.COLORS.GRAY_5}4F;
        }
        &.margin {
          margin: 0 12px;
        }
      }
      .vb_link {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
        color: ${({ theme }) => theme.COLORS.GRAY_17};
        text-decoration: underline;
        cursor: pointer;
        flex-shrink: 0;
      }
    }
    .carousel_container_mobile {
      display: flex;
      column-gap: 10px;
      width: 100%;
      overflow: auto;
      .prview_item_wrap {
        cursor: pointer;
        min-width: 240px;
      }
    }
  }
  .video_block {
    margin-bottom: 19px;
    padding-bottom: 30px;
    border-bottom: 2px solid ${({ theme }) => theme.COLORS.PINK_6};
    &:last-child {
      margin-bottom: 0px;
      border-bottom: none;
      padding-bottom: 0px;
    }
    .vb_head_row {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      .vb_title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_24};
        font-weight: 600;
        color: ${({ theme }) => theme.COLORS.BLACK_6};
        &.symbol {
          font-weight: 400;
          color: ${({ theme }) => theme.COLORS.GRAY_5}4F;
        }
        &.margin {
          margin: 0 12px;
        }
      }
      .vb_link {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
        color: ${({ theme }) => theme.COLORS.GRAY_17};
        text-decoration: underline;
        cursor: pointer;
        flex-shrink: 0;
      }
    }
    .carousel_container {
      .react-multiple-carousel__arrow--left {
        left: calc(1% + 1px);
        margin-top: -4%;
      }
      .react-multiple-carousel__arrow--right {
        right: calc(1% + 1px);
        margin-top: -4%;
      }

      .prview_item_wrap {
        flex: 1;
        padding: 0 5px;
        overflow: hidden;
        cussor: pointer;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    padding: 20px;
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    padding: 20px 0px;
    .box_filter {
      padding: 0px 20px;
    }
    .video_block_mobile {
      display: block;
      .vb_head_row {
        justify-content: space-between;
        padding: 0px 20px;
        .vb_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
          &.symbol {
            display: none;
          }
        }
        .vb_link {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
        }
      }
      .carousel_container_mobile {
      
      }
    }
    .video_block {
      display: none;
      .vb_head_row {
        justify-content: space-between;
        .vb_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
          &.symbol {
            display: none;
          }
        }
        .vb_link {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
  }
`;
