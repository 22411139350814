import styled from 'styled-components';

export const InsurancePackageContainerStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  // background: ${({ theme }) => theme.COLORS.GRAY_9};
  min-height: 100vh;

  padding: 20px 0px;
  .loader_layout {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bg_top_package {
    display: none;
    position: absolute;
    z-index: -1;
    height: 53vw;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .group_pack {
    .link_back {
      padding: 0px 60px;
      .destop {
        display: block;
      }
      .mobile {
        display: none;
      }
    }
    .container {
      max-width: 800px;
      margin: auto;
      .title {
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .group_pack {
      .link_back {
        padding: 0px 20px;
      }
      .container {
        padding: 0px 20px;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_TABLET}) {
    padding: 0px;

    .bg_top_package {
      display: block;
    }
    .group_pack {
      padding: 10px 0px;
      .link_back {
        .destop {
          display: none;
        }
        .mobile {
          display: block;
        }
      }
      .container {
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    .group_pack {
      .link_back {
      }
      .container {
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
    .group_pack {
      .container {
        padding: 0px 20px;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MINI_PHONE}) {
    .group_pack {
      .container {
        padding: 0px 20px;
      }
    }
  }
`;
