import apiHerwillService from '../apiHerwillService';

const apiPath = '/user-api';

export const herwillService = {
  POST_LOGIN_WITH_TOKEN: (params) => {
    return apiHerwillService.post(`${apiPath}/loginWithToken`, params);
  },

  POST_LOGIN: (params) => {
    return apiHerwillService.post(`${apiPath}/login`, params);
  },

  GET_CONTENT_CATEGORY: (queryString) => {
    return apiHerwillService.get(
      `${apiPath}/content_categories${queryString ? queryString : ''}`
    );
  },

  GET_HOME_RECOMMEND_CONTENT: () => {
    return apiHerwillService.get(
      `${apiPath}/home_recommend_content`
    );
  },

  // CONTENT
  GET_CONTENT_LIST: (queryString) => {
    return apiHerwillService.get(
      `${apiPath}/contents${queryString ? queryString : ''}`
    );
  },
  GET_CONTENT_DETAIL: (content_uuid) => {
    return apiHerwillService.get(`${apiPath}/contents/${content_uuid}`);
  },
  PATCH_CONTENT_DETAIL: (content_uuid, params) => {
    return apiHerwillService.patch(
      `${apiPath}/contents/${content_uuid}/me`,
      params
    );
  },
  GET_CONTENT_SUGGEST_LIST: (content_uuid) => {
    return apiHerwillService.get(
      `${apiPath}/contents/${content_uuid}/suggest_contents`
    );
  },

  GET_HOME_CONTENT_LIST: () => {
    return apiHerwillService.get(`${apiPath}/home_content_categories`);
  },

  //SHARE VIDEO
  POST_SHARE_VIDEO: (content_uuid,params) => {
    return apiHerwillService.post(`${apiPath}/contents/${content_uuid}/shares`, params);
  },
};
