import styled from 'styled-components';

export const ShareVideoWidgetStyled = styled.div`
  border-radius: 8px;
  padding: 16px 16px 32px 16px;
  min-width: 488px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .sv_row {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
    font-weight: 400;
    color: ${({ theme }) => theme.COLORS.GRAY_18};
    .close_wrap {
      cursor: pointer;
    }
  }
  .share_row {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    column-gap: 15px;
    .icon_share {
      &.share {
        padding: 3px;
        cursor: pointer;
      }
    }
  }
  .clipboard {
    border-radius: 5px;
    margin: auto;
    padding: 11px 14px 11px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${({ theme }) => theme.COLORS.BLACK_10};
    .cb_link {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      width: 95%;
      line-break: anywhere;
    }
    .cb_copy {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_13};
      color: ${({ theme }) => theme.COLORS.BLUE_10};
      cursor: pointer;
      padding-top: 1px;
      padding-left: 5px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    max-width: 300px;
    min-width: unset;
    .clipboard {
      max-width: 324px;
    }
  }
`;
