import styled from 'styled-components';

export const VideoRecWidgetStyled = styled.div`
  padding: 80px 64px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .vrw_header_row {
    display: flex;
    column-gap: 16px;
    .vrw_icon_wrap {
      padding-top: 6px;
      flex-shrink: 0;
    }
    .vrw_icon_wrap_moblie {
      display: none;
    }
    .vrw_title {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_30};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      display: flex;
      column-gap: 20px;
      .sub_text {
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
      }
    }
  }
  .preview_item {
    margin: 40px 82px 65px 82px;
  }
  .box_filter {
    margin-bottom: 20px;
    padding: 0px 82px;
  }
  .video_block_mobile {
    display: none;
    margin-bottom: 19px;
    padding-bottom: 30px;
    border-bottom: 2px solid ${({ theme }) => theme.COLORS.PINK_6};
    &:last-child {
      margin-bottom: 0px;
      border-bottom: none;
      padding-bottom: 0px;
    }
    .vb_head_row {
      margin-bottom: 23px;
      display: flex;
      align-items: center;
      .vb_title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        &.symbol {
          font-weight: 400;
          color: ${({ theme }) => theme.COLORS.GRAY_5}4F;
        }
        &.margin {
          margin: 0 12px;
        }
      }
      .vb_link {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
        color: ${({ theme }) => theme.COLORS.GRAY_17};
        text-decoration: underline;
        cursor: pointer;
        flex-shrink: 0;
      }
    }
    .carousel_container_mobile {
      display: flex;
      column-gap: 10px;
      width: 100%;
      overflow: auto;
      .prview_item_wrap {
        cursor: pointer;
        min-width: 240px;
      }
    }
  }
  .video_block {
    padding: 0px 82px 0px 82px;
    &:last-child {
      margin-bottom: 0px;
      border-bottom: none;
      padding-bottom: 0px;
    }
    .vb_head_row {
      margin-bottom: 23px;
      display: flex;
      align-items: center;
      .vb_title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_22};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        &.symbol {
          font-weight: 400;
          color: ${({ theme }) => theme.COLORS.GRAY_5}4F;
        }
        &.margin {
          margin: 0 12px;
        }
      }
      .vb_link {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
        color: ${({ theme }) => theme.COLORS.GRAY_17};
        text-decoration: underline;
        cursor: pointer;
        flex-shrink: 0;
      }
    }
    .carousel_container {
      position: relative;
      .react-multiple-carousel__arrow--left {
        left: calc(1% + 1px);
        margin-top: -4%;
      }
      .react-multiple-carousel__arrow--right {
        right: calc(1% + 1px);
        margin-top: -4%;
      }

      .prview_item_wrap {
        flex: 1;
        padding: 0 5px;
        overflow: hidden;
        cussor: pointer;
      }
    }
    .carousel_button_group {
      position: absolute;
      left: -121px;
      right: 0;
      width: calc(100vw - 64px);
      display: flex;
      justify-content: space-between;
      top: 30%;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MEDIUM_DESKTOP}) {
    .preview_item {
      margin: 40px 20px 65px 20px;
    }
    .box_filter {
      padding: 0px 20px;
    }
    .video_block {
      padding: 0px 20px 0px 20px;
      .carousel_button_group {
        left: -58px;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    .preview_item {
      margin: 20px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    padding: 30px 16px 19px 16px;
    .vrw_header_row {
      .vrw_icon_wrap {
        display: none;
      }
      .vrw_icon_wrap_moblie {
        display: flex;
        padding-top: 7px;
      }
      .vrw_title {
        column-gap: 5px;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        .sub_text {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
        }
      }
    }
    .box_filter {
      padding: 0px 20px;
      padding-right: 0px;
    }
    .video_block_mobile {
      .vb_head_row {
        justify-content: space-between;
        padding: 0px 20px;
        .vb_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
          &.symbol {
            display: none;
          }
        }
        .vb_link {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
        }
      }
      .carousel_container_mobile {
      }
    }
    .video_block {
      margin-bottom: 0px;
      border-bottom: none;
      padding-bottom: 0px;
      .vb_head_row {
        justify-content: space-between;
        .vb_title {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
          &.symbol {
            display: none;
          }
        }
        .vb_link {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
        }
      }
      .carousel_button_group {
        width: 92vw;
        left: -21px;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.PHONE}) {
    .video_block {
      .carousel_button_group {
        width: 92vw;
        left: -21px;
      }
    }
  }
`;

export const ButtonArrow = styled.div`
  border-radius: 40px;
  width: ${({ width }) => (width ? width : '40px')};
  height: ${({ height }) => (height ? height : '40px')};
  background: ${({ color }) => color};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
