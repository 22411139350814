import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { HastagStyled } from './styled';

const Hastag = ({ theme_standard, label }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <HastagStyled>
      <div className={customClass}>
        <div className="hastag_label">{label}</div>
      </div>
    </HastagStyled>
  );
};

Hastag.propTypes = {};

export default Hastag;
