import styled from 'styled-components';

export const TreatmentGuildlineWidgetStyled = styled.div`
  position: relative;
  min-height: 138vh;
  padding: 40px 64px;
  overflow: hidden;
  .tg_img_head {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: -1;
  }
  .tg_img_footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: -1;
  }
  .tg_header_row {
    margin-bottom: 48px;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    column-gap: 52px;
    .tr_heart {
      width: 78.92px;
      height: 73px;
    }
    .icon_show_mobile {
      display: none;
      .tr_heart_mobile {
        width: 21.23px;
        height: 19.64px;
      }
    }
    .tg_title_1 {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_48};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      text-align: center;
    }
  }
  .tg_body {
    margin: auto;
    width: 100%;
    max-width: 1594px;
    display: flex;
    column-gap: 44px;
    .tg_left_col {
      flex-basis: 48%;
      .statistic_container {
        height: 100%;
        border-radius: 50px;
        -webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.09);
        padding: 32px 79px 45px 79px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        .st_title {
          margin-bottom: 40px;
          text-align: center;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_30};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          .pink_highligh {
            color: ${({ theme }) => theme.COLORS.PINK_8};
            white-space: nowrap;
          }
        }
        .box_percent {
          height: calc(100% - 45%);
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .st_row {
            // margin-bottom: 24px;
            display: flex;
            align-items: center;
            // &.mb_0 {
            //   margin-bottom: 0px;
            // }
            .st_label {
              flex-shrink: 0;
              flex-basis: 112px;
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
              font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
              color: ${({ theme }) => theme.COLORS.BLACK_1};
            }
          }
          .text_det {
            margin-top: 10px;
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
            text-align: center;
          }
        }
      }
    }
    .tg_right_col {
      flex-basis: 52%;
      .treatment_patien_container {
        border-radius: 50px;
        -webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.09);
        box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.09);
        padding: 32px 0px 25px 0px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        .tg_title {
          margin-bottom: 24px;
          text-align: center;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_30};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          .pink_highligh {
            color: ${({ theme }) => theme.COLORS.PINK_8};
          }
        }
        .tg_content_row {
          display: flex;
          &.moblie {
            display: none;
          }
          .tg_item_wrap {
            flex-basis: 33.33%;
            &.border_left_right {
              border-left: 1px solid ${({ theme }) => theme.COLORS.PINK_10}80;
              border-right: 1px solid ${({ theme }) => theme.COLORS.PINK_10}80;
            }
            .img_virus {
              width: 68px;
              height: 62px;
              &.small {
                width: 30px;
                height: 28px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.MEDIUM_DESKTOP}) {
    padding: 40px;
    .tg_header_row {
      margin-top: 20px;
      column-gap: 20px;
      .tr_heart {
        width: 58px;
        height: 54px;
      }
      .tg_title_1 {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_44};
      }
    }
    .tg_body {
      .tg_left_col {
        .statistic_container {
          .st_title {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_26};
          }
          .box_percent {
            height: calc(100% - 51%);
            .st_row {
              .st_label {
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
              }
            }
          }
        }
      }
      .tg_right_col {
        .treatment_patien_container {
          .tg_title {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_26};
          }
          .tg_content_row {
          }
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    padding: 85px 24px;
    .tg_header_row {
      .tg_title_1 {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_38};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
      }
    }
    .tg_body {
      max-width: 636px;
      flex-direction: column;
      row-gap: 16px;
      .tg_left_col {
        .statistic_container {
          padding: 40px;
          .box_percent {
            height: unset;
            row-gap: 9px;
          }
        }
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    padding: 24px;
    padding-top: 40px;
    height: 730px;
    min-height: unset;
    .tg_img_footer {
      width: 150%;
      left: -25%;
    }
    .tg_header_row {
      margin-bottom: 17px;
      column-gap: 5px;
      margin-top: 5px;
      padding-top: 0px;
      .tr_heart {
        display: none;
      }
      .icon_show_mobile {
        display: flex;
      }
      .tg_title_1 {
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_18};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
      }
    }
    .tg_body {
      .tg_left_col {
        .statistic_container {
          padding: 16px 23px;
          border-radius: 18px;
          .st_title {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
            margin-bottom: 14px;
          }
          .box_percent {
            .st_row {
              // margin-bottom: 9px;
              .st_label {
                font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
                font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
                flex-basis: 60px;
              }
            }
            .text_det {
              font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_12};
            }
          }
        }
      }
      .tg_right_col {
        .treatment_patien_container {
          border-radius: 18px;
          .tg_title {
            font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
            font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
            margin-bottom: 14px;
          }
          .tg_content_row {
            display: none;
            &.moblie {
              display: flex;
            }
            .tg_item_wrap {
            }
          }
        }
      }
    }
  }
`;
