import styled from 'styled-components';

export const KnownledItemStyled = styled.div`
  border-radius: 18px;
  -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.09);
  width: 304px;
  height: 375px;
  padding: 56px 16px 21px 16px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  position: relative;
  .k_row {
    .k_img_wrap {
      margin-bottom: 22px;
      text-align: center;
    }
    .group_label {
      .k_label {
        text-align: center;
        font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_30};
        font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_SEMIBOLD};
        color: ${({ theme }) => theme.COLORS.BROWN_2};
      }
    }
  }
  .k_more {
    position: absolute;
    bottom: 12px;
    right: 16px;
    text-align: right;
    font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_16};
    font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.PINK_8};
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.MEDIUM_DESKTOP}) {
    width: 214px;
    height: 285px;
    .k_row {
      .k_img_wrap {
        .k_img {
          width: auto;
          height: 99px;
        }
      }
      .group_label {
        .k_label {
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
          text-align: center;
        }
      }
    }
    .k_more {
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.TABLET}) {
    margin: auto;
    max-width: 417px;
    width: 100%;
    height: 168px;
    padding: 16px 22px 16px 22px;
    .k_row {
      height: 100%;
      display: flex;
      align-items: center;
      column-gap: 16px;
      .k_img_wrap {
        width: 120px;
        margin-bottom: 0px;
        .k_img {
          width: auto;
          height: 99px;
        }
      }
      .group_label {
        .k_label {
          text-align: left;
        }
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    max-width: 217px;
    width: 100%;
    height: 92px;
    margin: auto;
    padding: 16px 20px 16px 20px;
    .k_row {
      display: flex;
      column-gap: 16px;
      .k_img_wrap {
        flex-shrink: 0;
        flex-basis: 48px;
        .k_img {
          width: auto;
          height: 48px;
        }
      }
      .group_label {
        line-height: 20px;
        .k_label {
          text-align: left;
          font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_14};
          font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_MEDIUM};
        }
      }
    }
    .k_more {
      right: 8px;
      bottom: 6px;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_11};
      font-family: ${({ theme }) => theme.FONTS.STYLE.KANIT_LIGHT};
    }
  }
`;
