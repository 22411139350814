import React from 'react';

export default function AaPlatform({
  color = '#4E5B7E',
  isUp,
  width = "20",
  height = "19.999"
}) {
  return (
    isUp ?
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 19.999">
        <path id="Exclusion_85" data-name="Exclusion 85" d="M14,20A10,10,0,1,1,24,10,10.011,10.011,0,0,1,14,20ZM11.956,3.81A.656.656,0,0,0,11.487,4l-.626.626a.663.663,0,0,0,0,.936l4.254,4.274L10.86,14.116a.663.663,0,0,0,0,.936l.626.626a.662.662,0,0,0,.937,0l5.368-5.368a.664.664,0,0,0,0-.938L12.425,4A.659.659,0,0,0,11.956,3.81Z" transform="translate(20 -4) rotate(90)" fill="#ed799e" />
      </svg>
      :
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 19.999">
        <path id="Exclusion_85" data-name="Exclusion 85" d="M14,20A10,10,0,1,1,24,10,10.011,10.011,0,0,1,14,20ZM11.956,3.81A.656.656,0,0,0,11.487,4l-.626.626a.663.663,0,0,0,0,.936l4.254,4.274L10.86,14.116a.663.663,0,0,0,0,.936l.626.626a.662.662,0,0,0,.937,0l5.368-5.368a.664.664,0,0,0,0-.938L12.425,4A.659.659,0,0,0,11.956,3.81Z" transform="translate(0 24) rotate(-90)" fill="#ed799e" />
      </svg>
  );
}
