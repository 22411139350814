import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { ConsultDoctorContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { iteleService } from 'apiService';
import { BASE_API_ITELE } from 'apiService/apiConfig';
import theme from 'styles/theme.json';
import { Icons, Modals, Displays, Buttons } from 'components';
import { HopitalItemType4, HopitalItemType3, MyAddressLocation } from 'widgets';

class ConsultDoctorContainer extends React.Component {
  state = {
    hideBox: true,
    isDesktop: window.matchMedia(`(max-width: ${theme.SCREENS.MINI_TABLET})`)
      .matches
      ? false
      : true,
  };

  componentDidMount() {
    // Tracking user pageview
    ReactGA.set({ page: this.props.router.location.pathname });
    ReactGA.pageview(this.props.router.location.pathname);
    this.setState({
      loading: true,
    });
  }

  onGetCurrentPosition = async (resPosition) => {
    if (resPosition && resPosition.status) {
      switch (resPosition.type) {
        case 'amphoeID':
          let resAmphoeID = await this.fetchMammogramCenter(
            `?per_page=99&sort_by=is_online&amphoe_id=${resPosition.amphoeID}`
          );
          if (resAmphoeID) {
            this.setState({
              loading: false,
              queryString: `sort_by=is_online&amphoe_id=${resPosition.amphoeID}`,
            });
          }
          break;
        case 'latln':
          let resLatLng = await this.fetchMammogramCenter(
            `?per_page=99&latitude=${resPosition.lat}&longitude=${resPosition.lng}`
          );
          if (resLatLng) {
            this.setState({
              loading: false,
              queryString: `sort_by=is_online&latitude=${resPosition.lat}&longitude=${resPosition.lng}`,
            });
          }
          break;
        default:
          break;
      }
    } else {
      let res = await this.fetchMammogramCenter(
        '?per_page=99&sort_by=is_online'
      );
      if (res) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  fetchMammogramCenter = async (queryString) => {
    let res = await iteleService.GET_ITELE_ACCOUNT_BY_SERVICE_CODE(
      'wellscreening',
      queryString
    );
    if (res && res.success) {
      let splitIsOnlineGroup = res.data.reduce(
        (result, data) => {
          if (data.is_online) {
            result.online.push(data);
          } else {
            result.offline.push(data);
          }
          return result;
        },
        { online: [], offline: [] }
      );
      this.setState({
        iteleServiceData: splitIsOnlineGroup.online,
        iteleServiceOfflineData: splitIsOnlineGroup.offline,
        hideBox: true,
      });
      return res.data;
    }
  };

  handleClickBack = () => {
    this.props.router.navigate(-1);
  };

  handleClickLandingPage = () => {
    this.props.router.navigate(-1);
  };

  handleSelectedAmphoe = async (e) => {
    let res = await this.fetchMammogramCenter(
      `?per_page=99&sort_by=is_online&amphoe_id=${e}`
    );
    if (res) {
      this.setState({
        loading: false,
        queryString: `amphoe_id=${e}`,
      });
    }
  };

  handleClickConsult = (e) => {
    this.setState({
      isModal: true,
      renderModal: (
        <div className="md_container">
          <img
            className="logo_show"
            src={'/assets/images/icons/new_logo_her_will.png'}
            alt="icon show"
          />
          <div className="text_1"></div>
          <div
            className="hopital_logo_show"
            style={{
              backgroundImage: `url(${e.logo_uri})`,
            }}
          />
          <div dangerouslySetInnerHTML={{ __html: e.description }} />
          <div
            className={`btn_action  ${e.is_online ? `` : `offline`}`}
            onClick={() => this.handleClickGoItele(e)}
          >
            {e.is_online ? 'เริ่มบริการ' : 'นอกเวลาทำงาน'}
          </div>
          <div className="back" onClick={this.handleClickCloseModal}>
            ย้อนกลับ
          </div>
          <div className="note" dangerouslySetInnerHTML={{ __html: e.note }} />
        </div>
      ),
    });
  };

  handleClickCloseModal = () => {
    this.setState({
      isModal: false,
      renderModal: false,
    });
  };

  handleClickGoItele = async (e) => {
    const { configProjectRedux } = this.props;

    if (e.is_online) {
      ReactGA.event({
        category: configProjectRedux.scheme + '_iTele',
        action: 'click_goItele',
      });
      window.open(
        `${BASE_API_ITELE}/service-api/services/wellscreening/accounts/${e.id}/patient_uri`,
        '_blank'
      );
      this.handleClickCloseModal();
    }
  };

  handleShowBox = () => {
    const { hideBox } = this.state;
    this.setState({
      hideBox: !hideBox,
    });
  };

  render() {
    const {
      iteleServiceData,
      iteleServiceOfflineData,
      loading,
      isModal,
      renderModal,
      hideBox,
      // isDesktop,
    } = this.state;
    return (
      <ConsultDoctorContainerStyled>
        <div className={`loader_layout ${loading ? 'active' : ''}`}>
          <Displays.LogoLoadingFade />
        </div>
        <div className="is_desktop">
          <div className="btn_back_wrap" onClick={this.handleClickBack}>
            <Buttons.BackBtn
              theme_gray
              txt="ย้อนกลับ"
              icon={<Icons.Back color="#6F7070" />}
            />
          </div>
          <div className="location_container">
            <div className="title">โรงพยาบาลที่เข้าร่วม</div>
            <div
              className="address_wrap"
              style={{
                backgroundImage: `url('/assets/images/backgrounds/con_doctor.jpg')`,
              }}
            >
              <img
                className="img_itele"
                src={'/assets/images/icons/itele_2.svg'}
                alt="icon show"
              />
              <div className="obj_text">
                <div className="ob_label">Telemedicine</div>
                <div className="group_text">
                  <div className="text1">ปรึกษาแพทย์ได้ทุกที่ทุกเวลา</div>
                  <div className="text2">ด้วยระบบวิดีโอคอล</div>
                </div>
              </div>
            </div>
            <MyAddressLocation
              onGetCurrentPosition={this.onGetCurrentPosition}
              onChangeAmphoe={this.handleSelectedAmphoe}
            />
            <div className="title_sec_2">รายชื่อโรงพยาบาล</div>
            <div className="row_2_col">
              {iteleServiceData &&
                iteleServiceData.map((e, i) => (
                  <>
                    <div className="item_wrap" key={i}>
                      <HopitalItemType3
                        name={e.name}
                        image_uri={e.logo_uri}
                        province={e.amphoe?.changwat?.name_th}
                        amphoe={e.amphoe?.name_th}
                        distance={e.distance}
                        phone_no={e.phone_no}
                        handleOnClick={() => this.handleClickConsult(e)}
                        isActive={e.is_online}
                      />
                    </div>
                    {iteleServiceData &&
                      iteleServiceData.length % 2 !== 0 &&
                      i + 1 === iteleServiceData.length && (
                        <div className="item_wrap mobile_not_show" key={i}></div>
                      )}
                  </>
                ))}
            </div>
            <div className="no_service">
              <div className="title_service">
                <div className="round" />
                <div>ไม่พร้อมให้บริการ</div>
              </div>
              <div className="btn_show" onClick={this.handleShowBox}>
                {hideBox ? (
                  <>
                    <div>แสดงทั้งหมด</div>
                    <Icons.ArrowDown />
                  </>
                ) : (
                  <>
                    <div>แสดงน้อยลง</div>
                    <Icons.ArrowUp />
                  </>
                )}
              </div>
              <div className={`hide_box ${!hideBox ? 'hide' : ''}`}>
                <div className="hide_box_title">โรงพยาบาลอื่นๆ</div>
                {/* <div className="coming_text">Coming soon...</div> */}
                <div className="row_2_col_no_service">
                  {iteleServiceOfflineData &&
                    iteleServiceOfflineData.map((e, i) => (
                      <>
                        <div key={i} className="item_wrap">
                          <HopitalItemType3
                            name={e.name}
                            image_uri={e.logo_uri}
                            province={e.amphoe?.changwat?.name_th}
                            amphoe={e.amphoe?.name_th}
                            distance={e.distance}
                            phone_no={e.phone_no}
                            handleOnClick={() => this.handleClickConsult(e)}
                            isActive={e.is_online}
                          />
                        </div>
                        {iteleServiceOfflineData &&
                          iteleServiceOfflineData.length % 2 !== 0 &&
                          i + 1 === iteleServiceOfflineData.length &&
                          (
                            <div className="item_wrap mobile_not_show"></div>
                          )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="is_mobile">
          <div
            className="bg_top"
            style={{
              backgroundImage: `url('/assets/images/backgrounds/group_doctor.png')`,
            }}
          />
          <div className="container">
            <div
              className="m_btn_back_wrap"
              onClick={this.handleClickBack}
            ></div>
            <div className="m_title">โรงพยาบาลที่ให้บริการ</div>
            <div className="m_address_wrap">
              <img
                className="img_itele"
                src={'/assets/images/icons/itele_2.svg'}
                alt="icon show"
              />
              <img
                className="img_doctor_mb"
                src={'/assets/images/icons/doctor_mb.png'}
                alt="icon show"
              />
              <div className="group_text">
                <div className="text_style fs_18">Telemedicine</div>
                <div className="text_style">ปรึกษาแพทย์ได้ทุกที่ทุกเวลา</div>
                <div className="text_style small">
                  ท่านจะได้รับการปรึกษากับแพทย์ที่โรงพยาบาล
                </div>
                <div className="text_style small">
                  จัดสรรให้ โดยอาจมีแพทย์หลากลายสาขาที่ให้บริการ
                </div>
              </div>
            </div>
            <MyAddressLocation
              onGetCurrentPosition={this.onGetCurrentPosition}
              onChangeAmphoe={this.handleSelectedAmphoe}
            />
            <div className="m_title_sec_2">รายชื่อโรงพยาบาล</div>
            <div className="m_row">
              {iteleServiceData &&
                iteleServiceData.map((e, i) => (
                  <div key={i} className="m_item_wrap">
                    <HopitalItemType4
                      name={e.name}
                      image_uri={e.logo_uri}
                      province={e.amphoe?.changwat?.name_th}
                      amphoe={e.amphoe?.name_th}
                      distance={e.distance}
                      phone_no={e.phone_no}
                      isActive={e.is_online}
                      handleOnClick={() => this.handleClickConsult(e)}
                    />
                  </div>
                ))}
            </div>
            <div className="m_no_service">
              <div className="title_service">
                <div className="round" />
                <div>ไม่พร้อมให้บริการ</div>
              </div>
              <div className="btn_show" onClick={this.handleShowBox}>
                {hideBox ? (
                  <>
                    <div>แสดงทั้งหมด</div>
                    <Icons.ArrowDown />
                  </>
                ) : (
                  <>
                    <div>แสดงน้อยลง</div>
                    <Icons.ArrowUp />
                  </>
                )}
              </div>
              <div className={`hide_box ${!hideBox ? 'hide' : ''}`}>
                <div className="hide_box_title">โรงพยาบาลอื่นๆ</div>
                {/* <div className="coming_text">Coming soon...</div> */}
                <div className="row_2_col_no_service">
                  {iteleServiceOfflineData &&
                    iteleServiceOfflineData.map((e, i) => (
                      <>
                        <div key={i} className="item_wrap">
                          <HopitalItemType4
                            name={e.name}
                            image_uri={e.logo_uri}
                            province={e.amphoe?.changwat?.name_th}
                            amphoe={e.amphoe?.name_th}
                            distance={e.distance}
                            phone_no={e.phone_no}
                            isActive={e.is_online}
                            handleOnClick={() => this.handleClickConsult(e)}
                          />
                        </div>
                        {iteleServiceOfflineData &&
                          iteleServiceOfflineData.length % 2 !== 0 &&
                          i + 1 === iteleServiceOfflineData.length && (
                            <div className="item_wrap mobile_not_show" key={i}></div>
                          )}
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn_layout">
          <button className="btn_end" onClick={this.handleClickLandingPage}>
            ย้อนกลับ
          </button>
        </div>
        <Modals.Modal
          theme_modal_standard
          isShow={isModal}
          handleClickCloseModal={this.handleClickCloseModal}
        >
          {renderModal}
        </Modals.Modal>
      </ConsultDoctorContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.authenRedux,
  configProjectRedux: state.configProjectRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConsultDoctorContainer));
