import styled from 'styled-components';

export const VideoSearchContainerStyled = styled.div`
  padding: 48px 21px 15px 64px;
  height: calc(100% - 24px);
  .pink_line {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
    height: 3px;
    background: ${({ theme }) => theme.COLORS.PINK_4};
  }
  .content_container {
    margin-top: 36px;
    .category_label {
      margin-bottom: 12px;
      font-size: ${({ theme }) => theme.FONTS.SIZE.SIZE_20};
      color: ${({ theme }) => theme.COLORS.BLACK_7};
    }
    .label_empty {
      margin-top: 10%;
      text-align: center;
    }
    .preview_item {
      margin-bottom: 20px;
    }
  }

  @media (max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO}) {
    padding: 12px;
    .content_container {
      margin-top: 16px;
    }
  }
`;
