import { BannerPopupStyled } from './styled';
import { Icons } from 'components';

const BannerPopup = ({ handleClickCloseModal, imgSrc }) => {
  return (
    <BannerPopupStyled>
      <div className="box_popup">
        <div className="svg_close" onClick={handleClickCloseModal}>
          <Icons.CloseX2 color="#000000" />
        </div>
        {/* <img
          className="img_branner"
          alt="notic icon"
          src="/assets/images/icons/popup_branner.jpg"
        /> */}
        <img
          className="img_branner"
          alt="notic icon"
          src={imgSrc ? imgSrc : "/assets/images/icons/roche_popup.png"}
        />
      </div>
    </BannerPopupStyled>
  );
};

export default BannerPopup;
