import ArrowDown from './ArrowDown';
import ArrowUp from './ArrowUp';
import ArrowVDown from './ArrowVDown';
import ArrowVUp from './ArrowVUp';
import CloseHerWill from './CloseHerWill';
import BtnHerWillShare from './BtnHerWillShare';
import WellQuestion from './WellQuestion';
import Back from './Back';
import CloseX2 from './CloseX2';
import Dot from './Dot';
import Hamburger from './Hamburger';
import Location from './Location';
import Call from './Call';
import Camera from './Camera';
import Campaign1 from './Campaign1';
import Campaign2 from './Campaign2';
import Campaign3 from './Campaign3';
import CarouselLeftArrow from './CarouselLeftArrow';
import CarouselRightArrow from './CarouselRightArrow';
import CircleContent from './CircleContent';
import CircleVideo from './CircleVideo';
import CircleVideoRec from './CircleVideoRec';
import Click from './Click';
import CloseBold from './CloseBold';
import Play from './Play';
import Search from './Search';
import SocialFB from './SocialFB';
import SocialLine from './SocialLine';
import TreatHeart from './TreatHeart';
import TreatHeartCapsule from './TreatHeartCapsule';
import TreatHeartFamale from './TreatHeartFamale';
import TreatHeartRate from './TreatHeartRate';
import TreatHeartVirus from './TreatHeartVirus';
import VideoLiked from './VideoLiked';
import VideoReport from './VideoReport';
import VideoReport2 from './VideoReport2';
import VideoShared from './VideoShared';
import VideoView from './VideoView';
import VideoLikedSmall from './VideoLikedSmall';
import VideoSharedSmall from './VideoSharedSmall';
import VideoViewSmall from './VideoViewSmall';
import CloseX from './CloseX';
import ArrowLeft from './ArrowLeft';
import ArrowPinkUpDown from './ArrowPinkUpDown';
import VideoSuggest from './VideoSuggest';
import PinkZoom from './PinkZoom';
import NoticLabel from './NoticLabel';
import Notic1 from './Notic1';
import Notic2 from './Notic2';
import Notic3 from './Notic3';
import Notic4 from './Notic4';

const Export = {
  VideoReport2,
  Notic1,
  Notic2,
  Notic3,
  Notic4,
  NoticLabel,
  PinkZoom,
  ArrowPinkUpDown,
  VideoSuggest,
  ArrowLeft,
  CloseX,
  ArrowDown,
  ArrowUp,
  ArrowVDown,
  ArrowVUp,
  CloseHerWill,
  BtnHerWillShare,
  WellQuestion,
  Back,
  CloseX2,
  Dot,
  Hamburger,
  Location,
  Call,
  Camera,
  Campaign1,
  Campaign2,
  Campaign3,
  CarouselLeftArrow,
  CarouselRightArrow,
  CircleContent,
  CircleVideo,
  CircleVideoRec,
  Click,
  CloseBold,
  Play,
  Search,
  SocialFB,
  SocialLine,
  TreatHeart,
  TreatHeartCapsule,
  TreatHeartFamale,
  TreatHeartRate,
  TreatHeartVirus,
  VideoLiked,
  VideoReport,
  VideoShared,
  VideoView,
  VideoLikedSmall,
  VideoSharedSmall,
  VideoViewSmall,
};

export default Export;
