import React from 'react';
import { HopitalItemType3Styled } from './styled';
import { Icons } from 'components';

const HopitalItemType3 = ({
  name,
  province,
  amphoe,
  distance,
  image_uri,
  isActive,
  handleOnClick,
}) => {
  return (
    <HopitalItemType3Styled>
      <div className="row_1">
        <div
          className="left_col"
          style={{
            backgroundImage: `url(${image_uri})`,
          }}
        ></div>
        <div className="right_col">
          <div className="txt name">{name}</div>
          <div className="txt gray mb_20 fs_16">
            <div>{province}</div>
            <div className="txt_amphoe">{amphoe}</div>
          </div>
          <div className={`btn_status  ${isActive ? `` : `offline`}`}>
            <div className="round" />
            <div className={`txt_status`}>
              {isActive ? 'ออนไลน์' : 'ออฟไลน์'}
            </div>
          </div>
        </div>
      </div>
      <div className="row_2">
        <div className="left_col">
          {distance && (
            <div className="rang_row">
              <Icons.Location color={isActive ? `#F395BE` : `#069EBF`} />
              <div className="txt">{distance} กม.</div>
            </div>
          )}
        </div>
        <div className="right_col">
          <div className={`btn_call`}>
            <>
              <Icons.Camera />
              <div className="btn_txt" onClick={() => handleOnClick()}>
                ปรึกษาหมอ
              </div>
            </>
          </div>
        </div>
      </div>
    </HopitalItemType3Styled>
  );
};

export default HopitalItemType3;
