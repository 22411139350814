import { KnowledWidgetStyled } from './styled';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { scroller } from 'react-scroll';
import ReactGA from 'react-ga';
import { Cards } from 'components';

const KnowledWidget = () => {
  const navigate = useNavigate();
  const { configProjectRedux } = useSelector((state) => state);

  const _handleClickItem = (key) => {
    switch (key) {
      case 'risk_factor':
        navigate(
          `${ROUTE_PATH.RISK_FACTOR}?scrollto=${
            renderDevice() === 'risk_desktop'
              ? 'risk_sec_1_desktop'
              : 'risk_sec_1_mobile'
          } `
        );
        break;
      case 'symptoms':
        navigate(`${ROUTE_PATH.RISK_FACTOR}?scrollto=${renderDevice()}`);
        break;
      case 'treatment':
        _handleScroll('treatment');
        break;
      case 'telemed':
        _handleClickConsultDocotr();
        break;
      default:
        break;
    }
  };

  const renderOffset = () => {
    let width = window.innerWidth;
    if (width > 1536) {
      return -112;
    }
    if (width > 1024) {
      return -102;
    }
    if (width > 540) {
      return -102;
    }
    return -80;
  };

  const _handleScroll = (key) => {
    scroller.scrollTo(key, {
      duration: 1000,
      delay: 100,
      smooth: true,
      // containerId: 'ContainerElementID',
      offset: renderOffset(), // Scrolls to element + 50 pixels down the page
    });
  };

  const _handleClickConsultDocotr = () => {
    ReactGA.event({
      category: configProjectRedux.scheme + '_fromLanding',
      action: 'click_goIteleList',
    });
    navigate(ROUTE_PATH.CONSULT_DOCTOR);
  };

  const renderDevice = () => {
    let width = window.innerWidth;
    if (width > 1536) {
      return 'risk_desktop';
    }
    if (width > 1024) {
      return 'risk_desktop';
    }
    return 'risk_mobile';
  };

  return (
    <KnowledWidgetStyled>
      <div className="group_title">
        <div className="kl_title">รู้รอบเรื่อง</div>
        <div className="kl_hero_title">"มะเร็งเต้านม"</div>
      </div>
      <div className="kl_content_row">
        <div
          className="ct_item_wrap"
          onClick={() => _handleClickItem('risk_factor')}
        >
          <Cards.KnownledItem
            src="/assets/images/icons/knownled1.png"
            label={['ปัจจัยเสี่ยง']}
          />
        </div>
        <div
          className="ct_item_wrap"
          onClick={() => _handleClickItem('symptoms')}
        >
          <Cards.KnownledItem
            src="/assets/images/icons/knownled2.png"
            label={['อาการที่ควร', 'หมั่นสังเกต']}
          />
        </div>
        <div
          className="ct_item_wrap"
          onClick={() => _handleClickItem('treatment')}
        >
          <Cards.KnownledItem
            src="/assets/images/icons/knownled3.png"
            label={['การตรวจคัดกรอง', 'จำเป็นอย่างไร']}
          />
        </div>
        <div
          className="ct_item_wrap"
          onClick={() => _handleClickItem('telemed')}
        >
          <Cards.KnownledItem
            src="/assets/images/icons/knownled4.png"
            label={['การรักษาและ', 'ช่องทางการปรึกษา']}
          />
        </div>
      </div>
    </KnowledWidgetStyled>
  );
};

export default KnowledWidget;
