import React from 'react';

export default function AaPlatform({ color = '#575959' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.745"
      height="20.318"
      viewBox="0 0 22.745 20.318"
    >
      <g
        id="Group_86188"
        data-name="Group 86188"
        transform="translate(-328.277 -56.924)"
      >
        <path
          id="Rectangle"
          d="M2.2.823,23.782,0a1.818,1.818,0,0,1,2.057,1.736,2.182,2.182,0,0,1-2.195,1.9L2.058,4.457A1.818,1.818,0,0,1,0,2.721,2.182,2.182,0,0,1,2.2.823Z"
          transform="translate(328.436 73.876) rotate(-41)"
          fill={color}
        />
        <path
          id="Rectangle-2"
          data-name="Rectangle"
          d="M2.036,0,23.487.854A2.249,2.249,0,0,1,25.677,2.9,1.857,1.857,0,0,1,23.643,4.78L2.192,3.928A2.249,2.249,0,0,1,0,1.881,1.857,1.857,0,0,1,2.036,0Z"
          transform="translate(331.35 56.998) rotate(40)"
          fill={color}
        />
      </g>
    </svg>
  );
}
