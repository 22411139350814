import { default as CheckBox } from './CheckBox';
import { default as DateAnt } from './DateAnt';
import { default as DateTime } from './DateTime';
import { default as DropDownCustom } from './DropDownCustom';
import { default as DropdownLib } from './DropdownLib';
import { default as InputSearch } from './InputSearch';
import { default as InputText } from './InputText';
import { default as OptionBox } from '../Inputs/OptionBox';
import { default as InputVideoSearch } from '../Inputs/InputVideoSearch';
import { default as InputNormalSelect } from '../Inputs/InputNormalSelect';

const EXPORT = {
  CheckBox,
  DateAnt,
  DateTime,
  DropDownCustom,
  DropdownLib,
  InputSearch,
  InputText,
  OptionBox,
  InputVideoSearch,
  InputNormalSelect,
};

export default EXPORT;
